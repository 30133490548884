import React, { useState } from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from 'react-redux';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Reservar } from "../reservar/Reservar";

export const Footer = () => {

    const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleClose = () => {
    setShow(false)
    navigate(0)
  };


    const [show, setShow] = useState(false);
  return (
    <>
    <footer className="footer">
    <div className="footer__container container">
      <img src="https://res.cloudinary.com/dkn2dgfex/image/upload/v1671063169/logo/logofondotransp320_bminry.png" className="footer__logo"/>
      <nav className="footer__nav">
        <Link to={"/quienessomos"} className="footer__link">Quienes somos</Link>
        <Link to={"/losprofes"} className="footer__link">Los Profes</Link>
        <Link to={"/contactanos"} className="footer__link">Contactanos</Link>
      </nav>
      <nav className="footer__nav">
        <Link to={"/nuestraspromos"} className="footer__link">Promos</Link>
        <Link to={"/alquilerdeequipos"} className="footer__link">Alquiler de Equipos</Link>
        <Link to={"/surftrip"} className="footer__link">Surf trip</Link>
      </nav>
      <nav className="footer__nav">
        <Link className="footer__link" onClick={()=>setShow(true)}>Reserva</Link>
        <Link to={"/infoclima"} className="footer__link">Clima</Link>
        <Link to={"/contactanos"} className="footer__link">Contactanos</Link>
      </nav>
      <nav className="footer__nav">
        
        <Link to={"/clasessurf"} className="footer__link">SURF</Link>
        <Link to={"/clasessup"} className="footer__link">SUP</Link>
        <Link to={"/clasesaguasabiertas"} className="footer__link">Aguas abiertas</Link>
        <Link to={"/clasespequesurf"} className="footer__link">Peques</Link>
        
      </nav>

      <nav className="footer__social">
        <a href="https://www.instagram.com/sudestadapinamar/" target="_blank" className="footer__media">
        <i className="bi bi-instagram" style={{"font-size": "2rem" }}></i>
        </a>
        <a href="https://www.facebook.com/surfsudestada" target="_blank" className="footer__media">
        <i className="bi bi-facebook" style={{"font-size": "2rem" }}></i>
        </a>
        
        <a href="https://wa.me/5492254587288" target="_blank" className="footer__media">
        <i className="bi bi-whatsapp" style={{"font-size": "2rem" }}></i>
        </a>
      </nav>
    </div>
  </footer>
  <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header closeButton>
            <Modal.Title>Reservar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Reservar></Reservar>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  )
}
