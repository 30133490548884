import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const Deslinde = () => {
  const [showDeslinde, setShowDeslinde] = useState(false);

  const handleCloseDeslinde = () => setShowDeslinde(false);
  const handleShowDeslinde = () => setShowDeslinde(true);

  return (
    <div>
      <button onClick={handleShowDeslinde}>Leer deslinde</button>
      <Modal show={showDeslinde} onHide={handleCloseDeslinde}>
        <Modal.Header closeButton>
          <Modal.Title>
            DESLINDE DE RESPONSABILID CIVIL
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Quien suscribe, declara bajo juramento conocer y aceptar que la
              actividad denominada Surf, y/o Paddle Surf, y/o Bodyboard, y/o
              Natación en Aguas Abiertas, y/o Ambientación al Surf (En adelante:
              actividades acuáticas), implica un esfuerzo de mis capacidades
              tanto físicas como mentales, por lo que asumo, en forma total y
              exclusiva, los riesgos que mi participación pueda eventualmente
              ocasionar a mi salud y/o a terceros, durante y después de la
              actividad. Declaro bajo juramento que me encuentro físicamente
              apto y que no poseo ninguna enfermedad ni limitante, que me impida
              realizar dicha actividad. Dejo constancia que mi declaración en
              este documento es verdadera, así mismo: a) Me comprometo a cumplir
              con las reglas y normas de seguridad de la actividad. b) Reconozco
              ser el único responsable por mis posesiones personales, por lo que
              deslindo a la Escuela de Surf Sudestada de cualquier
              responsabilidad por pérdida, extravío, hurto o robo. c) Autorizo a
              la Escuela de Surf Sudestada el libre uso de mi nombre, imagen
              física y/o fotografía para la promoción de la actividad. Declaro
              haber leído el formato de deslinde de responsabilidades, declaro
              comprenderlo en todos sus términos y suscribo el presente con
              total discernimiento, intención y libertad en señal de mi plena
              conformidad.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeslinde}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
