import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createPromos, deletePromos, getAllPromos, updatePromos } from '../../../redux/actions'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom'
import "./promos.scss"
import { DataGrid } from '@mui/x-data-grid';

export const Promos = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const reload = ()=>{
     setTimeout(function(){
      navigate(0)
  }, 2000);
}
  

    const allPromos = useSelector((state) => state.AllPromos);
    const [promoSelec, setPromoSelec] = useState({})
    const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [input, setInput] = useState({});

    useEffect(() => {
      dispatch(getAllPromos())
    
    }, [])

    const handleClose = () => setShow(false);
  const handleCloseDel = () => setShowDel(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleShow = () => setShow(true);
    
  const handleInputChange = function (e) {
    
      setInput({
        ...input,
        [e.target.name]: e.target.value,
        
      });
      
  };
const handleSaveUpdate = ()=>{
  dispatch(updatePromos(input))
    setInput({})
    // setShow(false)
    handleClose()
    reload();
  
    
}
const handleDelete = ()=>{
  dispatch(deletePromos(input))
    setInput({})
  //   // setShow(false)
  handleClose()
  reload();
}
const handleCreatePromo = ()=>{
  dispatch(createPromos(input))
    setInput({})
  //   // setShow(false)
  handleClose()
  reload();
}










const userColumns = [
  // { field: "id", headerName: "ID", width: 70 },
  // {
  //   field: "name",
  //   headerName: "User",
  //   width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <img className="cellImg" src={params.row.avatar_url} alt="avatar" />
  //         {params.row.name} {params.row.lastname}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "codigo",
    headerName: "Codigo",
    width: 150,
  },
  {
    field: "cantdias",
    headerName: "Dias",
    width: 50,
  },

  {
    field: "cantpers",
    headerName: "Personas",
    width: 70,
  },
  {
    field: "fechaini",
    headerName: "Inicio",
    width: 100,
  },
  {
    field: "fechafin",
    headerName: "Final",
    width: 100,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];



const [data, setData] = useState([]);

useEffect(() => {
  if(allPromos && allPromos.length > 0){
    const userConId = []
    for (let i = 0; i < allPromos.length; i++) {
      const a = allPromos[i];
      a.id = i
      userConId.push(a)
      
    }
    setData(userConId)
  }
}, [allPromos])



const handleDeletes = (id) => {
  setData(data.filter((item) => item.id !== id));
};
const buscarU = (uselec)=>{
  return allPromos.find((x)=> x.codigo === uselec)
}
const modificarUsuario = (e)=>{
  setPromoSelec(buscarU(e));
  setShow(true);
  setInput(buscarU(e));
    // setUserSelect(buscarU(e));
    // setShow(true);
    // setInput(buscarU(e));
}

const eliminarUsuario = (e)=>{
  setPromoSelec(buscarU(e));
                  setShowDel(true);
                  setInput(buscarU(e));

  // setUserSelect(buscarU(e));
  // setShowDel(true);
  // setInput(buscarU(e));
}

const actionColumn = [
  {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <button className="viewButton"
              onClick={(x)=>modificarUsuario(params.row.codigo)}
            >
              MODIFICAR
            </button>
          <div
            className="deleteButton"
            onClick={(x) => eliminarUsuario(params.row.codigo)}
          >
            Delete
          </div>
        </div>
      );
    },
  },
];




  return (
    <div className="list">




{allPromos && allPromos.length > 0 ?

<div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle">
        Agregar nueva PROMO
        <button className="btn btn-primary" onClick={(x) => {
                  setShowCreate(true);
                }}>CREAR PROMO</button>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
    </div>

:""}
    

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar PROMO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Codigo: {promoSelec.codigo}</label>
            </div>
            <div>
              <label>Cantidad de personas:</label>
              <input
                type="number"
                name="cantpers"
                onChange={handleInputChange}
                defaultValue={promoSelec.cantpers}
              />
            </div>
            <div>
              <label>Cantidad dias:</label>
              <input
                type="number"
                name="cantdias"
                onChange={handleInputChange}
                defaultValue={promoSelec.cantdias}
              />
            </div>
            <div>
              <label>Descuento:</label>
              <input
                type="number"
                name="descuento"
                step=".01"
                onChange={handleInputChange}
                defaultValue={promoSelec.descuento}
              />
            </div>
            <div>
              <label>Fecha inicio:</label>
              <input
                type="date"
                name="fechaini"
                onChange={handleInputChange}
                defaultValue={promoSelec.fechaini}
              />
            </div>
            <div>
              <label>Fecha fin:</label>
              <input
                type="date"
                name="fechafin"
                onChange={handleInputChange}
                defaultValue={promoSelec.fechafin}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {true ? (
              <Button variant="primary" onClick={handleSaveUpdate}>
                Save Changes
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showDel} onHide={handleCloseDel}>
          <Modal.Header closeButton>
            <Modal.Title>ELIMINAR PROMO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>VAS A ELIMINAR EL CODIGO: {promoSelec.codigo}</h1>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {true ? (
              <Button variant="danger" onClick={handleDelete}>
                DELETE
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showCreate} onHide={handleCloseCreate}>
          <Modal.Header closeButton>
            <Modal.Title>ELIMINAR PROMO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          <label>Codigo Promo:</label>
              <input
                type="text"
                name="codigo"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Cantidad de personas:</label>
              <input
                type="number"
                name="cantpers"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Cantidad dias:</label>
              <input
                type="number"
                name="cantdias"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Descuento:</label>
              <input
                type="number"
                name="descuento"
                step=".01"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Fecha inicio:</label>
              <input
                type="date"
                name="fechaini"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Fecha fin:</label>
              <input
                type="date"
                name="fechafin"
                onChange={handleInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreate}>
              Close
            </Button>
            {true ? (
              <Button variant="primary" onClick={handleCreatePromo}>
                CREAR
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
    </div>
  )
}
