import React from 'react'
import "./carusel.css"
import { Carousel, CarouselItem } from 'react-round-carousel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfesores } from '../../redux/actions';
import { useState } from 'react';

export const Carusel = ({items}) => {

    





    const width = 400
  


  return (
    <div className='divcolor'>
{/* <div class="container">
  <div class="carousel-3d carousel-3d-basic">
    <div class="carousel-3d-inner">
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(45).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(46).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(47).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(48).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(49).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(50).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(51).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(52).webp" alt="Slide"/></div>
      <div class="carousel-3d-item"><img src="https://mdbootstrap.com/img/Photos/Slides/img%20(53).webp" alt="Slide"/></div>
    </div>
  </div>
</div> */}
<Carousel items={items}  itemWidth={width} />
    </div>
  )
}
