import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Clima from "../clima/Clima";
import "./navbar.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { getDeports, userLogin } from "../../redux/actions";
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { useState } from "react";
import { Reservar } from "../reservar/Reservar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loginWithRedirect, logout, isAuthenticated,  user} = useAuth0();
  const userDetails = useSelector((state) => state.UserLog);

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("logged")
    if(loggedUserJSON){
      const userlogg = JSON.parse(loggedUserJSON)
      dispatch(userLogin(userlogg))
    }
    
  }, [])
  const logoutsacarstorage = ()=>{
    setTimeout(function(){
      logout({ returnTo: window.location.origin })
 }, 2000);
}

  useEffect(() => {
    if (user) {
      window.localStorage.setItem(
        "logged", JSON.stringify(user)
      )
      dispatch(userLogin(user))
    };
    dispatch(getDeports())
  }, [user]);

  const LogOut = ()=>{
    localStorage.clear();
    logoutsacarstorage()
  }
  const role = userDetails.roleId

  // var [carritoLleno, setCarritoLleno] = useState("")

//   useEffect(() => {
//     setCarritoLleno(getDatadiasYhorariosCarrito())
  
//   }, [])
//   const getDatadiasYhorariosCarrito= ()=>{
//     return JSON.parse(localStorage.getItem("diasYhorariosCarrito"))
// }
const [show, setShow] = useState(false);
const handleClose = () => {
  setShow(false)
  navigate(0)
};



  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="container-fluid">
          {/* este a estaba fijarse si sacar o no */}
        {/* <a className="navbar-brand" href="#"> */}
          <Link  className="navbar-brand" to={"/"}>
            <img src="https://res.cloudinary.com/dkn2dgfex/image/upload/dpr_2.0,f_auto,q_auto,w_80/v1674241108/logo/SUDESTADA_solo_qd1usy.png" alt="" /> 
          </Link>
          {/* <i className="bi bi-flower1"></i>
          <span className="text-warning">Sudestada</span> */}
        {/* </a>             */}

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>


        <div className="collapse navbar-collapse" id="menu">
          <ul className="navbar-nav me-auto">
            {/* <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">Inicio</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" onClick={()=>setShow(true)}>Reservar</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Nosotros
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/quienessomos"}>Quienes somos</Link></li>   
                <li><Link className="dropdown-item" to={"/losprofes"}>Los Profes</Link></li>   
                <li><Link className="dropdown-item" to={"/contactanos"}>Contactanos</Link></li>   
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Clases
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/clasessurf"}>SURF</Link></li>   
                <li><Link className="dropdown-item" to={"/clasessup"}>SUP</Link></li>   
                <li><Link className="dropdown-item" to={"/clasesaguasabiertas"}>AGUAS ABIERTAS</Link></li>   
                <li><Link className="dropdown-item" to={"/clasespequesurf"}>PEQUE SURF</Link></li>   
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Servicios
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
              <li><Link className="dropdown-item" to={"/nuestraspromos"}>Promos</Link></li>   
                <li><Link className="dropdown-item" to={"/alquilerequipos"}>Alquiler de equipos</Link></li>   
                <li><Link className="dropdown-item" to={"/surftrip"}>Surf trip</Link></li>   
              </ul>
            </li>
            




            {role && role === 1? 
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Panel Admin
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/AdminPanel/calendario"}>Calendario</Link></li>
                <li><Link className="dropdown-item" to={"/AdminPanel/reservaadmin"}>Reserva Admin</Link></li>
                {/* <li><Link className="dropdown-item" to={"/AdminPanel/generardisp"}>Generar disponibilidad</Link></li> */}
                <li><Link className="dropdown-item" to={"/AdminPanel/faltadepago"}>Deudores</Link></li>
                {/* <li><Link className="dropdown-item" to={"/AdminPanel/agregardisp"}>Agregar disponibilidad</Link></li> */}
                <li><Link className="dropdown-item" to={"/AdminPanel/quitardisp"}>Quitar disponibilidad</Link></li>
                {/* <li><Link className="dropdown-item" to={"/AdminPanel/usuarios"}>Usuarios</Link></li> */}
                <li><Link className="dropdown-item" to={"/AdminPanel/profesores"}>Profesores</Link></li>
                <li><Link className="dropdown-item" to={"/AdminPanel/modifreserva"}>Modificar Reserva</Link></li>
                <li><Link className="dropdown-item" to={"/AdminPanel/userdetail"}>Modificar perfil</Link></li>
                <li><Link className="dropdown-item" to={"/AdminPanel/promos"}>Promos</Link></li>
              </ul>
            </li>
                        
            :""}

            {role && role === 2 || role === 1? 
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Panel Profe
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/ProfPanel/calendario"}>Calendario</Link></li>
                <li><Link className="dropdown-item" to={"/ProfPanel/userdetail"}>Modificar Datos</Link></li>
              </ul>
            </li>

            :""}

            {role && role === 3 || role === 2 || role === 1? 
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Panel de Usuario
              </a>

              <ul className="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/UserPanel/userdetail"}>Modificar Datos</Link></li>
                <li><Link className="dropdown-item" to={"/UserPanel/reservauser"}>Reservas</Link></li>
                <li><Link className="dropdown-item" to={"/UserPanel/calendariouser"}>Calendario</Link></li>
              </ul>
            </li>

:""}






          </ul>

          <hr className="d-md-none text-white-50" />

          <ul className="navbar-nav  flex-row flex-wrap text-light">

           {/* <li className="nav-item col-6 col-md-auto p-3">
                <i className="bi bi-twitter"></i>
                <small className="d-md-none ms-2">Twitter</small>  
            </li> */}

          {/* {carritoLleno && carritoLleno.length>0 ?
          <li className="nav-item col-6 col-md-auto p-4">
            <Link className="nav-link" to={"/carrito"}><AddShoppingCartOutlinedIcon></AddShoppingCartOutlinedIcon></Link>
          
           </li>
          :

            
            <li className="nav-item col-6 col-md-auto p-4">
              <ShoppingCartOutlinedIcon></ShoppingCartOutlinedIcon>
               </li>
          } */}
            <li className="nav-item col-6 col-md-auto p-4">
              {isAuthenticated || userDetails.roleId ? 
               <button className="btn btn-info" onClick={LogOut}>
               Log Out
             </button>:
               
    <button className="btn btn-info"  onClick={() => loginWithRedirect()}>Log In</button>
               }
              
              {/* <a className="nav-link active" aria-current="page" href="#">Login</a> */}
            </li>


            <li className="nav-item col-6 col-md-auto p-0">
              <Clima className="d-none d-md-inline-block "></Clima>
              <Link to={"/infoclima"} className="linknone"><small className="d-md-none ms-2 linknone">Clima</small></Link>
            </li>

          </ul>
          

            
          <form className="d-flex">
            {/* <button className="btn btn-outline-warning d-none d-md-inline-block " type="submit">Informacion</button> */}
          </form>
          
          
        </div>
     
        </div> 


        <div>
        <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header closeButton>
            <Modal.Title>Reservar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Reservar></Reservar>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    
  </div>
      </nav>

  );
}
