import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import {formatDistance} from "date-fns";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { createFicha, createReview, getClasesProfeAlumno, getUserDetails } from "../../../redux/actions";
import { allreservasarray } from "../../paneladmin/funciones/allreservasarray";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const CalendarioProf = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // esto es para mandar al calendario///////////////////////////////
  const clasesProfe = useSelector((state) => state.ClasesProfAlumn);
  const userDetail = useSelector((state) => state.UserDetail);
  const [alumnex, setAlumnex] = useState(false)
  const [review, setReview] = useState({})

  const { isAuthenticated, user } = useAuth0();
  const userDetails = useSelector((state) => state.UserLog);
  const [show, setShow] = useState(false);
  const [input, setInput] = useState({
    alumno: "",
    realizado: false,
    profesoreEmail: "",
    pago: 0,
    id: 0,
  });
  var reservasA = [];

  useEffect(() => {
    if (userDetails.email) {
      dispatch(getClasesProfeAlumno({ profesoreEmail: userDetails.email }));
      
    }
  }, [userDetails]);

  if (clasesProfe.length > 0) {
    reservasA = allreservasarray(clasesProfe);
  }
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const handleClose = () => {
    setAlumnex(false)
    setShow(false)
  };
  const handleShow = () => setShow(true);
  var events = reservasA;
  const onSelectEvent = useCallback((calEvent) => {
   
    if(calEvent.desc.alumno){
      dispatch(getUserDetails(calEvent.desc.alumno))
    }

    setInput({
      ...input,
      diahorario: format(calEvent.start,"dd/MM/yyyy,HH:mm"),
      id: calEvent.desc.id,
      pago: calEvent.desc.pago,
      reservaId : calEvent.desc.reservaId,
      profesoreEmail : calEvent.desc.profesoreEmail,
      alumno: calEvent.desc.alumno,
      realizado: calEvent.desc.realizado,
      review: calEvent.desc.review

    });
    setReview(calEvent.desc.review);
    if(calEvent.desc.alumno != null){
      setAlumnex(true)
    }
    
    handleShow();
  }, []);



const edad = ()=>{
  if(userDetail.fechanac){
    const birthday = new Date(userDetail.fechanac)
const presentDay = new Date();
 return formatDistance(presentDay, birthday)
}
}

const handleInputChange = (e)=>{
  setInput({
    ...input,
    [e.target.name]: e.target.value,
    // [e.target.name]: e.target.checked,
  });
}

const handleSaveChange = ()=>{
  const env = {ficha : {}}
  if(input.review && input.review.id){
    env.ficha.userEmail = input.alumno
    env.review = {
      idClase: input.id,
      comment: input.review.comment,
      id: input.review.id,
      profesoreEmail: userDetails.email,
      rating: input.review.rating,
      tipProfe: input.tipProfe,
      userEmail: input.alumno
    }
    env.ficha.historial = {
      fecha : input.diahorario,
      ficha: input.ficha,
      profe: userDetails.name
    }
  }else{
    env.ficha.userEmail = input.alumno
    env.review = {
      idClase: input.id,
      comment: null,
      id: null,
      profesoreEmail: userDetails.email,
      rating: null,
      tipProfe: input.tipProfe,
      userEmail: input.alumno
    }
    env.ficha.historial = {
      fecha : input.diahorario,
      ficha: input.ficha,
      profe: userDetails.name
    }

  }
  dispatch(createReview(env.review))
  dispatch(createFicha(env.ficha))
  reload()

}
const reload = ()=>{
  setTimeout(function(){
    navigate(0)
}, 2000);}

const handleFicha = ()=>{
  const sabnada = userDetail.ficha.sabenadar ? "Sabe nadar: SI":"Sabe nadar: No"
  const alerg = userDetail.ficha.alergico ? "Alergico: SI":"Alergico: No"
  const enfermed = userDetail.ficha.enfermedad ? "Enfermedad: SI":"Enfermedad: No"
  const comentar = userDetail.ficha.comentario ? userDetail.ficha.comentario:"Sin comentario"
  const autoriz = userDetail.ficha.autoriza ? `Autoriza: ${userDetail.ficha.autoriza}`:""
  Swal.fire(`${userDetail.ficha && userDetail.ficha.historial? `${sabnada} <br> ${alerg}<br> ${enfermed}<br> ${comentar}<br> ${autoriz} <br>${userDetail.ficha.historial.map(a => `<p className = "display-6">${a.fecha}:${a.ficha}//${a.profe}</p>`)}`:`${sabnada} <br> ${alerg}<br> ${enfermed}<br> ${comentar}<br> ${autoriz}`}`)
}


  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
        onSelectEvent={onSelectEvent}
        popup
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{userDetail.name} {userDetail.lastname} -- {input.diahorario}</Modal.Title>
        </Modal.Header>
        {alumnex ?
          <Modal.Body>
        <img src={userDetail.avatar_url} alt="" width="100px" height="100px"/>
        <p>{userDetail.fechanac}</p>
        <p>Edad: {edad()}</p>
        <p >Cant clases tomadas: {userDetail.cantclases}</p>
        <p>Review alumno: {input.review && input.review.comment ? input.review.comment + " " +input.review.rating:"SIN REVIEW" }</p>
        {input.review && input.review.tipProfe ? <p>Tip Profe: {input.review.tipProfe}</p>:
         input.realizado?
        <div>
          <label>Tip alumno:</label>
          <textarea name="tipProfe" placeholder="TIP PARA EL ALUMNO" onChange={handleInputChange}></textarea>
          <br />
          <label>Ficha:</label>
          <textarea name="ficha" placeholder="FICHA DEL ALUMNO" onChange={handleInputChange}></textarea>

        </div>
        :""}
        <button onClick={handleFicha}>FICHA</button>
        </Modal.Body>
        :
        <Modal.Body>
          <p>sin alumno asignado</p>
        </Modal.Body>

        
      }
          
          
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {input.alumno && input.profesoreEmail && input.ficha && input.tipProfe?
          <Button variant="primary" onClick={handleSaveChange}>
            Save Changes
          </Button>
          :""}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
