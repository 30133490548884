import * as actionTypes from "../action-types/";
import { arreglarDias } from "./funciones/arreglarDias";

const initialState = {
  Windguru: [],
  Clima: [],
  Marea: [],
  DisponibilidadXDia: [],
  Deports: [],
  DiaSeleccionado: {},
  DeporteSeleccionado: "",
  RespuestaASacarDisp: "",
  AllReservas: [],
  AllReservasTotales: [],
  AllUsers: [],
  UserCreated: {},
  CreateReserva: [],
  ReservasPorUsuario: [],
  ReservaById: [],
  UpdateReservaAlumnos: [],
  AllProfes: [],
  UpdateClaseAdmin: [],
  UserLog:[],
  ClasesProfAlumn:[],
  UserDetail:[],
  CodigoXid: {},
  MP_link: {},
  AllPromos: [],
  AllReviews: []
};
const a = {};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WINDGURU:
      return {
        ...state,
        Windguru: action.payload,
      };
    case actionTypes.GET_MAREA:
      return {
        ...state,
        Marea: action.payload,
      };
    case actionTypes.GET_CLIMA:
      return {
        ...state,
        Clima: action.payload,
      };
    case actionTypes.GET_DISPONIBILIDADXDIA:
      return {
        ...state,
        DisponibilidadXDia: action.payload,
      };
    case actionTypes.GET_DEPORTS:
      return {
        ...state,
        Deports: action.payload,
      };
    case actionTypes.DEPORTE_SELECCIONADO:
      return {
        ...state,
        DeporteSeleccionado: action.payload,
      };
    case actionTypes.SACAR_DIAS_DISPONIBLES:
      if (action.payload === "correcto") {
        return {
          ...state,
          RespuestaASacarDisp: state.DiaSeleccionado,
        };
      } else {
        return {
          ...state,
          RespuestaASacarDisp: action.payload,
        };
      }
    case actionTypes.DIAS_SELECCIONADOS:
      return {
        ...state,
        DiaSeleccionado: arreglarDias(state.DiaSeleccionado, action.payload),
      };
    case actionTypes.RESET_DISPONIBILIDADXDIA:
      return {
        ...state,
        DisponibilidadXDia: [],
      };
    case actionTypes.GET_ALL_RESERVAS:
      return {
        ...state,
        AllReservas: action.payload,
      };
    case actionTypes.GET_RESERVA_BY_ID:
      return {
        ...state,
        ReservaById: action.payload,
      };
    case actionTypes.GET_ALL_RESERVAS_TOTALES:
      return {
        ...state,
        AllReservasTotales: action.payload,
      };
    case actionTypes.GET_RESERVAS_POR_USUARIO:
      return {
        ...state,
        ReservasPorUsuario: action.payload,
      };
    case actionTypes.GET_ALL_USERS:
      return {
        ...state,
        AllUsers: action.payload
      };
    case actionTypes.GET_USER_FOR_ID:
      return {
        ...state,
        UserDetail: action.payload
      };
    case actionTypes.CREATE_USER:
      return {
        ...state,
        UserCreated: action.payload
      };
    case actionTypes.CREATE_RESERVA:
      return {
        ...state,
        CreateReserva: action.payload
      };
    case actionTypes.UPDATE_RESERVA_ALUMNOS:
      return {
        ...state,
        UpdateReservaAlumnos: action.payload
      };
    case actionTypes.UPDATE_CLASE_ADMIN:
      return {
        ...state,
        UpdateClaseAdmin: action.payload
      };
    case actionTypes.GET_CLASE_PROFE_ALUMNO:
      return {
        ...state,
        ClasesProfAlumn: action.payload
      };
    case actionTypes.GET_ALL_PROF:
      return {
        ...state,
        AllProfes: action.payload
      };
      case actionTypes.USER_LOGIN:
        return {
          ...state,
          UserLog: action.payload,
        }
      case actionTypes.USER_LOGOUT:
        return {
          ...state,
          UserLog: [],
        }
      case actionTypes.VERIFICAR_PROMO:
        return {
          ...state,
          CodigoXid: action.payload,
        }
      case actionTypes.GET_ALL_PROMO:
        return {
          ...state,
          AllPromos: action.payload,
        }
      case actionTypes.GET_ALL_REVIEW:
        return {
          ...state,
          AllReviews: action.payload,
        }
      case actionTypes.CREATE_MP_LINK:
        return {
          ...state,
          MP_link: action.payload,
        }

    default:
      return state;
  }
};

export default rootReducer;
