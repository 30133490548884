export function sacarDisp(arreglo,horariosusados) {

    // var arreglo = [1,2,3,4,35,5,3];
    // var horariosusados = [1,3,3,3]
    for(var i = 0 ; i<horariosusados.length ; i++){
      var indice = arreglo.indexOf(horariosusados[i]);
      if(indice === -1){
        arreglo = []
        i = 1000000000000
      }else{
        arreglo.splice(indice, 1);
      }
      
    }
  return arreglo
}