import React from 'react'
import { Footer } from '../footer/Footer'
import "./clasesaguas.css"

export const ClasesAguas = () => {
  return (
    <div className="home__clasesaguas">
    <header className="hero__clasesaguas">
      <section className="hero__main__clasesaguas container__clasesaguas">
        <h1 className="hero__title__clasesaguas">AGUAS ABIERTAS<br></br>“nadar en el Mar es toda una aventura”</h1>
      </section>
    </header>
    <main>
      <section className="services__clasesaguas">
        <div className="service_image--clasesaguas"></div>
        <div className="services__texts__clasesaguas">
          <h2 className="services__title__clasesaguas"> Clases</h2>
          <p className="services__paragraph__clasesaguas">
          Viví esta experiencia única de la mano de nuestro equipo de profesionales. Instructores y guardavidas te acompañaran durante todo el recorrido para que puedas lograr tu objetivo de la manera más segura!</p>
        </div>
        <div className="service__image--iynmaguas"></div>

        <div className="services__texts__clasesaguas services__texts--2__clasesaguas">
          <h2 className="services__title__clasesaguas"> Iniciación</h2>
          
          <li>Programa especial de ambientación: nivel 1 y 2, para quienes nunca nadaron en Mar o para aquellos que recién empiezan</li>
          <li>Acompañamiento profesional permanente dentro y fuera del agua</li>
          <li>Equipo de Seguridad y Traje de Neoprene corto incluidos</li>
          <li>Duración de la clase en base al objetivo</li>
          
        </div>
      </section>
      
      <section className="services__clasesaguas2">
        <div className="service__image--aguadv"></div>
        <div className="services__texts__clasesaguas services__texts--5__clasesaguas">
          <h2 className="services__title__clasesaguas">Nivel Medio y Avanzado</h2>
          
          <li>Diseño de planes específicos según el objetivo (Carreras, Entrenamientos, Acondicionamiento, etc)</li>
          <li>Acompañamiento profesional permanente dentro y fuera del agua</li>
          <li>Equipo de seguridad y Traje de Neoprene corto incluidos</li>
          <li>Duración de la clase en base al objetivo</li>
          
        </div>
      </section>
      
    </main>
    
<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
