import React, { Component,  useState } from 'react';


export default function CardWindguru (s) {
  
    

    return (
        <>
            {s.diaN.horario.map((c,i)=> 
            <div className='item'>
                    <div className='item__fecha'>{s.diaN.fecha + " " + s.diaN.letra}</div>
                    <div className='item__hora'>{s.diaN.horario[i]} H</div>
                    <div className='item__viento'>{s.diaN.viento[i]} km/h</div>
                    <div className='item__rafagas'>{s.diaN.rafagas[i]} km/h</div>
                    <div className='item__direcv'>{s.diaN.direcV[i].split(" ")[0]}</div>
                    <div className='item__temp'>{s.diaN.temp[i]}°</div>
                    <div className='item__nubes'>{s.diaN.nubes[i].slice(0,3)}</div>
                    <div className='item__precip'>{s.diaN.precipitaciones[i]} mm</div>
                    <div className='item__olas'>{s.diaN.olas[i]} m</div>
                    <div className='item__periodo'>{s.diaN.periodo[i]} s</div>
                    <div className='item__direco'>{s.diaN.direcO[i].split(" ")[0]}</div>

        </div>)}
            
        </>
    );
};