import React from "react";
import "./Home.css";
import Calpreservas from "../calpreservas/calpreservas";
import { BotonReservar } from "../botonreservar/BotonReservar";
import { BotonPagar } from "../botonpagar/BotonPagar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReview } from "../../redux/actions";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Reservar } from "../reservar/Reservar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Footer } from "../footer/Footer";

const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const allReviews = useSelector((state) => state.AllReviews);
  const [tresReviews, setTresReviews] = useState([])
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getAllReview())
    
  }, [])
  
  const solotresreviews = (aR)=>{
    if(aR.length > 0 && aR.length < 3){
      setTresReviews(aR)
    }else{
      function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
      }
      
      function randomElements(array, quantity) {
    return shuffleArray([...array]).slice(0, quantity);
  }
  setTresReviews(randomElements(aR,3))
    }
    
  }
  useEffect(() => {
    if(allReviews && allReviews.length > 0){
      solotresreviews(allReviews)
    }
  }, [allReviews])
  
  const ratingmargin = (rating)=>{
    // const num = rating*10
    
    // return (100 - num)+ "%" 
    const num = rating/2
    return num 
  }

const handleClose = () => {
  setShow(false)
  localStorage.clear();
  navigate(0)
};

  return (
    <div className="home">
      <header className="hero">
        <section className="hero__main container">
          <h1 className="hero__title">Todo el mundo puede Surfear, <br></br> ¡Animate!</h1>
          <div class="container__button">

    <button onClick={()=>setShow(true)} class="button button--3">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="fish"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <span class="button__text">R E S E R V A</span>
    </button>

</div>
        </section>
      </header>
      <main>
        <section className="services">
          <div className="services__image service_image--1"></div>
          <div className="services__texts">
            <h2 className="services__title"> Clases</h2>
            <p className="services__paragraph">
            Clases de Surf Personalizadas. Individuales y Grupales. Todos los nivles. Todas las Edades.
            </p>
            <ul className="" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to={"/clasessurf"}>SURF</Link></li>   
                <li><Link className="dropdown-item" to={"/clasessup"}>SUP</Link></li>   
                <li><Link className="dropdown-item" to={"/clasesaguasabiertas"}>AGUAS ABIERTAS</Link></li>   
                <li><Link className="dropdown-item" to={"/clasespequesurf"}>PEQUE SURF</Link></li>   
              </ul>
          </div>
          <div className="services__image service__image--2"></div>

          <div className="services__texts services__texts--2">
            <h2 className="services__title"> Profesores</h2>
            <p className="services__paragraph">
              Somos el mejor equipo para acompañarte en esta aventura.
            </p>
            <Link className="services__cta" to={"/losprofes"}>MAS INFO</Link>
  
          </div>
        </section>
        <section className="jobs">
      <div className="jobs__section">
        <h2 className="jobs__name">
          SURF</h2>

        <p className="jobs__paragraph">
          No importa cual sea tu nivel, tenemos una propuesta especial para vos!!. Iniciacion. Nivel Medio. Perfeccionamiento.
        </p>
      </div>
      <div className="jobs__section jobs__section--second">
        <h2 className="jobs__name">
        PADDLE SURF</h2>

        <p className="jobs__paragraph">
          Deslizate en la ola desde una perspectiva distinta.
        </p>
      </div>
      <div className="jobs__section jobs__section--terc">
        <h2 className="jobs__name">
        AGUAS ABIERTAS</h2>

        <p className="jobs__paragraph">
          Nadar en el mar es toda una aventura. Hacelo de la manera mas segura y con los mejores profes!
          </p>
      </div>
      <div className="jobs__section jobs__section--cuart">
        <h2 className="jobs__name">
        PEQUE SURF</h2>

        <p className="jobs__paragraph"> Una propuesta diseñada especialmente para los mas chiquitos.</p>
      </div>
    </section>
    <section className="testimonials container">
      
      
      <h2 className="testimonials__title"> Opiniones de Alumnos</h2>

      <div className="testimonials__container">
      {tresReviews.length > 0 ? 
      tresReviews.map(e => 
          <div key={e.review.id}>
          <article className="testimonials__item">
          <img src={e.review.user.avatar_url} className="testimonials__img"/>
          <h3 className="testimonials__name">{e.review.user.name}</h3>
          <p className="testimonials__paragraph">
            {e.review.comment}
          </p>
          {/* <img src={e.review.user.avatar_url} height="50px"/>
          <img src={e.review.user.avatar_url} height="50px"/>
          <img src={e.review.user.avatar_url} height="50px"/>
          <img src={e.review.user.avatar_url} height="50px"/>
          <img src="/images/olasreviews.png" alt="" /> */}
          <div className="divolasreviews">
          <div className="olasreviews">
            {ratingmargin(e.review.rating)>=5?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=4.7?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatercio.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=4.5?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olamedio.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=4.2?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olacuarto.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=4?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=3.7?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatercio.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=3.5?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olamedio.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=3.2?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olacuarto.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            :
            ratingmargin(e.review.rating)>=3?
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            :
            <>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatotal.png" alt="" width="40px"/>
            <img src="/images/reviews/olatercio.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            <img src="/images/reviews/olacero.png" alt="" width="40px"/>
            </>
            
            
          }
          
          </div>
          </div>
          <p className="testimonials__job">Profe: {e.profe.name}
          </p>
          </article>
          </div>
        )
        :""}
        </div>
    </section>
    <section className="gallery">
      <div className="gallery__image"></div>
      <div className="gallery__image"></div>
      <div className="gallery__image"></div>
      <div className="gallery__image"></div>
    </section>
      </main>
      {/* <footer className="footer">
    <div className="footer__container container">
      <img src="https://res.cloudinary.com/dkn2dgfex/image/upload/v1671063169/logo/logofondotransp320_bminry.png" className="footer__logo"/>

      <nav className="footer__nav">
        <Link to={"/quienessomos"} className="footer__link">Quienes somos</Link>
        <Link to={"/losprofes"} className="footer__link">Los Profes</Link>
        <Link to={"/clases"} className="footer__link">Clases</Link>
      </nav>
      <nav className="footer__nav">
        <Link to={"/promos"} className="footer__link">Promos</Link>
        <Link to={"/alquilerdeequipos"} className="footer__link">Alquiler de Equipos</Link>
        <Link to={"/surftrip"} className="footer__link">Surf trip</Link>
      </nav>
      <nav className="footer__nav">
        <Link className="footer__link" onClick={()=>setShow(true)}>Reserva</Link>
        <Link to={"/infoclima"} className="footer__link">Clima</Link>
        <Link to={"/contactanos"} className="footer__link">Contactanos</Link>
      </nav>

      <nav className="footer__social">
        <a href="https://www.instagram.com/sudestadapinamar/" target="_blank" className="footer__media">
        <i className="bi bi-instagram" style={{"font-size": "2rem" }}></i>
        </a>
        <a href="https://www.facebook.com/surfsudestada" target="_blank" className="footer__media">
        <i className="bi bi-facebook" style={{"font-size": "2rem" }}></i>
        </a>
        
        <a href="https://wa.me/5492254587288" target="_blank" className="footer__media">
        <i className="bi bi-whatsapp" style={{"font-size": "2rem" }}></i>
        </a>
      </nav>
    </div>
  </footer> */}
  <Footer></Footer>
  <div>
  <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header closeButton>
            <Modal.Title>Reservar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Reservar></Reservar>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
    
  </div>
      {/* <div>
        <Calpreservas></Calpreservas>
      </div>
      <div>
        <BotonReservar></BotonReservar>
      </div>
      <div>
        <BotonPagar></BotonPagar>
      </div> */}
    </div>
  );
};

export default Home;
