import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { getAllReservas, getAllReservasTotales } from "../../redux/actions";

import { Calendario } from "../calendario/Calendario";
import { allreservasarray } from "./funciones/allreservasarray";

export const PanelAdmin = () => {
  const dispatch = useDispatch();

  // esto es para mandar al calendario///////////////////////////////
  // const allReservasTotales = useSelector((state) => state.AllReservasTotales);
  // var reservasA = [];

  // useEffect(() => {
  //   dispatch(getAllReservasTotales());
  // }, []);

  // if (allReservasTotales.length > 0) {
  //   reservasA = allreservasarray(allReservasTotales);
  // }
  /////////////////////////////////////

  return (
    <div>
      {/* <Link to="calendario">calendario</Link>
      <br />
      <Link to="generardisp">generar disponibilidad</Link>
      <li className="nav-item">
              <Link className="nav-link" to={"reservaadmin"}>reserva admin</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"usuarios"}>usuarios</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"profesores"}>profesores</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"modifreserva"}>modificar reserva</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"userdetail"}>modificar datos</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={"promos"}>modificar promos</Link>
            </li> */}
      <Outlet/>
      {/* {reservasA && reservasA.length > 1 ? (
        <Calendario reservasA={reservasA}></Calendario>
      ) : (
        ""
      )} */}
    </div>
  );
};
