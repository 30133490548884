import React, { useEffect } from 'react';
import CardMarea from './CardMarea';



export default function CardsMarea(a) {

  return (
    <>
      {a.datosMarea.map(c => <CardMarea {...c}/>)}
    </>
  );
}