import React, { useEffect } from 'react';
import CardWindguru from './CardWindguru';
import CardWindguru2 from './CardWindguru2';
// import './Games.css';
// import Game from './Game';
// import { connect } from "react-redux";
// import { obtenerGames } from '../redux/actions/gamesAction';
// import './contenedor.css'



export default function CardsWindguru(a) {

    var primeros = a.datosWindguru.slice(0, 3)
    var ultimos = a.datosWindguru.slice(1)
    

  return (
    <>
      {/* {<CardWindguru {...a.datosWindguru[0]}></CardWindguru>} */}
      {a.datosWindguru.map(c => <CardWindguru {...c}/>)}
    </>
  );
}
