import React from 'react'
import { Carusel } from '../carusel/Carusel'
import { Footer } from '../footer/Footer'
import "./losprofes.css"
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProfesores } from '../../redux/actions';
import { useState } from 'react';


export const LosProfes = () => {




    const dispatch = useDispatch()



    const allProfes = useSelector((state) => state.AllProfes);
    const [items, setItems] = useState([])



    useEffect(() => {
      dispatch(getAllProfesores())
    }, [])
    useEffect(() => {
      if(allProfes && allProfes.length>0){
        setearitems()
      }
    }, [allProfes])
    const setearitems = ()=>{
        const itema = allProfes.map((a, index) => ({
        
            alt: 'A random photo',
            image: a.user.avatar_url,
            content: (
              <div>
                <strong>{a.user.name}</strong>
              </div>
            ),
          }))
        setItems(allProfes.map((a, index) => ({
        
            alt: 'A random photo',
            image: a.user.avatar_url,
            content: (
              <div>
                <strong>{a.user.name}</strong>
              </div>
            ),
          })).concat(itema))
    }

    const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  


  return (
    <div>
        <div className="body__losprofes">
            <header className='hero__losprofes'>
                <div className="hero__texts__losprofes">
                    <h1 className="hero__title__losprofes">
                    NUESTROS PROFES
                    </h1>
                </div>
                <img src="./wave.svg" className='hero__wave__losprofes' alt="" />
            </header>
            <div className="grid2__losprofes">
                <div className="grid2__img__losprofes"></div>
                <div className="grid2__texts__losprofes">
                    <h2 className="grid2__title__losprofes">LOS PROFES</h2>
                    <p className="grid2__paragraph__losprofes">No son los mejores simplemente porque clase a clase logran trasmitir ese amor por lo que hacen, o por darte seguridad y apoyo en todo momento sin dejar de lado la diversión en el agua; Lo son también por su profesionalidad: Instructores de Surf, Profes de Educación Física, Instructores de Natación y Guardavidas. 
Este combo de títulos, su experiencia, y su magia; solo pueden dar como resultado dos cosas: “un EQUIPAZO” y “la satisfacción asegurada”
</p>
                </div>
            </div>
            {width > 768?
            
            <Carusel items={items}></Carusel>
        :
        allProfes && allProfes.length>0? 
        allProfes.map(e => 
        <div className='contenedor__cards__profe'>
            <img className="div__img__profe" src={e.user.avatar_url} alt=''>
        </img>
            <h2 className="h2__name__profe">
        {e.user.name}</h2>
            
            </div>
        )
        
        :""
        }
        <Footer></Footer>
        </div>
        


    </div>
  )
}
