import React from 'react'
import { Link, Outlet } from 'react-router-dom'

export const PanelUser = () => {
    return (
        <div>
          
          <Outlet/>
        </div>
      )
}
