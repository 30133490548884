import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { GenerarDisponibilidad } from "./component/admin/generardisponibilidad/GenerarDisponibilidad";
import { ModificarReservaAdmin } from "./component/admin/modificarreserva/ModificarReservaAdmin";
import { ProfesControl } from "./component/admin/profecontrol/ProfesControl";
import { ReservaAdmin } from "./component/admin/reservaadmin/ReservaAdmin";
import { UsersControl } from "./component/admin/usercontrol/UsersControl";
import BotonesRedes from "./component/botonesredes/BotonesRedes";
import { Calendario } from "./component/calendario/Calendario";
import { CalendarioAdmin } from "./component/calendario/CalendarioAdmin";
import Home from "./component/home/Home";
import InfoClima from "./component/infoclima/InfoClima";
import Navbar from "./component/navbar/Navbar";
import Noticias from "./component/noticias/Noticias";
import { PanelAdmin } from "./component/paneladmin/PanelAdmin";
import { CalendarioProf } from "./component/prof/calendarioprof/CalendarioProf";
import { PanelProf } from "./component/prof/panelProf/PanelProf";
import { ReservasUsuario } from "./component/user/reservasusuario/ReservasUsuario";
import { ModificarReserva } from "./component/user/modificarReserva/ModificarReserva";
import { PanelUser } from "./component/user/panelUser/PanelUser";
import { UserDetail } from "./component/userdetail/UserDetail";
import { CalendarioUser } from "./component/user/calendariouser/CalendarioUser";
import { Carrito } from "./component/carrito/Carrito";
import { Promos } from "./component/admin/promos/Promos";
import { Agregardisponibilidad } from "./component/admin/agregardisponibilidad/Agregardisponibilidad";
import { Quitardisponibilidad } from "./component/admin/quitardisp/Quitardisp";
import { FaltadePago } from "./component/admin/faltadepago/FaltadePago";
import { LosProfes } from "./component/losprofes/LosProfes";
import { ClasesSurf } from "./component/clases/ClasesSurf";
import { ClasesSup } from "./component/clases/ClasesSup";
import { ClasesAguas } from "./component/clases/ClasesAguas";
import { ClasesPeques } from "./component/clases/ClasesPeques";
import { Quienessomos } from "./component/quienessomos/Quienessomos";
import { Contactanos } from "./component/contactanos/Contactanos";
import { Alquilerequipos } from "./component/alquilerequipos/Alquilerequipos";
import { Surftrip } from "./component/surftrip/Surftrip";
import { Nuestraspromos } from "./component/promos/Nuestraspromos";
import { Paymentmp } from "./component/paymentmp/Paymentmp";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "./redux/actions";
import { useAuth0 } from "@auth0/auth0-react";
import ScrollToTop from "./component/scrolltotop/Scrolltotop";


function App() {





  const dispatch = useDispatch()

  const { loginWithRedirect, logout, isAuthenticated,  user} = useAuth0();
  const userDetails = useSelector((state) => state.UserLog);

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem("logged")
    if(loggedUserJSON){
      const userlogg = JSON.parse(loggedUserJSON)
      dispatch(userLogin(userlogg))
    }
    
  }, [])
  

  useEffect(() => {
    if (user) {
      window.localStorage.setItem(
        "logged", JSON.stringify(user)
      )
      dispatch(userLogin(user))
    };
  }, [user]);

  const role = userDetails.roleId














  return (
    <div className="App">
      <Navbar></Navbar>
      <ScrollToTop></ScrollToTop>
      <Routes>
        
        <Route exact path="/" element={<Home></Home>} />
        <Route exact path="/carrito" element={<Carrito></Carrito>} />
        <Route exact path="/noticias" element={<Noticias />} />
        <Route exact path="/quienessomos" element={<Quienessomos />} />
        <Route exact path="/contactanos" element={<Contactanos />} />
        <Route exact path="/losprofes" element={<LosProfes />} />
        <Route exact path="/clasessurf" element={<ClasesSurf />} />
        <Route exact path="/clasessup" element={<ClasesSup />} />
        <Route exact path="/clasesaguasabiertas" element={<ClasesAguas />} />
        <Route exact path="/clasespequesurf" element={<ClasesPeques />} /> 
        <Route exact path="/nuestraspromos" element={<Nuestraspromos />} /> 
        <Route exact path="/alquilerequipos" element={<Alquilerequipos />} /> 
        <Route exact path="/surftrip" element={<Surftrip />} /> 
        <Route exact path="/paymentmp" element={<Paymentmp />} /> 


        {role && role === 1? 
        <Route  path="/AdminPanel/*" element={<PanelAdmin />} >
          <Route path="calendario" element={<CalendarioAdmin />} />
          <Route path="reservaadmin" element={<ReservaAdmin />} />
          <Route path="generardisp" element={<GenerarDisponibilidad />} />
          <Route path="agregardisp" element={<Agregardisponibilidad />} />
          <Route path="faltadepago" element={<FaltadePago />} />
          <Route path="quitardisp" element={<Quitardisponibilidad />} />
          <Route path="usuarios" element={<UsersControl />} />
          <Route path="profesores" element={<ProfesControl />} />
          <Route path="modifreserva" element={<ModificarReservaAdmin />} />
          <Route path="userdetail" element={<UserDetail />} />
          <Route path="promos" element={<Promos />} />
        </Route>
        :""}


        {role && role === 2 || role === 1? 
        <Route exact path="/ProfPanel/*" element={<PanelProf />} >
          <Route path="calendario" element={<CalendarioProf />} />
          <Route path="userdetail" element={<UserDetail />} />
        </Route>
        :""}

        {role && role === 3 || role === 2 || role === 1? 
        <Route path="/UserPanel/*" element={<PanelUser />}>
          <Route path="userdetail" element={<UserDetail />} />
          <Route path="calendariouser" element={<CalendarioUser />} />
            <Route path="reservauser" element={<ReservasUsuario />} />
              <Route path="reservauser/modificarreserva/:id" element={<ModificarReserva />} />
        </Route>
        :""}
        
        
        <Route exact path="/infoclima" element={<InfoClima />} />
      </Routes>
      <BotonesRedes></BotonesRedes>
    </div>
  );
}

export default App;
