export const paraEnvGenDisp = (data)=>{
    const disp = []
    for(let i = 0 ; i < data.dias.length; i++){
      const obj = {
        diadisp: data.dias[i].diadisp,
        profedisp: ["agu"],
        deport: data.deport,
        horariosdisp : data.horarios,
        cantprofdisp: data.cantprofdisp
      }
      var horariosusados = []
      for(let x = 0 ; x < data.cantprofdisp ; x++){
        var h = data.horarios
        var a = horariosusados.concat(h)
        horariosusados = a
      }
    obj.horariosusados = horariosusados
    disp.push(obj)
      
    }
  return disp
  }