export function sacarCantDiasSelec(seleccionados) {
    var cantDias = 0
    const dias = Object.keys(seleccionados)
    for (let i = 0; i < dias.length; i++) {
        const diaN = seleccionados[dias[i]]
        const horarios = Object.keys(diaN)
        var sum = 0
        for (let j = 0; j < horarios.length; j++) {
            if(diaN[horarios[j]] > 0){
                sum = 1
            }
            
        }
        cantDias = cantDias + sum
        
    }
    return cantDias

}

export function siPasaDePersonas(seleccionados, cantdepersonas) {
    var cantDias = 0
    const dias = Object.keys(seleccionados)
    
    for (let i = 0; i < dias.length; i++) {
        const diaN = seleccionados[dias[i]]
        const horarios = Object.keys(diaN)
        var sum = 0
        for (let j = 0; j < horarios.length; j++) {
            if(diaN[horarios[j]] > 0){
                const hc = parseInt(diaN[horarios[j]])
                sum = sum + hc 
            }
            if(sum > cantdepersonas){
                return "error"
            }
        }
        
    }
    return cantDias

}



export function allreservasarray(reservas) {
    const respuesta = [
      {
        title: "Vacation",
        start: new Date(2022, 10, 15, 15),
        end: new Date(2022, 10, 15, 15),
      },
      {
        title: "Vacation ",
        start: new Date(2022, 10, 15, 15),
        end: new Date(2022, 10, 15, 15, 1),
        desc: { cantper: 2, nombre: "Agu" },
      },
    ];
    
    const resp = [];
    for (let i = 0; i < reservas.length; i++) {
      const desc = {
        cantdiasreserva: reservas[i].reserva.cantdiasreserva,
        cantpersonasreserva: reservas[i].reserva.cantpersonasreserva,
        deportDeport: reservas[i].reserva.deportDeport,
        reservaId: reservas[i].reservaId,
        pago: reservas[i].reserva.pago,
        realizado: reservas[i].reserva.realizado,
        userEmail: reservas[i].reserva.userEmail,
        idreservatotales: reservas[i].id,
        profesores: reservas[i].profesore,
        profesoreEmail: reservas[i].profesoreEmail,
        reservaalumnos: reservas[i].reservaalumnos,
        review: reservas[i],
      };
      const title =
        reservas[i].user.name +
        " " +
        reservas[i].user.lastname +
        " " +
        reservas[i].reserva.deportDeport;
  
      const a = reservas[i].fecha;
      const año = parseInt(a.substr(0, 4));
      const mes = parseInt(a.substr(5, 2)) - 1;
      const dia = parseInt(a.substr(8, 2));
      const hora = parseInt(reservas[i].hora);
      const obj = {
        title: title,
        start: new Date(año, mes, dia, hora),
        end: new Date(año, mes, dia, hora),
        desc: desc,
      };
      resp.push(obj);
    }
  
    //     for (let i = 0; i < reservas.length; i++) {
    //         for (let j = 0; j < reservas[i].diasreserva.length; j++) {
    //             const desc = {
    //                 cantdiasreserva : reservas[i].cantdiasreserva,
    //                 cantpersonasreserva : reservas[i].cantpersonasreserva,
    //                 deportDeport : reservas[i].deportDeport,
    //                 id : reservas[i].id,
    //                 pago : reservas[i].pago,
    //                 profesor : reservas[i].profesores,
    //                 realizado: reservas[i].realizado,
    //                 userEmail: reservas[i].userEmail
  
    //             }
    //             const title = reservas[i].user.name +" "+ reservas[i].user.lastname+ " "+ reservas[i].deportDeport
    //             for (let k = 0; k < reservas[i].diasreserva[j].horario.length; k++) {
  
    //                 const a = reservas[i].diasreserva[j].dia
    //                 const año = parseInt(a.substr(0,4))
    //                 const mes = parseInt(a.substr(5,2))-1
    //                 const dia = parseInt(a.substr(8,2))
    //                 const hora = parseInt(reservas[i].diasreserva[j].horario[k])
  
    //                const obj = {
    //                 title: title,
    //                 start: new Date(año,mes,dia,hora),
    //                 end: new Date(año,mes,dia,hora),
    //                 desc: desc,
    //                }
    //                resp.push(obj)
  
    //             }
  
    //         }
  
    //     }
  
    return resp;
  }


  export function acomodamientoDiasH(a){
    const diasreserva = []
    const arrayDias = Object.keys(a)
    for(let i = 0 ; i<arrayDias.length ; i++){
      const horarios = Object.keys(a[arrayDias[i]])
      const obj = {
        dia : arrayDias[i],
        horario : []
      }
      
      for(let x = 0 ; x<horarios.length ; x++){
        var cantHorarios = parseInt(a[arrayDias[i]][horarios[x]])
        const hora = horarios[x]
        if(cantHorarios > 0){
          while (cantHorarios > 0) {
              obj.horario.push(hora)
            cantHorarios = cantHorarios -1 
            }
        }
        
      }
      diasreserva.push(obj)
    }
    return diasreserva
  }
  