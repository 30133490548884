import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { verificarPagoMP } from '../../redux/actions'
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react'

export const Paymentmp = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(true);
    const [status, setStatus] = useState("")
    const [external_reference, setExternal_reference] = useState("")
    const [payment_id, setPayment_id] = useState("")
   const {search} = useLocation()

   
   useEffect(() => {
    
     const query = new URLSearchParams(search)
     setStatus(query.get("status"))
    setExternal_reference(query.get("external_reference"))
     setPayment_id(query.get("payment_id"))
     
      
      
    }, [])


    useEffect(() => {
      if(status === "approved"){
        const obj = {
            idpayment : payment_id,
            id : external_reference,
            email : ""
        }
        dispatch(verificarPagoMP(obj))
      }
    }, [status])
    

    // const mandarcosas = ()=>{

    //   if(status === "approved"){
    //     const obj = {
    //         idpayment : payment_id,
    //         id : external_reference,
    //         email : ""
    //     }
    //     dispatch(verificarPagoMP(obj))
    //   }
    //   // setTimeout(() => {

  
    //   //  }, 2000);
 
    // }
    // console.log(status)


    // Swal.fire({
    //   title: 'Custom width, padding, color, background.',
    //   width: 600,
    //   padding: '3em',
    //   color: '#716add',
    //   background: '#fff url(https://res.cloudinary.com/dkn2dgfex/image/upload/v1671063168/logo/logofondoblanco320_akj1s7.png) center no-repeat',
    //   backdrop: `
    //     rgba(0,0,123,0.4)
    //     url("/images/nyan-cat.gif")
    //     left top
    //     no-repeat
    //   `,
    //   confirmButtonText: 'Ir a reservas',
    //   inputValidator: (result) => {
    //     return console.log("aaaa")
    //   }
      
    // })
    
    if(status === "approved"){
  return (
    <div>
            <Alert show={show} variant="success">
        <Alert.Heading>Ya tienes tus clases</Alert.Heading>
        <p>
        Recorda que para confirmar tu lugar, tenes que abonar el importe restante 3 días antes de tu primer clase..  
Podes hacerlo mediante: MERCADO PAGO: sudestada.pinamar ; <br />
TRANSFERENCIA/DEPÓSITO: Banco Galicia CTA: 4009799-3 368-3 CBU:00703688-30004009799333 <br />
EFECTIVO: Escuela de Surf SUDESTADA (Av. Olimpo y Playa) <br />

No te olvides de mandarnos el comprobante x whatsapp: +5492254587288 <br />

Verifica la casilla de spam de tu correo, puede que nuestros mails esten ahi.<br />

Acordate de rellenar tus datos desde tu panel de usuario.<br />

Gracias!
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => navigate("/UserPanel/userdetail")} variant="outline-success">
          {/* <Button onClick={mandarcosas} variant="outline-success"> */}
            Ir a mis reservas
          </Button>
        </div>
      </Alert>
      
    </div>
  )
}
else{
  
  return (
    <div>
      
            <Alert show={show} variant="danger">
        <Alert.Heading>UP! hubo un error!</Alert.Heading>
        <p>
        Tu pago no se realizo por favor volve a intentar mas tarde. <br />
        Cualquier consulta escribinos <br />
        Gracias.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => navigate("/")} variant="danger">
            Ir al HOME
          </Button>
        </div>
      </Alert>

    
    </div>
  )
  
}


}
