import React from "react";
import { Link } from "react-router-dom";
import "./BotonesRedes.css";



export default function BotonesRedes() {
  return (
    <div className="">
        <a href="https://wa.me/5492254587288" target="_blank" rel="noopener noreferrer" className="btn-wsp">
        <i className="bi bi-whatsapp"></i>
        </a>
    </div>

  );
}
