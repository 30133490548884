import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  agregarProfeAdmin,
    deleteProfeAdmin,
  getAllProfesores,
  getAllUsers,
  updateProfeAdmin,
} from "../../../redux/actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DataGrid } from '@mui/x-data-grid';
import "./profescontrol.scss"

export const ProfesControl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allProfes = useSelector((state) => state.AllProfes);
  const allUsers = useSelector((state) => state.AllUsers);
  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [userSelect, setUserSelect] = useState({});
  const [profSelec, setProfSelec] = useState({})
  const [input, setInput] = useState({
    avatar_url: "",
    cantclases: "",
    deslingue: false,
    dni: "",
    email: "",
    fechanac: "",
    instagram: "",
    lastname: "",
    name: "",
    roleId: 3,
    tel: "",
    emailAnt: "",
  });

  useEffect(() => {
    dispatch(getAllProfesores());
    dispatch(getAllUsers());
  }, []);

  const handleClose = () => setShow(false);
  const handleCloseDel = () => setShowDel(false);
  const handleCloseCreate = () => setShowCreate(false);

  const handleInputChange = function (e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
      // [e.target.name]: e.target.checked,
    });
  };
  const reload = ()=>{
    setTimeout(function(){
     navigate(0)
 }, 2000);}
  const handleModif = () => {
    dispatch(updateProfeAdmin(input));
    setShow(false);
    setInput({});
    reload();
  };

  const handleDelete = () => {
    dispatch(deleteProfeAdmin(input));
    dispatch(getAllUsers());
    setInput({});
    handleCloseDel();
    reload();
  };

  const handleInputP = (e) => {
    setProfSelec(e.target.value)
  }

  const handleCreateProfe = ()=>{
    const env = {
      email : profSelec
    }
    dispatch(agregarProfeAdmin(env))
    setProfSelec("");
    handleCloseCreate();
    reload();
  } 









  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "user",
      headerName: "Profe",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.user.avatar_url} alt="avatar" />
            {params.row.user.name} {params.row.user.lastname}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
  
    {
      field: "clasesdadas",
      headerName: "Clases dadas",
      width: 150,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];




  const [data, setData] = useState([]);

  useEffect(() => {
    if(allProfes && allProfes.length > 0){
      const userConId = []
      for (let i = 0; i < allProfes.length; i++) {
        const a = allProfes[i];
        a.id = i
        userConId.push(a)
        
      }
      setData(userConId)
    }
  }, [allProfes])
  


  const handleDeletes = (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  const buscarU = (uselec)=>{
    return allProfes.find((x)=> x.email === uselec)
  }
  const modificarUsuario = (e)=>{

      setUserSelect(buscarU(e));
      setShow(true);
      setInput(buscarU(e));
  }

  const eliminarUsuario = (e)=>{

    setUserSelect(buscarU(e));
    setShowDel(true);
    setInput(buscarU(e));
}

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <button className="viewButton"
                onClick={(x)=>modificarUsuario(params.row.email)}
              >
                MODIFICAR
              </button>
            <div
              className="deleteButton"
              onClick={(x) => eliminarUsuario(params.row.email)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];







  

  return (
    <div>
      <div className="list">
      <div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle">
        Agregar Profe
        <button className="btn btn-primary" onClick={(x) => {
                  setShowCreate(true);
                }}>Agregar</button>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
      </div>












    </div>






      {/* <button className="btn btn-primary" onClick={(x) => {
                  setShowCreate(true);
                }}>AGREGAR PROFESOR</button>
      {allProfes && allProfes.length > 0
        ? allProfes.map((e, i) => (
            <div key={i * 12}>
              <ul>
                <li>{e.email}</li>
                <li>{e.user.name}</li>
                <li>{e.user.lastname}</li>
                <li>clases dadas : {e.clasesdadas}</li>
              </ul>
              <button
                onClick={(x) => {
                  setUserSelect(e);
                  setShow(true);
                  setInput(e);
                }}
              >
                MODIFICAR
              </button>
              <button
                className="btn-danger"
                onClick={(x) => {
                  setUserSelect(e);
                  setShowDel(true);
                  setInput(e);
                }}
              >
                ELIMINAR
              </button>
            </div>
          ))
        : ""} */}
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar PROFESOR</Modal.Title>
          </Modal.Header>
          {userSelect.email ? (
            <Modal.Body>
              <div>
                <label>Email: {userSelect.email}</label>
              </div>
              <div>
                <label>Nombre: {userSelect.user.name}</label>
              </div>
              <div>
                <label>Apellido: {userSelect.user.lastname}</label>
              </div>

              <div>
                <label>Cant Clases dadas:</label>
                <input
                  type="number"
                  name="clasesdadas"
                  onChange={handleInputChange}
                  defaultValue={userSelect.clasesdadas}
                />
              </div>
            </Modal.Body>
          ) : (
            ""
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModif}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {userSelect.email ? (
          <Modal show={showDel} onHide={handleCloseDel}>
            <Modal.Header closeButton>
              <Modal.Title>ELIMINAR PROFESOR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <h1>Va a eliminar a</h1>
                <p>Email: {userSelect.email}</p>
                <p>Nombre: {userSelect.user.name}</p>
                <p>Apellido: {userSelect.user.lastname}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDel}>
                Close
              </Button>
              {true ? (
                <Button variant="danger" onClick={handleDelete}>
                  ELIMINAR
                </Button>
              ) : (
                ""
              )}
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        
        <Modal show={showCreate} onHide={handleCloseCreate}>
        <Modal.Header closeButton>
          <Modal.Title>AGREGAR PROFESOR</Modal.Title>
        </Modal.Header>
        {allUsers.length > 0 ? 
        <Modal.Body>
          <div>
            <select onChange={handleInputP}>
            {allUsers.map(e => <option key={e.email} value={e.email}>{e.email}</option>)}
            </select>
          </div>
          
        </Modal.Body>
        :""}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreate}>
            Close
          </Button>
          {profSelec.length > 1 ? (
            <Button variant="primary" onClick={handleCreateProfe}>
              Agregar a {profSelec}
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
        

      </div>
    </div>
  );
};
