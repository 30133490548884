import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser, deleteUserAdmin, getAllUsers, modificarDiaClaseAdmin, updateUserAdmin } from "../../../redux/actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./usercontrol.scss"
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";

export const UsersControl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const allUsers = useSelector((state) => state.AllUsers);
  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [userSelect, setUserSelect] = useState({});
  const [input, setInput] = useState({
    avatar_url: "",
    cantclases: 0,
    deslingue: false,
    dni: "",
    email: "",
    fechanac: "",
    instagram: "",
    lastname: "",
    name: "",
    roleId: 3,
    tel: "",
    emailAnt: ""
  });

  const reload = ()=>{
    setTimeout(function(){
     navigate(0)
 }, 2000);
}

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const handleInputChange = function (e) {
    if (e.target.name === "deslingue" || e.target.name === "residente") {
      setInput({
        ...input,
        [e.target.name]: e.target.checked,
        emailAnt: userSelect.email
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
        emailAnt: userSelect.email
        // [e.target.name]: e.target.checked,
      });
    }
    
  };
  
  const handleClose = () => setShow(false);
  const handleCloseDel = () => setShowDel(false);
  const handleCloseCreate = () => setShowCreate(false);
  const handleCloseFicha = () => setShowFicha(false);
  const handleShow = () => setShow(true);
  const handleSave = ()=>{

    
    dispatch(updateUserAdmin(input))
    dispatch(getAllUsers());
    setInput({})
    // setShow(false)
    handleClose()
    reload()

  }
  const handleDelete = ()=>{
    
    dispatch(deleteUserAdmin(input))
    dispatch(getAllUsers());
    setInput({})
    handleCloseDel()
    reload()
  }
  const handleCreateUser = ()=>{
    
    setInput(input)
    dispatch(createUser(input))
    dispatch(getAllUsers());
    setInput({})
    handleCloseCreate()
    reload()
  }




  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "User",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img className="cellImg" src={params.row.avatar_url} alt="avatar" />
            {params.row.name} {params.row.lastname}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
    },
  
    {
      field: "tel",
      headerName: "Tel",
      width: 120,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];


  const [showFicha, setShowFicha] = useState(false)


  
  const mostrarFichaf = ()=>{
    if(userSelect.ficha){
      
      const sabnada = userSelect.ficha.sabenadar ? "Sabe nadar: SI":"Sabe nadar: No"
      const alerg = userSelect.ficha.alergico ? "Alergico: SI":"Alergico: No"
      const enfermed = userSelect.ficha.enfermedad ? "Enfermedad: SI":"Enfermedad: No"
      const comentar = userSelect.ficha.comentario ? userSelect.ficha.comentario:"Sin comentario"
      const autoriz = userSelect.ficha.autoriza ? `Autoriza: ${userSelect.ficha.autoriza}`:""
      return (userSelect.ficha && userSelect.ficha.historial? `${sabnada} <br> ${alerg}<br> ${enfermed}<br> ${comentar}<br> ${autoriz} <br>${userSelect.ficha.historial.map(a => `<p className = "display-6">${a.fecha}:${a.ficha}//${a.profe}</p>`)}`:`${sabnada} <br> ${alerg}<br> ${enfermed}<br> ${comentar}<br> ${autoriz}`)

    }
  }
  const handleFicha = (e)=>{
    setUserSelect(buscarU(e));
    setShowFicha(true);
      setInput(buscarU(e));

 
  }





  const [data, setData] = useState([]);

  useEffect(() => {
    if(allUsers && allUsers.length > 0){
      const userConId = []
      for (let i = 0; i < allUsers.length; i++) {
        const a = allUsers[i];
        a.id = i
        userConId.push(a)
        
      }
      setData(userConId)
    }
  }, [allUsers])
  


  const handleDeletes = (id) => {
    setData(data.filter((item) => item.id !== id));
  };
  const buscarU = (uselec)=>{
    return allUsers.find((x)=> x.email === uselec)
  }
  const modificarUsuario = (e)=>{

      setUserSelect(buscarU(e));
      setShow(true);
      setInput(buscarU(e));
  }

  const eliminarUsuario = (e)=>{

    setUserSelect(buscarU(e));
    setShowDel(true);
    setInput(buscarU(e));
}

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
              <div
              className="btn btn-info"
              onClick={(x) => handleFicha(params.row.email)}
            >
              FICHA
            </div>
            <button className="viewButton"
                onClick={(x)=>modificarUsuario(params.row.email)}
              >
                MODIFICAR
              </button>
            <div
              className="deleteButton"
              onClick={(x) => eliminarUsuario(params.row.email)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  

  return (
    <div className="list">
      <div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle">
        Agregar nuevo usuario
        <button className="btn btn-primary" onClick={(x) => {
                  setShowCreate(true);
                }}>CREAR USUARIO</button>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
      </div>














      {/* <button className="btn btn-primary" onClick={(x) => {
                  setShowCreate(true);
                }}>CREAR USUARIO</button>
      {allUsers && allUsers.length > 0
        ? allUsers.map((e, i) => (
            <div key={i * 12}>
              <ul>
                <li>{e.email}</li>
                <li>{e.name}</li>
                <li>{e.lastname}</li>
                <li>{e.fechanac}</li>
                <li>{e.dni}</li>
                <li>{e.tel}</li>
                <li>{e.cantclases}</li>
                <li>{e.instagram}</li>
                <li>{e.deslingue ? "✅" : "❎"}</li>
                <li>
                  {e.roleId === 1
                    ? "admin"
                    : e.roleId === 2
                    ? "profe"
                    : "alumno"}
                </li>
              </ul>
              <button
                onClick={(x) => {
                  setUserSelect(e);
                  setShow(true);
                  setInput(e);
                }}
              >
                MODIFICAR
              </button>
              <button className="btn-danger"
                onClick={(x) => {
                  setUserSelect(e);
                  setShowDel(true);
                  setInput(e);
                }}
              >
                ELIMINAR
              </button>
            </div>
          ))
        : ""} */}
      <div>
        <Modal show={showFicha} onHide={handleCloseFicha}>
          <Modal.Header closeButton>
            <Modal.Title>FICHA {userSelect.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {!userSelect.ficha ? 
              <h1>SIN FICHA</h1>
              :
              <div>
                <p>{userSelect.ficha.sabenadar ? "Sabe nadar: SI":"Sabe nadar: No"}</p>
                <br />
                <p>{userSelect.ficha.alergico ? "Alergico: SI":"Alergico: No"}</p>
                <br />
                <p>{userSelect.ficha.enfermedad ? "Enfermedad: SI":"Enfermedad: No"}</p>
                <br />
                <p>{userSelect.ficha.comentario ? userSelect.ficha.comentario:"Sin comentario"}</p>
                <br />
                <p>{userSelect.ficha.historial ? userSelect.ficha.historial.map(a => a.fecha + " : " + a.ficha +" =>"+" Profe: "+ a.profe + "\n"):"sin historial"}</p>
                <br />

              </div>
            
            }

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFicha}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar USUARIO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Email:</label>
              <input
                type="text"
                name="email"
                onChange={handleInputChange}
                defaultValue={userSelect.email}
              />
            </div>
            <div>
              <label>Nombre:</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
                defaultValue={userSelect.name}
              />
            </div>
            <div>
              <label>Apellido:</label>
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
                defaultValue={userSelect.lastname}
              />
            </div>
            <div>
              <label>DNI:</label>
              <input
                type="number"
                name="dni"
                onChange={handleInputChange}
                defaultValue={userSelect.dni}
              />
            </div>
            <div>
              <label>Tel:</label>
              <input
                type="number"
                name="tel"
                onChange={handleInputChange}
                defaultValue={userSelect.tel}
              />
            </div>
            <div>
              <label>Fecha de nacimiento:</label>
              <input
                type="date"
                name="fechanac"
                onChange={handleInputChange}
                defaultValue={userSelect.fechanac}
              />
            </div>
            <div>
              <label>Residente:</label>
              <input
                type="checkbox"
                name="residente"
                onChange={handleInputChange}
                defaultChecked={userSelect.residente}
              />
            </div>
            <div>
              <label>Instagram:</label>
              <input
                type="text"
                name="instagram"
                onChange={handleInputChange}
                defaultValue={userSelect.instagram}
              />
            </div>
            <div>
              <label>avatar_url:</label>
              <input
                type="text"
                name="avatar_url"
                onChange={handleInputChange}
                defaultValue={userSelect.avatar_url}
              />
            </div>
            <div>
              <label>Deslingue:</label>
              <input
                type="checkbox"
                name="deslingue"
                onChange={handleInputChange}
                defaultChecked={userSelect.deslingue}
              />
            </div>

            <div>
              <label>Cant Clases:</label>
              <input
                type="number"
                name="cantclases"
                onChange={handleInputChange}
                defaultValue={userSelect.cantclases}
              />
            </div>
            <div>
              <label>ROL:</label>
              <input
                type="number"
                name="roleId"
                onChange={handleInputChange}
                defaultValue={userSelect.roleId}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {true ? (
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showDel} onHide={handleCloseDel}>
          <Modal.Header closeButton>
            <Modal.Title>ELIMINAR USUARIO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Va a eliminar a</h1>
              <p>Email: {userSelect.email}</p> 
              <p>Nombre: {userSelect.name}</p> 
              <p>Apellido: {userSelect.lastname}</p> 
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDel}>
              Close
            </Button>
            {true ? (
              <Button variant="danger" onClick={handleDelete}>
                ELIMINAR
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showCreate} onHide={handleCloseCreate}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar USUARIO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Email:</label>
              <input
                type="text"
                name="email"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Nombre:</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Apellido:</label>
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>DNI:</label>
              <input
                type="number"
                name="dni"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Tel:</label>
              <input
                type="number"
                name="tel"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Fecha de nacimiento:</label>
              <input
                type="text"
                name="fechanac"
                onChange={handleInputChange}
                placeholder="1988/07/29"
              />
            </div>
            <div>
              <label>Instagram:</label>
              <input
                type="text"
                name="instagram"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Residente:</label>
              <input
                type="checkbox"
                name="residente"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>avatar_url:</label>
              <input
                type="text"
                name="avatar_url"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Deslingue:</label>
              <input
                type="checkbox"
                name="deslingue"
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label>Cant Clases:</label>
              <input
                type="number"
                name="cantclases"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>ROL:</label>
              <input
                type="number"
                name="roleId"
                onChange={handleInputChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreate}>
              Close
            </Button>
            {true ? (
              <Button variant="primary" onClick={handleCreateUser}>
                Crear Usuario
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
