import React from 'react'
import { Footer } from '../footer/Footer'
import "./clasessurf.css"

export const ClasesSurf = () => {
  return (
    <div className="home__clasessurf">
    <header className="hero__clasessurf">
      <section className="hero__main__clasessurf container__clasessurf">
        <h1 className="hero__title__clasessurf">CLASES DE SURF<br></br>“todo el mundo puede surfear”</h1>
      </section>
    </header>
    <main>
      <section className="services__clasessurf">
        <div className="service_image--clasessurf"></div>
        <div className="services__texts__clasessurf">
          <h2 className="services__title__clasessurf"> Clases</h2>
          <p className="services__paragraph__clasessurf">
          Nuestras clases de Surf son personalizadas, por lo que nos adaptamos a todos los niveles y a todas las edades. No importa si es tu primera vez, si tenés experiencia, si se te dan bien los deportes o si simplemente querés experimentar el deslizarte en una Ola; lo importante es tener ganas de conectar con el Mar de una manera distinta y divertirte!
          </p>
        </div>
        <div className="service__image--iynm"></div>

        <div className="services__texts__clasessurf services__texts--2__clasessurf">
          <h2 className="services__title__clasessurf"> Iniciación y nivel medio</h2>
          <p className="services__paragraph__clasessurf">
          Viví tus primeras experiencias de la mano de los mejores profes!
          </p>
          <li>Clase personalizada: un profe- un alumno</li>
          <li>Acompañamiento permanente dentro y fuera del Mar</li>
          <li>Duración de la clase: 50´aprox</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          
        </div>
      </section>


      <section className="services__clasessurf1">
        <div className="service_image--perfeccionamiento"></div>
        <div className="services__texts__clasessurf services__texts--3__clasessurf">
          <h2 className="services__title__clasessurf"> Perfeccionamiento</h2>
          <p className="services__paragraph__clasessurf">
          Perfeccioná tu técnica, anímate a nuevos desafíos
          </p>
          <li>Clase personalizada</li>
          <li>Objetivos específicos</li>
          <li>Duración de la clase: 1 hora aprox.</li>
          <li>Traje de Neoprene corto</li>
          
        </div>
        <div className="service__image--surfenfamilia"></div>

        <div className="services__texts__clasessurf services__texts--4__clasessurf">
          <h2 className="services__title__clasessurf"> Surf en Familia</h2>
          <p className="services__paragraph__clasessurf">
          Compartí un momento distinto, disfrutá del mar y divertite en familia.
          </p>
          <li>Clase grupal, acompañamiento personalizado permanente</li>
          <li>Mínimo 4 integrantes x grupo familiar. Edad mínima 6 años.</li>
          <li>Duración de la clase: de 50´a 1hs 30´dependiendo de la cantidad de integrantes</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          
        </div>
      </section>


      <section className="services__clasessurf2">
        <div className="service__image--surfengrupo"></div>
        <div className="services__texts__clasessurf services__texts--5__clasessurf">
          <h2 className="services__title__clasessurf"> Surf en Grupo</h2>
          <p className="services__paragraph__clasessurf">
          Divertite surfeando con amigxs, compañerxs de colegio, o grupo de trabajo. Armamos propuestas especiales para escuelas, universidades, egresados, 15 años, despedidas, festejos, team building empresariales, asociaciones deportivas, gimnasios, etc.
          </p>
          <li>Clase grupal, acompañamiento personalizado permanente</li>
          <li>Mínimo 8 integrantes x grupo.</li>
          <li>Duración de la clase: dependiendo de la propuesta</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          
        </div>
      </section>
      
    </main>
    
<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
