import React from 'react'
import { Seleccionador } from './seleccionador'

export const Horarioslibres = (e) => {


    const dia = e.diadisp.diadisp
    const horarios = e.diadisp.horariosdisp
    const horariosdisp = e.diadisp.horariosusados


    if(horariosdisp[0] === "sin horarios" || horariosdisp.length === 0){
        return (
            <div>
                <div>{dia}</div>
                <div>Sin horarios</div>
            </div>
          )
    }else{

        return (
          <div>
              <div>{dia}</div>
              {horarios.map((a,i) => <div key={i*54651}>{a}hs:<Seleccionador key={i*5461} posicion = {i} horarios = {horarios} horariosdisp = {horariosdisp} dia = {dia}></Seleccionador></div>)}
          </div>
        )


    }

}
