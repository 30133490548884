import React from 'react'
import { Footer } from '../footer/Footer'
import "./surftrip.css"

export const Surftrip = () => {
  return (
    <div className="home__surftrip">
    <header className="hero__surftrip">
      <section className="hero__main__surftrip container__surftrip">
        <h1 className="hero__title__surftrip">SURF TRIPS</h1>
        <p className="services__paragraph parrafo__surftrip">
        “viajar siempre es una buena opción, pero hacer un viaje de Surf es la MEJOR de las opciones”
            </p>
      </section>
    </header>
    <main>
      <section className="services__surftrip">
        <div className="service_image--surftrip"></div>
        <div className="services__texts__surftrip">
          <p className="services__paragraph__clasessup">
          Amanecé en la playa, cargate de energía positiva, llénate de Mar, conocé gente, viví nuevas experiencias y divertite surfeando otras olas. 
Ofrecemos viajes para todos los gustos y niveles, tanto en Pinamar como en otras playas; dentro y fuera del país.
</p>
          </div>
        <div className="service__image--surftrip1"></div>

        <div className="services__texts__surftrip services__texts--2__surftrip">
          
          <p className="services__paragraph__clasessup">
          Organizamos Surf Trips personalizados para grupos cerrados con objetivos específicos: improve your surfing, cursos intensivos de iniciación y perfeccionamiento, despedidas, surf trips femeninos, 15 años, team building, etc</p>
          
        </div>
      </section>
    </main>
    


<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
