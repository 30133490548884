import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReservasTotales } from "../../redux/actions";

import { Calendario } from "../calendario/Calendario";
import { allreservasarray } from "../paneladmin/funciones/allreservasarray";

export const CalendarioAdmin = () => {
  const dispatch = useDispatch();

  // esto es para mandar al calendario///////////////////////////////
  const allReservasTotales = useSelector((state) => state.AllReservasTotales);
  var reservasA = [];

  useEffect(() => {
    dispatch(getAllReservasTotales());
  }, []);

  if (allReservasTotales.length > 0) {
    reservasA = allreservasarray(allReservasTotales);
  }
  /////////////////////////////////////////////

  return (
    <div>
      {reservasA && reservasA.length > 0 ? (
        <Calendario reservasA={reservasA}></Calendario>
      ) : (
        ""
      )}
    </div>
  );
};
