
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react';
import { getMarea, getWindguru } from '../../redux/actions';
import CardsClima from './cardsclima/CardsClima';
import './InfoClima.css';
import CardsWindguru from './cardswindguru/CardsWindguru';
import CardsMarea from './cardsMarea/CardsMarea';
import { Loading } from '../loading/Loading';

const InfoClima = () => {


  const diasele = useSelector(state => state.DiaSeleccionado)
  


  const windguru = useSelector(state => state.Windguru.datos)
  
  const clima = useSelector(state => state.Clima)
  const marea = useSelector(state => state.Marea.marea)
  const [mas, setMas] = useState(1)
  const [masomenos, setMasomenos] = useState("Mas...")
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWindguru())
    dispatch(getMarea())
    
  }, [])

 
  function showTime(){
    var myDate = new Date();
    var hours = myDate.getHours();
    var minutes = myDate.getMinutes();
    var seconds = myDate.getSeconds();
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;
    
    
    return hours + ":" + minutes
    }
  const more = document.querySelector(
    '.section.today .section__more'
  )
  const items = document.querySelectorAll(
    '.section.today .item.collapsabl'
  )

  const moreF = () => {
    items.forEach((item) => {
          item.classList.toggle('collaps')
        })
}

  const masF = ()=>{
    if(mas === 1){
      setMas(2)
      setMasomenos("Menos...")
    }else{
      setMas(1)
      setMasomenos("Mas...")
    }
  }
  

  return (
    <div className='asterC'>
      <div className='bodyC type--morning'>
        <div className='mainC'>
          <section className='header'>
            <div className='header__time'>{showTime()}</div>
            <div className='header__location'>
              Pinamar, BUENOS AIRES
            </div>
          </section>
          <section className='now'>
            <div className='now__bg'></div>
            <div className='now-data'>
              {clima && clima.weather ? <img className="now-data__icon" src={`${process.env.REACT_APP_PAGINA}/images/clima/${clima.weather[0].icon}.png`} alt="" />:""}
              <div className='now-data__cond'>{clima && clima.weather ? clima.weather[0].description:""}</div>
              <div className='now-data__temp'>{clima && clima.weather ? clima.main.temp+"°":""}</div>
              <div className='now-data__feel'>
                <p>{clima && clima.weather ? "Min: "+clima.main.temp_min+"°":""}</p>
                <p>{clima && clima.weather ? "Max: "+clima.main.temp_max+"°":""}</p>
              </div>
            </div>
          </section>
          <section className='section today'>
            <div className='section__header'>
              <h1>PRONOSTICO</h1>
              {/* <p className='section__more' onClick={moreF}>More</p> */}
            </div>


              <div className='section__grilla'>

            <div className='item__datos'>

                <div className='win_datos'></div>
                <div className='win_datos'></div>
                <div className='win_datos'>Viento</div>
                <div className='win_datos'>Rafagas</div>
                <div className='win_datos'>Direc V</div>
                <div className='win_datos'>Temp</div>
                <div className='win_datos'>Nubes</div>
                <div className='win_datos'>Precip</div>
                <div className='win_datos'>OLAS</div>
                <div className='win_datos'>Periodo</div>
                <div className='win_datos'>Direc O</div>
              </div>
                <div className='section__items'>
                  {/* {windguru && windguru.length>1? <CardsClima windguru={windguru}></CardsClima>:"" } */}
                  {windguru && windguru.length>1? <CardsWindguru datosWindguru={windguru}></CardsWindguru>:<Loading></Loading> }
                  

                </div>
              </div>

            
          </section >

          <section className='section forecast'>
            <div className='section__header'>
              <h1>MAREA</h1>
              
            </div>
            
                {!marea ?
                <Loading></Loading>
                :
            <div className='bodyMarea'>
              <div className="containerMarea">

                <table className="tableMarea">
                {/* <caption>fechas</caption> */}
                <thead>
                    <tr>
                        <th>Fechas</th>
                        <th>Marea 1</th>
                        <th>Marea 2</th>
                        <th>Marea 3</th>
                        <th>Marea 4</th>
                    </tr>
                </thead>
                <tbody>
                    {marea && marea.length>1 && mas === 2? <CardsMarea datosMarea={marea}></CardsMarea>:"" }
                    {marea && marea.length>1 && mas === 1? <CardsMarea datosMarea={marea.slice(0,3)}></CardsMarea>:"" }
                </tbody>
            </table>
                  
                  <p className='section__more' onClick={masF}>{masomenos}</p>
              </div>
          </div>
              }
            
            
            

          </section>

        </div>


      </div>
      <div>






      <div>
        {/* {windguru && windguru.length>1? <CardsClima windguru={windguru}></CardsClima>:"" } */}
        
      </div>
        
      </div>
    </div>
  )
}

export default InfoClima