import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import {formatDistance} from "date-fns";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { createFicha, createReview, getAllProfesores, getClasesProfeAlumno, getUserDetails } from "../../../redux/actions";
import { allreservasarray } from "../../paneladmin/funciones/allreservasarray";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const CalendarioUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

    const { isAuthenticated, user } = useAuth0();
  const userDetails = useSelector((state) => state.UserLog);
  const clasesAlumno = useSelector((state) => state.ClasesProfAlumn);
  const allProfes = useSelector((state) => state.AllProfes);
  const alumnoDetail = useSelector((state) => state.UserDetail);
  const [input, setInput] = useState({
    alumno: "",
    realizado: false,
    pago: 0,
    id: 0,
    profe:{}
  });
  var reservasA = [];

  useEffect(() => {
    dispatch(getAllProfesores())
  }, [])
  

  useEffect(() => {
    if (userDetails.email) {
      dispatch(getClasesProfeAlumno({ alumno: userDetails.email }));
      
    }
  }, [userDetails]);
  

  if (clasesAlumno.length > 0) {
    reservasA = allreservasarray(clasesAlumno);
  }
  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  var events = reservasA;
  const [profeClase, setProfeClase] = useState({})
  useEffect(() => {
    if(input.profesoreEmail){
      filtrarprofeClase(input.profesoreEmail)
    }else{
      setProfeClase({})
    }
  
  }, [input])
  
  
  const filtrarprofeClase = (b)=>{
    const profefilt = allProfes.find(a => a.email == b)
    setProfeClase(profefilt);
  }
  
  //////////////////cuando hace click en la clase////////////////
  const onSelectEvent = useCallback((calEvent) => {
    if(calEvent.desc.alumno){
        dispatch(getUserDetails(calEvent.desc.alumno))
      }
      setInput({
        ...input,
        diahorario: format(calEvent.start,"dd/MM/yyyy,HH:mm"),
        id: calEvent.desc.id,
        pago: calEvent.desc.pago,
        reservaId : calEvent.desc.reservaId,
        profesoreEmail : calEvent.desc.profesoreEmail,
        alumno: calEvent.desc.alumno,
        realizado: calEvent.desc.realizado,
        review: calEvent.desc.review
  
      });
      handleShow();
    }, []);


    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
      };
      const handleShow = () => setShow(true);





      const handleSaveChange = ()=>{
        const env = {ficha : {}}
        if(input.review && input.review.id){
          env.review = {
            idClase: input.id,
            comment: input.comment,
            id: input.review.id,
            profesoreEmail: input.profesoreEmail,
            rating: input.rating ,
            tipProfe: input.review.tipProfe,
            userEmail: input.alumno
          }
        }else{
          env.review = {
            idClase: input.id,
            comment: input.comment,
            id: null,
            profesoreEmail: input.profesoreEmail,
            rating: input.rating,
            tipProfe: null,
            userEmail: input.alumno
          }
      
        }
        dispatch(createReview(env.review))
        reload()
      
      }

      const reload = ()=>{
        setTimeout(function(){
          navigate(0)
     }, 2000);}

      const handleInputChange = (e)=>{
        setInput({
          ...input,
          [e.target.name]: e.target.value,
          // [e.target.name]: e.target.checked,
        });
      }


  return (
    <div>
        <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
        onSelectEvent={onSelectEvent}
        popup
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{alumnoDetail.name} {alumnoDetail.lastname} -- {input.diahorario}</Modal.Title>
        </Modal.Header>
            {profeClase.user && profeClase.user.name ? 
          <Modal.Body>
            <div>
              <h3>Profe de la clase:</h3>
              <img src={profeClase.user.avatar_url} alt="" width="100px" height="100px"/>
              <h2>{profeClase.user.name}</h2>
            </div>
          {input.review && input.review.tipProfe ? <p>Tip de la clase: {input.review.tipProfe}</p>:
          <p>El profe no dejo ningun tip todavia</p>}
          {input.review && input.review.comment ? <p>Review: {input.review.comment}</p>:
         input.realizado?
        <div>
          <label>Puntua la clase:</label>
          <input type="range" name="rating" onChange={handleInputChange} max="10"></input>
          <label>Que te parecio la clase:</label>
          <textarea name="comment" placeholder="REVIEW" onChange={handleInputChange}></textarea>
          <br />

        </div>
        :""}
        
        </Modal.Body>
        :
        <Modal.Body>
          <h2>SIN PROFESOR ASIGNADO</h2>        
        </Modal.Body>
          }
          
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {input.alumno && input.profesoreEmail && input.comment && input.rating?
          <Button variant="primary" onClick={handleSaveChange}>
            Save Changes
          </Button>
          :""}
        </Modal.Footer>
      </Modal>
    </div>
  )
}
