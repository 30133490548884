import React from 'react'
import "./loading.css";

export const Loading = () => {
  return (
    <div className='bodyloading'>
      <div class="water"></div>
    </div>
  )
}
