import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getReservaById, updateReservaAlumnos } from "../../../redux/actions";
import { Deslinde } from "../../deslinde/Deslinde";
import { calcularEdad } from "../../funciones/siesmayor";
import "./modificarreserva.css"

export const ModificarReserva = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams();
  const reserva = useSelector((state) => state.ReservaById);
  const userDetails = useSelector((state) => state.UserLog);
  const [alumnos, setAlumnos] = useState([]);
  const [sinEmail, setSinEmail] = useState(false)
  const [mayordeedad, setMayordeedad] = useState(true)
  const [soyYo, setSoyYo] = useState(1);
  const [veces, setVeces] = useState(0);
  const [input, setInput] = useState({
    userEmail: "",
    name: "",
    lastname: "",
    dni: "",
    deslingue: false,
    fechanac: new Date()
  });
  const [ficha, setFicha] = useState({
    sabenadar: userDetails.sabenadar|| false,
    alergico: userDetails.alergico||false,
    enfermedad: userDetails.enfermedad||false,
    autoriza: userDetails.autoriza||"",
    comentario: userDetails.comentario||"",
    userEmail: userDetails
  });
  const [errors, setErrors] = useState({
    userEmail: "Completar email",
    name: "Completar nombre",
    lastname: "Completar apellido",
    dni: "Completar DNI",
    deslingue: "Completar deslingue",
    fechanac: "Completar fecha de nacimiento",
    tel: "Completar telefono",

  });
  useEffect(() => {
    if (reserva.user) {
      setInput({
        userEmail: reserva.user.email,
        name: reserva.user.name,
        lastname: reserva.user.lastname,
        dni: reserva.user.dni,
        deslingue: reserva.user.deslingue,
        fechanac: reserva.user.fechanac,
        sabenadar: userDetails.sabenadar|| false,
    alergico: userDetails.alergico||false,
    enfermedad: userDetails.enfermedad||false,
    autoriza: userDetails.autoriza||"",
    comentario: userDetails.comentario||"",
      });
    }
  }, [reserva]);

  const handleInputChange = function (e) {
    if (e.target.name === "deslingue" || e.target.name === "sabenadar" || e.target.name === "alergico" || e.target.name === "enfermedad") {
      setInput({
        ...input,
        [e.target.name]: e.target.checked,
      });
      setErrors(
        validate({
          ...input,
          [e.target.name]: e.target.checked,
        })
      );
    } else {
      
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
      setErrors(
        validate({
          ...input,
          [e.target.name]: e.target.value,
        })
      );
    }
  };
  
  
  const validate = function (input) {
    if(sinEmail){
      setInput({
        ...input,
        userEmail: input.dni + "@sudestadapinamar.com.ar",
      });
    }
    let errors = {};
    if (!input.userEmail) {
      errors.userEmail = "Es necesario un Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input.userEmail)
    ) {
      errors.userEmail = "Invalid email address";
    }
    if (!input.name) {
      errors.name = "Es necesario un nombre";
    } else if (!/^[A-Z]{2,24}$/i.test(input.name)) {
      errors.name = "Invalid name";
    }
    if (!input.lastname) {
      errors.lastname = "Es necesario un nombre";
    } else if (!/^[A-Z]{2,24}$/i.test(input.lastname)) {
      errors.lastname = "Invalid lastname";
    }
    if (!input.dni) {
      errors.dni = "Es necesario un dni";
    } else if (!/^[0-9]{7,8}$/i.test(input.dni)) {
      errors.dni = "Invalid DNI";
    }
    if (!input.deslingue) {
      errors.deslingue = "Es necesario aceptar deslingue";
    }
    if (!input.fechanac) {
      errors.fechanac = "Es necesario fecha de nacimiento";
    }
    if (!input.tel) {
      errors.tel = "Es necesario un telefono";
    }

    return errors;
  };

  useEffect(() => {
    dispatch(getReservaById(id));
  }, []);

  const inputSoyYo = (e) => {
    setSoyYo(parseInt(e.target.value));
    if (parseInt(e.target.value) === 1) {
      setInput({
        userEmail: reserva.user.email,
        name: reserva.user.name,
        lastname: reserva.user.lastname,
        dni: reserva.user.dni,
        deslingue: reserva.user.deslingue,
        fechanac: reserva.user.fechanac,
        roleId: 3,
        sabenadar: userDetails.sabenadar|| false,
    alergico: userDetails.alergico||false,
    enfermedad: userDetails.enfermedad||false,
    autoriza: userDetails.autoriza||"",
    comentario: userDetails.comentario||"",
      });
    } else {
      setInput({
        userEmail: "",
        name: "",
        lastname: "",
        dni: "",
        deslingue: false,
        roleId: 3,
        sabenadar:false,
        fechanac: new Date(),
        tel:"",

    alergico: false,
    enfermedad: false,
    autoriza: "",
    comentario: "",
      });
    }
  };

  const inputSiguiente = () => {
    if (soyYo === 1) {
      setInput({
        userEmail: reserva.user.email,
        name: reserva.user.name,
        lastname: reserva.user.lastname,
        dni: reserva.user.dni,
        deslingue: reserva.user.deslingue,
        roleId: 3,
        sabenadar: userDetails.sabenadar|| false,
    alergico: userDetails.alergico||false,
    enfermedad: userDetails.enfermedad||false,
    autoriza: userDetails.autoriza||"",
    comentario: userDetails.comentario||"",
      });
      let alumno = {
        userEmail: reserva.user.email,
        name: reserva.user.name,
        lastname: reserva.user.lastname,
        dni: reserva.user.dni,
        deslingue: reserva.user.deslingue,
        roleId: 3,
        sabenadar: userDetails.sabenadar|| false,
    alergico: userDetails.alergico||false,
    enfermedad: userDetails.enfermedad||false,
    autoriza: userDetails.autoriza||"",
    comentario: userDetails.comentario||"",
      };
      // alumnos.push(alumno);
    }
    
    alumnos.push(input)
    setSinEmail(false)
    setInput({
      userEmail: "",
      name: "",
      lastname: "",
      dni: "",
      deslingue: false,
      roleId: 3,
      fechanac: new Date(),
        tel:"",
      sabenadar:false,
    alergico: false,
    enfermedad: false,
    autoriza: "",
    comentario: "",
    });
    comprobarErrors()
    setVeces(veces + 1);
  };

  const comprobarErrors = function () {
    if (
      !errors.userEmail &&
      !errors.name &&
      !errors.lastname &&
      !errors.deslingue &&
      !errors.dni &&
      !errors.fechanac &&
      !errors.tel
    ) {
      return true;
    } else {
      return false;
    }
  };



  const inputGuardar = ()=>{
    alumnos.push(input)
    const obj = {
        alumnos: alumnos,
        id: id
    }
    dispatch(updateReservaAlumnos(obj))
    reload()
  }

  const reload = ()=>{
    setTimeout(function(){
      navigate("/UserPanel/calendariouser")
 }, 2000);}


  useEffect(() => {
    if(input.fechanac){
      
      setMayordeedad(calcularEdad(input.fechanac))
    }
  }, [input])
  
  const handleSinEmail = (e)=>{
    setSinEmail(e.target.checked)
  }
  
  

  if (veces === 0) {
    return (
      <div className="body__modifreserva__usuario">





    <section class="container__modifreserva__usuario">
      <header>Agrega alumnos de la reserva</header>
      <div action="#" class="form__modifreserva__usuario">
      <div class="gender-box__modifreserva__usuario">
              <div class="gender-option__modifreserva__usuario">
                <div class="gender__modifreserva__usuario">
                <input
                      id="radio1"
                      name="soy"
                      type="radio"
                      value="1"
                      onChange={inputSoyYo}
                      defaultChecked
                    />
                        <label for="radio1">Soy yo el alumno!</label>
                </div>
                <div class="gender__modifreserva__usuario">
                <input
                      id="radio2"
                      name="soy"
                      type="radio"
                      value="2"
                      onChange={inputSoyYo}
                    />
                        <label for="radio2">No soy el alumno!</label>
                </div>
              </div>
        </div>




        {soyYo === 1 && reserva.id ?  
        
        <div>
          <div class="input-box__modifreserva__usuario">
            <label>Nombre:</label>
            <input type="text" placeholder={reserva.user.name} disabled />
          </div> 
          <div class="input-box__modifreserva__usuario">
            <label>Apellido:</label>
            <input type="text" placeholder={reserva.user.lastname} disabled />
          </div> 
          <div class="input-box__modifreserva__usuario">
            <label>DNI:</label>
            <input type="text" placeholder={reserva.user.dni} disabled />
          </div> 
          <div class="input-box__modifreserva__usuario">
            <label>Email:</label>
            <input type="text" placeholder={reserva.user.email} disabled />
          </div>
          <div class="column__modifreserva__usuario">
            <div class="input-box__modifreserva__usuario">
              <label>Telefono:</label>
              <input type="number" placeholder={reserva.user.tel} disabled />
            </div>
            <div class="input-box__modifreserva__usuario">
              <label>Fecha de Nacimiento:</label>
              <input type="date" defaultValue={reserva.user.fechanac} disabled />
            </div>
          </div>
          <div class="gender-box__modifreserva__usuario">
              <div class="gender-option__modifreserva__usuario">
                <div class="gender__modifreserva__usuario">
                <input
                    type="checkbox"
                    name="deslingue"
                    id="deslingue"
                    defaultChecked={reserva.user.deslingue}
                    onChange={handleInputChange}
                  />
                        <label for="deslingue">Deslinde</label>
                        <Deslinde></Deslinde>
                </div>
              </div>
          </div>
          <div class="gender-box__modifreserva__usuario">
              <div class="gender-option__modifreserva__usuario">
                <div class="gender__modifreserva__usuario">
                <input
                    type="checkbox"
                    name="sabenadar"
                    id="sabenadar"
                    defaultChecked={ficha.sabenadar}
                    onChange={handleInputChange}
                  />
                        <label for="sabenadar">Sabe nadar?</label>
                </div>
                <div class="gender__modifreserva__usuario">
                <input
                    type="checkbox"
                    name="alergico"
                    id="alergico"
                    defaultChecked={ficha.alergico}
                    onChange={handleInputChange}
                  />
                        <label for="alergico">Es alergico?</label>
                </div>
                <div class="gender__modifreserva__usuario">
                <input
                    type="checkbox"
                    id="enfermedad"
                    name="enfermedad"
                    defaultChecked={ficha.enfermedad}
                    onChange={handleInputChange}
                  />
                        <label for="enfermedad">Tiene alguna enfermedad / limitante que pueda inferir?</label>
                </div>
              </div>
          </div>
          
          {!mayordeedad? 
          <div class="input-box__modifreserva__usuario">
                          <label>Es autorizado por (nombre y apellido):</label>
                          <input
                  type="text"
                  name="autoriza"
                  defaultValue={ficha.autoriza}
                  placeholder="PADRE/TUTOR"
                  onChange={handleInputChange}
                />
          </div>
          :""}
          <div class="input-box__modifreserva__usuario">
                          <label> Algun comentario:</label>
                          <input
                    type="text"
                    name="comentario"
                    defaultValue={ficha.comentario}
                    onChange={handleInputChange}
                  />
          </div>

          {reserva.cantpersonasreserva &&
          reserva.cantpersonasreserva - 1 === veces ? (
            <button onClick={inputGuardar}>GUARDAR</button>
          ) : input.userEmail.length > 1 ? (
            <button onClick={inputSiguiente}>SIGUIENTE</button>
          ) : (
            ""
          )}
          
        </div>
        

        
        :"" }

































{/* --------------------------------------------------------------------------------------------------------------------- */}






{soyYo !== 1 ?
        <div>

<div class="gender-box__modifreserva__usuario">
            <div class="gender-option__modifreserva__usuario">
              <div class="gender__modifreserva__usuario">
              <input type="checkbox" onChange={handleSinEmail} id="sinemail"/>
                      <label for="sinemail">Sin email</label>
              </div>
            </div>
        </div>
        {!sinEmail?
        <>
        <div class="input-box__modifreserva__usuario">
          <label>Email:</label>
          <input
                  type="text"
                  name="userEmail"
                  onChange={handleInputChange}
                />
        </div>

{errors.userEmail ? <p className="text-danger">{errors.userEmail}</p> : ""} 
</>
: ""}

                <>
        <div class="input-box__modifreserva__usuario">
          <label>Nombre:</label>
          <input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                />
        </div>

{errors.name ? <p className="text-danger">{errors.name}</p> : ""} 
</>
                <>
        <div class="input-box__modifreserva__usuario">
          <label>Apellido:</label>
          <input
                  type="text"
                  name="lastname"
                  onChange={handleInputChange}
                />
        </div>

{errors.lastname ? <p className="text-danger">{errors.lastname}</p> : ""} 
</>
                <>
        <div class="input-box__modifreserva__usuario">
          <label>DNI:</label>
          <input
                  type="text"
                  name="dni"
                  onChange={handleInputChange}
                />
        </div>

{errors.dni ? <p className="text-danger">{errors.dni}</p> : ""} 
</>




        <div class="column__modifreserva__usuario">
          <div class="input-box__modifreserva__usuario">
            <label>Telefono:</label>
            <input
                  type="number"
                  name="tel"
                  onChange={handleInputChange}
                />
                {errors.tel ? <p className="text-danger">{errors.tel}</p> : ""}
          </div>
          <div class="input-box__modifreserva__usuario">
            <label>Fecha de Nacimiento:</label>
            <input
                  type="date"
                  name="fechanac"
                  onChange={handleInputChange}
                />
                {errors.fechanac ? <p className="text-danger">{errors.fechanac}</p> : ""}
          </div>
        </div>

        <div class="gender-box__modifreserva__usuario">
            <div class="gender-option__modifreserva__usuario">
              <div class="gender__modifreserva__usuario">
              <input
                  type="checkbox"
                  name="deslingue"
                  id="deslingue"
                  defaultChecked={reserva.user.deslingue}
                  onChange={handleInputChange}
                />
                      <label for="deslingue">Deslinde</label>
                      <Deslinde></Deslinde>
              </div>
            </div>
        </div>
        <div class="gender-box__modifreserva__usuario">
            <div class="gender-option__modifreserva__usuario">
              <div class="gender__modifreserva__usuario">
              <input
                  type="checkbox"
                  name="sabenadar"
                  id="sabenadar"
                  defaultChecked={ficha.sabenadar}
                  onChange={handleInputChange}
                />
                      <label for="sabenadar">Sabe nadar?</label>
              </div>
              <div class="gender__modifreserva__usuario">
              <input
                  type="checkbox"
                  name="alergico"
                  id="alergico"
                  defaultChecked={ficha.alergico}
                  onChange={handleInputChange}
                />
                      <label for="alergico">Es alergico?</label>
              </div>
              <div class="gender__modifreserva__usuario">
              <input
                  type="checkbox"
                  id="enfermedad"
                  name="enfermedad"
                  defaultChecked={ficha.enfermedad}
                  onChange={handleInputChange}
                />
                      <label for="enfermedad">Tiene alguna enfermedad / limitante que pueda inferir?</label>
              </div>
            </div>
        </div>
        
        {!mayordeedad? 
        <div class="input-box__modifreserva__usuario">
                        <label>Es autorizado por (nombre y apellido):</label>
                        <input
                type="text"
                name="autoriza"
                placeholder="PADRE/TUTOR"
                onChange={handleInputChange}
              />
        </div>
        :""}
        <div class="input-box__modifreserva__usuario">
                        <label> Algun comentario:</label>
                        <input
                  type="text"
                  name="comentario"
                  onChange={handleInputChange}
                />
        </div>



{reserva.cantpersonasreserva &&
              reserva.cantpersonasreserva - 1 === veces &&
              comprobarErrors() ? 
                <button>GUARDAR</button>
               : input.userEmail.length > 1 && comprobarErrors() ? 
                <button onClick={inputSiguiente}>SIGUIENTE</button>
               : 
                ""
              }

            
           : 
            {reserva.cantpersonasreserva &&
                reserva.cantpersonasreserva - 1 === veces ? 
                  <button onClick={inputGuardar}>GUARDAR</button>
                 : input.userEmail.length > 1 ? 
                  <button onClick={inputSiguiente}>SIGUIENTE</button>
                 : 
                  ""
                
          }
        
      </div>



:""}
        
        {/* <div class="input-box__modifreserva__usuario address__modifreserva__usuario">
          <label>Address</label>
          <input type="text" placeholder="Enter street address" required />
          <input type="text" placeholder="Enter street address line 2" required />
          <div class="column__modifreserva__usuario">
            <div class="select-box__modifreserva__usuario">
              <select>
                <option hidden>Country</option>
                <option>America</option>
                <option>Japan</option>
                <option>India</option>
                <option>Nepal</option>
              </select>
            </div>
            <input type="text" placeholder="Enter your city" required />
          </div>
          <div class="column__modifreserva__usuario">
            <input type="text" placeholder="Enter your region" required />
            <input type="number" placeholder="Enter postal code" required />
          </div>
        </div> */}
        
      </div>
    </section>

                  
      </div>
    );
  } else {
    return (

<div className="body__modifreserva__usuario">





<section class="container__modifreserva__usuario">
  <header>Agrega alumnos de la reserva</header>
  <div action="#" class="form__modifreserva__usuario">


    <div>

<div class="gender-box__modifreserva__usuario">
        <div class="gender-option__modifreserva__usuario">
          <div class="gender__modifreserva__usuario">
          <input type="checkbox" onChange={handleSinEmail} defaultChecked={sinEmail} checked={sinEmail} id="sinemail"/>
                  <label for="sinemail">Sin email</label>
          </div>
        </div>
    </div>
    {!sinEmail?
    <>
    <div class="input-box__modifreserva__usuario">
      <label>Email:</label>
      <input
              type="text"
              name="userEmail"
              value={input.userEmail}
              onChange={handleInputChange}
            />
    </div>

{errors.userEmail ? <p className="text-danger">{errors.userEmail}</p> : ""} 
</>
: ""}

            <>
    <div class="input-box__modifreserva__usuario">
      <label>Nombre:</label>
      <input
              type="text"
              name="name"
              value={input.name}
              onChange={handleInputChange}
            />
    </div>

{errors.name ? <p className="text-danger">{errors.name}</p> : ""} 
</>
            <>
    <div class="input-box__modifreserva__usuario">
      <label>Apellido:</label>
      <input
              type="text"
              name="lastname"
              value={input.lastname}
              onChange={handleInputChange}
            />
    </div>

{errors.lastname ? <p className="text-danger">{errors.lastname}</p> : ""} 
</>
            <>
    <div class="input-box__modifreserva__usuario">
      <label>DNI:</label>
      <input
              type="text"
              name="dni"
              value={input.dni}
              onChange={handleInputChange}
            />
    </div>

{errors.dni ? <p className="text-danger">{errors.dni}</p> : ""} 
</>




    <div class="column__modifreserva__usuario">
      <div class="input-box__modifreserva__usuario">
        <label>Telefono:</label>
        <input
              type="number"
              name="tel"
              value={input.tel}
              onChange={handleInputChange}
            />
            {errors.tel ? <p className="text-danger">{errors.tel}</p> : ""}
      </div>
      <div class="input-box__modifreserva__usuario">
        <label>Fecha de Nacimiento:</label>
        <input
              type="date"
              name="fechanac"
              value={input.fechanac}
              onChange={handleInputChange}
            />
            {errors.fechanac ? <p className="text-danger">{errors.fechanac}</p> : ""}
      </div>
    </div>

    <div class="gender-box__modifreserva__usuario">
        <div class="gender-option__modifreserva__usuario">
          <div class="gender__modifreserva__usuario">
          <input
              type="checkbox"
              name="deslingue"
              id="deslingue"
              defaultChecked={input.deslingue}
              checked={input.deslingue}
              onChange={handleInputChange}
            />
                  <label for="deslingue">Deslinde</label>
                  <Deslinde></Deslinde>
          </div>
        </div>
    </div>
    <div class="gender-box__modifreserva__usuario">
        <div class="gender-option__modifreserva__usuario">
          <div class="gender__modifreserva__usuario">
          <input
              type="checkbox"
              name="sabenadar"
              id="sabenadar"
              defaultChecked={ficha.sabenadar}
              onChange={handleInputChange}
            />
                  <label for="sabenadar">Sabe nadar?</label>
          </div>
          <div class="gender__modifreserva__usuario">
          <input
              type="checkbox"
              name="alergico"
              id="alergico"
              defaultChecked={ficha.alergico}
              onChange={handleInputChange}
            />
                  <label for="alergico">Es alergico?</label>
          </div>
          <div class="gender__modifreserva__usuario">
          <input
              type="checkbox"
              id="enfermedad"
              name="enfermedad"
              defaultChecked={ficha.enfermedad}
              onChange={handleInputChange}
            />
                  <label for="enfermedad">Tiene alguna enfermedad / limitante que pueda inferir?</label>
          </div>
        </div>
    </div>
    
    {!mayordeedad? 
    <div class="input-box__modifreserva__usuario">
                    <label>Es autorizado por (nombre y apellido):</label>
                    <input
            type="text"
            name="autoriza"
            defaultValue={ficha.autoriza}
            placeholder="PADRE/TUTOR"
            onChange={handleInputChange}
          />
    </div>
    :""}
    <div class="input-box__modifreserva__usuario">
                    <label> Algun comentario:</label>
                    <input
              type="text"
              name="comentario"
              defaultValue={ficha.comentario}
              onChange={handleInputChange}
            />
    </div>



    
  </div>

  {reserva.cantpersonasreserva &&
              reserva.cantpersonasreserva - 1 === veces &&
              comprobarErrors() ? (
                <button onClick={inputGuardar}>GUARDAR</button>
              ) : input.userEmail.length > 1 && comprobarErrors() ? (
                <button onClick={inputSiguiente}>SIGUIENTE</button>
              ) : (
                ""
              )}


    
  </div>
  
</section>





        </div>
      
    );
  }
};
