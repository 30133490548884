import React, { useEffect, useState } from "react";

import DatePicker, {
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { agregarDisponibilidad, getAllProfesores, getDeports, putAllDisponibilidad } from "../../../redux/actions";
import { paraEnvGenDisp } from "../generardisponibilidad/funciones/paraenviargendisp";
import { useNavigate } from "react-router-dom";

export const Agregardisponibilidad = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    

    const profesores = useSelector((state) => state.AllProfes);
    const deportes = useSelector((state) => state.Deports);
  const [dates, setDates] = useState([]);
  const [allDates, setAllDates] = useState([]);
  const [diasF, setDiasF] = useState([])
  const [rangeDias, setRangeDias] = useState([])
  const [horarios, setHorarios] = useState([])
  const [ho, setHo] = useState()
  const [cantProf, setCantProf] = useState(0)
  const [seleccionados, setSeleccionados] = useState({deport:"surf"})
  
    useEffect(() => {
      dispatch(getAllProfesores())
      dispatch(getDeports())
      
    }, [])
    


  const formatearDias = () => {
    const diai = [];
    for (let i = 0; i < allDates.length; i++) {
      const date = new Date(allDates[i]);
      const diadisp = format(date, "yyyy/MM/dd")
      const obj = {
        diadisp
      }
      diai.push(obj);
    }
    const rangeDia = []
    for (let i = 0; i < dates.length; i++) {
        const date = new Date(dates[i]-1);
        const diadisp = format(date, "yyyy/MM/dd")

        rangeDia.push(diadisp);
      }
      setRangeDias(rangeDia)
      setSeleccionados({
        ...seleccionados,
        diaini :rangeDia[0],
        diafin : rangeDia[1],
        dias: diai
    })
    setDiasF(diai);
  };

  const horarioS = function(e){
    setHo(e.target.value)
  }
  const agregarHorario = function(){
    let hh = horarios
    hh.push(ho)
    setHorarios(hh)
    setSeleccionados({
        ...seleccionados,
        horarios : hh
    })
  }

  const agregarCantProf = function(){
    setSeleccionados({
        ...seleccionados,
        cantprofdisp : cantProf 
    })
  }
  const handleInputDeport = function(e){
    setSeleccionados({
        ...seleccionados,
        deport : e.target.value 
    })
  }

  const handleBotonCrear = ()=>{
    
    const paraEnv = paraEnvGenDisp(seleccionados)

    dispatch(agregarDisponibilidad(paraEnv[0]))
    navigate('/AdminPanel/calendario')
  }


  return (
    <div>
        <div>
            <p>seleccionar fechas</p>
      <DatePicker
        // range
        // calendarPosition="top-left"
        // fixMainPosition
        value={dates}
        minDate={new Date()}
        onChange={(dateObjects) => {
          setDates(dateObjects);
          setAllDates(getAllDatesInRange(dateObjects));
        }}
        plugins={[<DatePanel eachDaysInRange />]}
      />
      <button onClick={formatearDias}>agregar</button>
            
        </div>
        <div>
            <input type="number" onChange={horarioS}/>
            <label >elegir horario</label>
            <button onClick={agregarHorario}>agregar</button>
        </div>
        <div>
            <input type="number" onChange={(set)=>setCantProf(set.target.value)}/>
            <label >elegir cant prof</label>
            <button onClick={agregarCantProf}>agregar</button>
            
        </div>
        <div>
            <p>seleccione deporte:</p>
            <select onChange={handleInputDeport}>
                {deportes ? deportes.map((e,i)=>
                    <option key={e.deport} value={e.deport}>
                    {e.deport}
                  </option>
            ):""    
            }
            </select>
        </div>
        <div>
            <p>Fecha de inicio: {seleccionados.diaini? seleccionados.diaini:"falta fechas"}</p>
            <p>Fecha fininal: {seleccionados.diafin? seleccionados.diafin:"falta fechas"}</p>
            <p>Horarios: {seleccionados.horarios? seleccionados.horarios.map(e => e + "//"):"faltan horarios"}</p>
            <p>Cantidad de profesores: {seleccionados.cantprofdisp? seleccionados.cantprofdisp:"faltan profesores"}</p>
            <p>Cantidad de profesores: {seleccionados.deport? seleccionados.deport:"falt deporte"}</p>
        </div>

        {seleccionados.diaini && seleccionados.diafin && seleccionados.horarios && seleccionados.cantprofdisp?
        <div>
            <button onClick={handleBotonCrear}>CREAR</button>
            <button>BORRAR TODO</button>
        </div>
        :""}
    </div>
  );
};
