import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  crearPagoMP,
  createReserva,
  getDeports,
  getPromporId,
  putSacarDisponibilidad,
  userLogin,
} from "../../redux/actions";
import Sweal from "sweetalert2";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { Loading } from "../loading/Loading";
import "./carrito.css";

export const Carrito = () => {
  const dispatch = useDispatch();
  const path = useResolvedPath();
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  

  // useEffect(() => {
  //   const loggedUserJSON = window.localStorage.getItem("logged")
  //   if(loggedUserJSON){
  //     const userlogg = JSON.parse(loggedUserJSON)
  //     dispatch(userLogin(userlogg))
  //   }
    
  // }, [])

  useEffect(() => {
    setFechaparaenviar(getDatafechaparaenviar());
    setCantpersonas(getcantpers());
    setDiasYhorariosCarrito(getDatadiasYhorariosCarrito());
    // setCodigoPromo(getDatapromo())
    aplicarDescuento();
  }, []);

  const getDatafechaparaenviar = () => {
    return JSON.parse(localStorage.getItem("fechasparaenviar"));
  };
  const getcantpers = () => {
    return JSON.parse(localStorage.getItem("cantpersonas"));
  };
  const getDatadiasYhorariosCarrito = () => {
    return JSON.parse(localStorage.getItem("diasYhorariosCarrito"));
  };
  const getDatapromo = () => {
    return JSON.parse(localStorage.getItem("codigoPromoIng"));
  };

  const [fechaparaenviar, setFechaparaenviar] = useState([]);
  const [diasYhorariosCarrito, setDiasYhorariosCarrito] = useState({});
  const [cantpersonas, setCantpersonas] = useState(1);

  const [codigoPromo, setCodigoPromo] = useState("");
  const userDetails = useSelector((state) => state.UserLog);
  const codigoXid = useSelector((state) => state.CodigoXid);
  const deports = useSelector((state) => state.Deports);
  const reservaCreada = useSelector((state) => state.CreateReserva);
  const MP_link = useSelector((state) => state.MP_link);
  const [descuento, setDescuento] = useState(1);
  const [costo, setCosto] = useState(0);
  const [valorClase, setValorClase] = useState(0);
  const [promo, setPromo] = useState(0);
  const [cantClases, setcantClases] = useState(0);
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    if (reservaCreada.id) {
      const resparaMP = {
        price_total: reservaCreada.valorreserva,
        email: reservaCreada.userEmail,
        id: reservaCreada.id,
      };
      dispatch(crearPagoMP(resparaMP));
    }
  }, [reservaCreada]);
  useEffect(() => {
    
    if (MP_link.init_point) {
      
      window.location.replace(MP_link.init_point);
    }
  }, [MP_link]);

  useEffect(() => {
    if (deports[0] && deports[0].deport) {
      fcosto();
    }
  }, [deports]);

  useEffect(() => {
    aplicarDescuento();
  }, [costo]);

  // useEffect(() => {
  //   if(codigoPromo && codigoPromo.length>3){
  //     dispatch(getPromporId(codigoPromo.toLowerCase()))
  //     aplicarDescuento()
  //   }
  // }, [codigoPromo])

  const fcosto = () => {
    if (
      deports &&
      deports[0] &&
      deports[0].deport &&
      fechaparaenviar != null &&
      fechaparaenviar[0] &&
      fechaparaenviar[0].deport
    ) {
      var dep = deports.find((e) => e.deport === fechaparaenviar[0].deport);

      var clases = 0;
      for (let i = 0; i < diasYhorariosCarrito.length; i++) {
        clases = clases + diasYhorariosCarrito[i].horarios.length;
      }
      setcantClases(clases);
      setCosto(dep.valorclase * clases);
      setValorClase(dep.valorclase);
    }
    // valorclase = dep.valorclase
  };
  const inputHandleChange = (e) => {
    setCodigoPromo(e.target.value);
  };
  const verificar = () => {
    if(codigoPromo.toLowerCase() !== "residente"){
    dispatch(getPromporId(codigoPromo.toLowerCase()));}
    // localStorage.setItem("codigoPromoIng", JSON.stringify(codigoPromo.toLowerCase()))
    aplicarDescuento();
  };

  const aplicarDescuento = () => {
    if (userDetails.residente) {
      if (!codigoXid.codigo) {
        dispatch(getPromporId("residente"));
      }

      if (codigoXid.descuento) {
        Sweal.fire("Éxito", "Se agrego descuento residente", "success");
        setDescuento(codigoXid.descuento);
      }
    } else if (fechaparaenviar.length >= 15 && cantpersonas >= 2) {
      if (!codigoXid.codigo) {
        dispatch(getPromporId("familia15"));
      }
      if (codigoXid.descuento && codigoXid.descuento + 0 > descuento) {
        Sweal.fire("Éxito", "Se agrego descuento familia15", "success");
        setDescuento(codigoXid.descuento);
      }
    } else if (fechaparaenviar.length >= 10 && cantpersonas >= 2) {
      if (!codigoXid.codigo) {
        dispatch(getPromporId("familia10"));
      }
      if (codigoXid.descuento && codigoXid.descuento + 0 > descuento) {
        Sweal.fire("Éxito", "Se agrego descuento familia10", "success");
        setDescuento(codigoXid.descuento);
      }
    } else if (fechaparaenviar.length >= 5 && cantpersonas >= 2) {
      if (!codigoXid.codigo) {
        dispatch(getPromporId("familia5"));
      }
      if (codigoXid.descuento && codigoXid.descuento + 0 > descuento) {
        Sweal.fire("Éxito", "Se agrego descuento familia5", "success");
        setDescuento(codigoXid.descuento);
      }
    } else if (
      codigoXid.cantdias >= fechaparaenviar.length &&
      codigoXid.cantpers >= cantpersonas
    ) {
      if (descuento === 1 && descuento !== "residente") {
        Sweal.fire(
          "Éxito",
          `Se agrego descuento ${codigoXid.codigo}`,
          "success"
        );
        setDescuento(codigoXid.descuento);
      }
    }
  };
  useEffect(() => {
    if (codigoXid.id) {
      aplicarDescuento();
    }
  }, [codigoXid]);
  const reservaenv = {
    diasreserva: [],
    cantdiasreserva: 0,
    cantpersonasreserva: cantpersonas,
    pago: 0,
    realizado: false,
    profesores: ["agu@gmail.com"],
    reservaalumnos: null,
    valorreserva: 0,
    valortotal: 0,
    userEmail: "",
    deportDeport: "",
    userId: 3,
  };
  const handlepagarboton = () => {
    var totalcondesc = 0;
    var valorreserva = 0
    if (descuento > 1) {
      totalcondesc = costo - costo * descuento * 0.01;
      valorreserva = (costo - (costo * descuento * 0.01)) * 0.1;
    } else {
      totalcondesc = costo;
      valorreserva = costo * 0.1;
    }
    
    for (let i = 0; i < diasYhorariosCarrito.length; i++) {
      const obj = {};
      if (diasYhorariosCarrito[i].horarios.length >= 1) {
        obj.dia = diasYhorariosCarrito[i].dia;
        obj.horario = diasYhorariosCarrito[i].horarios;
        reservaenv.diasreserva.push(obj);
        reservaenv.cantdiasreserva = reservaenv.cantdiasreserva + 1;
      }
    }
    if(reservaenv.cantpersonasreserva === null){
      reservaenv.cantpersonasreserva = 1
    }
    reservaenv.valorreserva = valorreserva;
    reservaenv.valortotal = totalcondesc;
    reservaenv.userEmail = userDetails.email;
    reservaenv.deportDeport = fechaparaenviar[0].deport;

    dispatch(createReserva(reservaenv));
    dispatch(putSacarDisponibilidad(fechaparaenviar));
    localStorage.removeItem("diasYhorariosCarrito");
    localStorage.removeItem("fechasparaenviar");
    localStorage.removeItem("codigoPromoIng");
    setCargando(true);
  };

  const masInfo = ()=>{
    Sweal.fire("Terminos de pago", "Se abonara lo correspondiente a la reserva mediante la plataforma MercadoPago. Se debera abonar el restante antes del inicio de la clase. Al no realizar el pago se dara de baja la reserva, perdiendo asi el horario de la clase. El restante se tendra que abonar mediante transferencia bancaria o en efectivo. Las clases podran ser modificadas, debido a condiciones climaticas, dicho asi se le informara via email y sera modificado su calendario.", "info");
  }


  if (cargando) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  } else {
    return (
      <div className="body__carrito">
        {/* <label>CODIGO PROM</label>
          <input type="text" onChange={inputHandleChange}/>
          <button onClick={verificar}>verificar</button>
          <h3>cant clases</h3>
          <p>{cantClases}</p>
          <h3>valor clase</h3>
          <p>{valorClase}</p>
          <h3>total</h3>
          <p>{costo}</p>
          {descuento != 1 ?
          <div>
              <h3>desucuento</h3>
              <p>{costo*descuento*0.01}</p>
  
          </div>
          :""
      }
  
  {isAuthenticated ? 
                 <button onClick={handlepagarboton}>
                 PAGAR
               </button>:
                 
      <button  onClick={()=>loginWithRedirect()}>PAGAR</button>
                 } */}

        <div className="card__carrito">
          <div className="row">
            <div className="col-md-8 cart__carrito">
              <div className="title__carrito">
                <div className="row">
                  <div className="col">
                    <h4>
                      <b>Clases</b>
                    </h4>
                  </div>
                  <div className="col align-self-center text-right text-muted">
                    {diasYhorariosCarrito.length} Dias
                  </div>
                </div>
              </div>


              {diasYhorariosCarrito && diasYhorariosCarrito.length > 0? 
              
              diasYhorariosCarrito.map(e =>
                <div className="row border-top border-bottom" key={e.dia}>
                  <div className="row main align-items-center">
                    <div className="col-2">
                      <img
                        className="img__carrito img-fluid"
                        src="https://images.emojiterra.com/google/noto-emoji/v2.034/512px/1f30a.png"
                      />
                    </div>
                    <div className="col">
                      <div className="row text-muted">{e.dia}</div>
                      <div className="row">{e.deport}</div>
                    </div>
                    <div className="col colhorarios__carrito">
                      {e.horarios.map(h => 
                        <p className="a__carrito border__carrito">
                        {h}hs.
                      </p>
                        )}
                    
                  </div>
                  <div className="col">
                    $ {e.horarios.length * valorClase}
                    {/* <span className="close__carrito">&#10005;</span> */}
                  </div>

                  </div>

                </div>
                
                )
              
              
              :""}
              




              <div className="row border-top border-bottom">

              </div>
                <div className="back-to-shop__carrito">
                  <span className="text-muted">Se cobrara solamente la reserva.</span>
                  <button onClick={masInfo}>Mas info...</button>
                </div>
            </div>
            <div className="col-md-4 summary__carrito">
              <div>
                <h5 className="h5__carrito">
                  <b>Resumen</b>
                </h5>
              </div>
              <hr className="hr__carrito" />
              <div class="row">
                <div class="col cantitems__carrito">
                  Cantidad de clases {cantClases}
                </div>
                <div class="col text-right">${costo}</div>
              </div>
              <div className="form__carrito">
                {/* <p>SHIPPING</p>
                <select className="select__carrito">
                  <option className="text-muted">Standar delivery 5,00</option>
                </select> */}
                <p>CODIGO DE DESCUENTO</p>
                <div>
                  <input
                    type="text"
                    id="code__carrito"
                    onChange={inputHandleChange}
                    placeholder="INGRESE CODIGO"
                  />
                  <button className="btn-dark" onClick={verificar}>
                    VERIFICAR
                  </button>
                </div>
              </div>
              <div className="row preciofinal__carrito">
                <div className="col">PRECIO TOTAL + DESCUENTO</div>
                {descuento > 1 ? (
                  <div className="col text-right">
                    ${costo - costo * descuento * 0.01}
                  </div>
                ) : (
                  <div className="col text-right">${costo}</div>
                )}
              </div>
              <div className="row preciofinal__carrito">
                <div className="col">PRECIO RESERVA</div>
                {descuento > 1 ? (
                  <div className="col text-right">
                    ${(costo - costo * descuento * 0.01) * 0.1}
                  </div>
                ) : (
                  <div className="col text-right">${costo * 0.1}</div>
                )}
              </div>
              {isAuthenticated || userDetails.roleId ? (
                <button onClick={handlepagarboton} className="btn__carrito">
                  PAGAR
                </button>
              ) : (
                <button
                  onClick={() => loginWithRedirect()}
                  className="btn__carrito"
                >
                  Log para pagar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};
