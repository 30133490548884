import React from 'react'
import { Footer } from '../footer/Footer'
import "./clasessup.css"

export const ClasesSup = () => {
  return (
    <div className="home__clasessup">
    <header className="hero__clasessup">
      <section className="hero__main__clasessup container__clasessup">
        <h1 className="hero__title__clasessup">CLASES DE SUP</h1>
      </section>
    </header>
    <main>
      <section className="services__clasessup">
        <div className="service_image--clasessup"></div>
        <div className="services__texts__clasessup">
          <h2 className="services__title__clasessup"> Clases</h2>
          <p className="services__paragraph__clasessup">
          El Paddle Surf o SUP es un deporte que cada vez tiene más aficionados, por qué será?.. descubrilo vos mismo!! Vení a remar con nosotros!  Podes intentar surfearte una olita o simplemente salir a remar mar adentro con tu profe; el desafío es personal, animate!!</p>
        </div>
        <div className="service__image--iynmsup"></div>

        <div className="services__texts__clasessup services__texts--2__clasessup">
          <h2 className="services__title__clasessup"> Iniciación/ Nivel Medio</h2>
          <li>Clase personalizada: un profe- un alumno</li>
          <li>Acompañamiento permanente dentro y fuera del Mar</li>
          <li>Duración de la clase: 50´aprox</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          
        </div>
      </section>
    </main>
    
<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
