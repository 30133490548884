import React, { useCallback, useRef, useState } from "react";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProfesores, getAllReservasTotales, getAllUsers, updateClaseAdmin } from "../../redux/actions";
import { findUserForClass } from "./funciones.jsx/filtrarusuarioclase";
import { allreservasarray } from "../paneladmin/funciones/allreservasarray";

export const Calendario = ({reservasA}) => {
  
    const clickRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const allReservasTotales = useSelector((state) => state.AllReservasTotales);
    const allProfes = useSelector((state) => state.AllProfes);
    const allUsers = useSelector((state) => state.AllUsers);

    const [show, setShow] = useState(false);
    const [popUp, setPopUp] = useState({})
    const [title, setTitle] = useState("")
    const [arrayAlumnos, setArrayAlumnos] = useState([])
    const [alumno, setAlumno] = useState({})
    const [alumno2, setAlumno2] = useState({})
    const [pagado, setPagado] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)
    const [CantClases, setCantClases] = useState(0)
    const [CantPersonas, setCantPersonas] = useState(0)
    const [profesor, setProfesor] = useState("")
    const [profesor2, setProfesor2] = useState("")
    const [id, setId] = useState(0)
    const [realizado, setRealizado] = useState(false)
    const [input, setInput] = useState({
      alumno: "",
      realizado: false,
      profesoreEmail: "",
      pago: 0,
      id: 0
    });

const [allUsuarios, setAllUsuarios] = useState([])

    useEffect(() => {
      dispatch(getAllProfesores())
      dispatch(getAllUsers())
      dispatch(getAllReservasTotales());
    
    }, [])
    

useEffect(() => {
  if(alumno){
    setAlumno2(findUserForClass(allUsers, alumno))
  }
  if(!alumno){
    setAlumno2(null)
  }
  if(profesor){
    setProfesor2(findUserForClass(allUsers, profesor))
  }
  if(!profesor){
    setProfesor2(null)
  }

}, [alumno, profesor])



    const handleInputChange = function (e) {
      if (e.target.name === "realizado") {
        setInput({
          ...input,
          [e.target.name]: e.target.checked,
        });
        }else{
          setInput({
            ...input,
            [e.target.name]: e.target.value,
            // [e.target.name]: e.target.checked,
          });

        }
        
    };
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  
    const locales = {
      "en-US": require("date-fns/locale/en-US"),
    };
    const localizer = dateFnsLocalizer({
      format,
      parse,
      startOfWeek,
      getDay,
      locales,
    });
    
    var events = reservasA
    
    const onSelectEvent = useCallback((calEvent) => {
      /**
       * Here we are waiting 250 milliseconds (use what you want) prior to firing
       * our method. Why? Because both 'click' and 'doubleClick'
       * would fire, in the event of a 'doubleClick'. By doing
       * this, the 'click' handler is overridden by the 'doubleClick'
       * action.
       */
        
      // if(calEvent.desc.alumno){
      // setAlumno(calEvent.desc.alumno)}
      // setProfesor(calEvent.desc.profesoreEmail)
      setRealizado(calEvent.desc.realizado)
      setInput({
        ...input,
        id: calEvent.desc.id,
        pago: calEvent.desc.pago,
        reservaId : calEvent.desc.reservaId,
        profesoreEmail : calEvent.desc.profesoreEmail,
        alumno: calEvent.desc.alumno

      });
      
      setId(calEvent.desc.id)
      setPagado(calEvent.desc.pago)
      setValorTotal(calEvent.desc.valortotal)
      setArrayAlumnos(calEvent.desc.reservaalumnos)
      setCantClases(calEvent.desc.cantdiasreserva)
      setCantPersonas(calEvent.desc.cantpersonasreserva)
      setTitle(calEvent.title);

        setAlumno(calEvent.desc.alumno)
        setProfesor(calEvent.desc.profesoreEmail)
        // setAlumno(findUserForClass(allUsers, calEvent.desc.alumno))
        // setProfesor(findUserForClass(allUsers, calEvent.desc.profesoreEmail))
      handleShow()
    }, []);

    const savechange = ()=>{
      dispatch(updateClaseAdmin(input))
      
      handleClose()
      reload()
    }

    const reload = ()=>{
      setTimeout(function(){
       navigate(0)
   }, 2000);}
    

  return (
    <div>
        <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
        onSelectEvent={onSelectEvent}
        popup
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cant clases reserva: {CantClases}</p>
          <p>Cant personas reserva: {CantPersonas}</p>
          <p>${pagado}/${valorTotal}</p>
          <p>Profesor: {profesor2 && profesor2.name ? profesor2.name:""}</p>
          
          <p>Alumno: {alumno2 && alumno2.name ? alumno2.name:""}</p>
          
          <p>Cant de clases tomadas: {alumno2 && alumno2.cantclases ? alumno2.cantclases:""}</p>
          


          {!realizado ?
          <div>

<p>Selecciona almuno:</p>
            <select name="alumno" id="selalumnos" onChange={handleInputChange} onClick={handleInputChange}>
            {arrayAlumnos.map((e,i) => (
          <option key={i} name={e.userEmail} value={e.userEmail}>
            {e.name}
          </option>
        ))}
      </select>
      <p>Selecciona profe:</p>
      <select name="profesoreEmail" id="selprofe" onChange={handleInputChange} onClick={handleInputChange}>
            {allProfes.map((e,i) => (
          <option key={i} value={e.email}>
            {e.user.name}
          </option>
        ))}
      </select>
      <div>
      <label>Pago:</label>
      <input type="number" name="pago" onChange={handleInputChange} defaultValue={pagado}/>/${valorTotal}
      </div>
      <input type="checkbox" id="realizado" name="realizado" value="true" defaultChecked={realizado} onChange={handleInputChange}/>
      <label> REALIZADO</label>
          </div>

    :""
        
        
        
        
        }





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!realizado?
            <Button variant="primary" onClick={savechange}>
            Save Changes
          </Button>:""
          }
          
        </Modal.Footer>
      </Modal>
    </div>
  )
}
