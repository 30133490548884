import React, { useState } from "react";
// import DatePicker from "react-multi-date-picker";
// import { Calendar } from "react-multi-date-picker"

import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"





import MultipleDatePicker from "react-multiple-datepicker";
import { format } from "date-fns";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deporteSeleccionado, getDeports, getDisponibilidadXDia, resetDisponibilidadXdia } from "../../redux/actions";
import { Horarioslibres } from "./horarioslibres";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { sacarDisp } from "../botonreservar/funciones";

const Calpreservas = () => {
  const dispatch = useDispatch();
  const diasdisponibles = useSelector((state) => state.DisponibilidadXDia);
  const deports = useSelector((state) => state.Deports);

  const [dias, setDias] = useState([]);
  const [diasF, setDiasF] = useState([]);
  const [selecDeport, setSelecDeport] = useState("surf");
  const [cantPersonas, setCantPersonas] = useState(1)

  useEffect(() => {
    dispatch(getDeports());
  }, []);

  useEffect(() => {
    formatearDias(dias);
  }, [dias]);

  const formatearDias = () => {
    const diai = [];
    for (let i = 0; i < dias.length; i++) {
      const date = new Date(dias[i]);
      diai.push(format(date, "yyyy/MM/dd"));
    }
    setDiasF(diai);
  };
  // useEffect(() => {
  //   if(diasF.length > 0){
  //     dispatch(getDisponibilidadXDia(diasF))

  //   }
  // }, [diasF]);

  const handleInputDep = function (e) {
    setSelecDeport(e.target.value);
    // dispatch(deporteSeleccionado(selecDeport))
  };
  const handleInputCantPers = function (e) {
    setCantPersonas(e.target.value);
    localStorage.setItem("cantpersonas", JSON.stringify(e.target.value))
  };


const [sebusco, setSebusco] = useState(false)



  const buscarDisp = () => {
    setSebusco(true)
    const buscar = {
      dias: diasF,
      deport: selecDeport,
    };

    if (diasF.length > 0) {
      dispatch(getDisponibilidadXDia(buscar));
      // dispatch(deporteSeleccionado(buscar.deport))
    }
  };

//  useEffect(() => {
   
 
//    return () => {
//      dispatch(resetDisponibilidadXdia())
//    }
//  }, [])

//  const diasYhorarios = useSelector((state) => state.DiaSeleccionado);
 const deporteSeleccionado = selecDeport;
 const disponibilidadXdia = useSelector((state) => state.DisponibilidadXDia);
 const verificar = ""



 const [mostrarbotonreseravar, setMostrarbotonreseravar] = useState(false)

const [cambiarState, setCambiarState] = useState(1)
//  useEffect(() => {

// if(!mostrarbotonreseravar){
//    setTimeout(() => {
//     setCambiarState(cambiarState + 1)
//     sicambio(diasYhorarios)
//    }, 1000);
//    }

 
//  }, [diasYhorarios,cambiarState])
 


 const sicambio = (dias)=>{
  const keydias = Object.keys(dias);
  for (var i = 0; i < keydias.length; i++) {
    
     const obj = {
       diadisp: "",
       horariosusados: [],
       deport: "",
     };
     const horariosusados = [];
     const horar = dias[keydias[i]];
     const arrayHorarios = Object.keys(dias[keydias[i]]);
     for (var x = 0; x < arrayHorarios.length; x++) {
       if (horar[arrayHorarios[x]] != 0) {
         setMostrarbotonreseravar(true)
         
       }
     }
}
}















 
 const reservar = function () {
  
   
   
   const fechasparaenviar = [];
   const diasYhorariosCarrito = []
   const arrayDias = Object.keys(diasYhorarios);
   const buscar = {
     dias: arrayDias,
     deport: deporteSeleccionado,
   };



   ////////////////////////////
   const hor = []
   
   for (var i = 0; i < arrayDias.length; i++) {
       const horariosT = disponibilidadXdia[i].horariosusados;
     const obj = {
       diadisp: "",
       horariosusados: [],
       deport: "",
     };
     const horariosusados = [];
     const horar = diasYhorarios[arrayDias[i]];
     const arrayHorarios = Object.keys(diasYhorarios[arrayDias[i]]);
     for (var x = 0; x < arrayHorarios.length; x++) {
       if (horar[arrayHorarios[x]] != 0) {
         for (var y = 0; y < horar[arrayHorarios[x]]; y++) {
           horariosusados.push(arrayHorarios[x]);
         }
       }
     }
     obj.diadisp = arrayDias[i];
     obj.horariosusados = sacarDisp(horariosT,horariosusados);
     obj.deport = deporteSeleccionado;
     hor.push(horar)
     const o = {
       dia: arrayDias[i],
       horarios: horariosusados,
       deport: deporteSeleccionado
     }
     diasYhorariosCarrito.push(o)
     
     if (obj.horariosusados.length >= 0) {
       if(obj.horariosusados.length === 0){
           obj.horariosusados = []
       }
       fechasparaenviar.push(obj);
     }
   }
   
  //  for(let x = 0 ; x<hor.length ; x++){
  //   const array = Object.values(hor[x]);
  //   let total = array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  //   if(total != cantPersonas){
  //     Swal.fire('Ups hubo un error', `La cantidad de personas no coinciden con la cantidad de clases seleccionadas`, 'error')
  //     diasYhorarios = []
  //     return ""
  //   }else{
      
  //   }
  // }
  localStorage.setItem("fechasparaenviar", JSON.stringify(fechasparaenviar))
  localStorage.setItem("diasYhorariosCarrito", JSON.stringify(diasYhorariosCarrito))
  
  
   // const navi = ()=>{
   //   navigate("/carrito")

   // }
   // navi()
   // dispatch(putSacarDisponibilidad(fechasparaenviar))
   // dispatch(getDisponibilidadXDia(buscar))

   Swal.fire({
     title: '<strong>IR AL CARRITO</strong>',
     icon: 'success',
     html:'<a btn btn-success  href="https://www.sudestadapinamar.com.ar/carrito"><i class="btn btn-success bi bi-cart"></i></a>',
     //   'You can use <b>bold text</b>, ' +
     //   '<a href="carrito">links</a> ' +
     //   'and other HTML tags',
     // showCloseButton: true,
     // showCancelButton: true,
     showConfirmButton:false,
     focusConfirm: false,
   })
   diasYhorarios = []
 };



 const cantidaddehoras = (posicion, horarios, horariosdisp, dia)=>{
  var cant = 0;
  const horariosSeleccionados = { 
    dia: 0, 
    // horario: 0,
    // cant: 0
}
  for (var i = 0; i < horariosdisp.length; i++) {
    if (horariosdisp[i] === horarios[posicion]) {
      cant++;
    }
  }

  var num = [];
  for (var x = 0; x < cant + 1; x++) {
    num.push(x);
  }
  return num
 }

 const [diasYhorarios, setDiasYhorarios] = useState({})

 const handleInputH = (e)=>{
  
  const enarray = (e.target.value.split(","))
  const posicionselect = enarray[0]
  const diaselect = enarray[1]
  const cantselect = enarray[2]
  const horarioselect = enarray[3+ +posicionselect]
  const sumapos = 2 + +posicionselect
  
  
  const obj = {
    
    diaselect : enarray[1],
    cantselect : cantselect,
   horarioselect : horarioselect
  }
  if(diasYhorarios[obj.diaselect]){
    diasYhorarios[obj.diaselect][obj.horarioselect] = obj.cantselect
  }else{
    diasYhorarios[obj.diaselect] = {[obj.horarioselect] : obj.cantselect}
  }
  
  setMostrarbotonreseravar(true)
 }



  return (
    <div>
      <div>
        <label>Seleccione la cantidad de personas:</label>
      <select name="cantPers" onChange={handleInputCantPers} >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
      </select>
      </div>
      <div>
        <label>Seleccione deporte:</label>
      <select name="deportes" onChange={handleInputDep} defaultValue="surf">
        {deports && deports.length > 0
          ? deports.map((e) => (
              <option key={e.deport} value={e.deport}>
                {e.deport}
              </option>
            ))
          : ""}
      </select>
      </div>
      {/* <MultipleDatePicker onSubmit={(dates) => setDias(dates)} /> */}




            <div>
<label>Seleccione los dias:</label>
      <DatePicker
  multiple
  calendarPosition="top"
  fixMainPosition="fixMainPosition"
  minDate={new Date((new Date()).valueOf() + 1000*3600*36)}
  value={dias} 
  onChange={setDias}
  plugins={[
   <DatePanel />
  ]}
/>
{dias.length > 0 ?
  <button className="btn btn-primary" onClick={buscarDisp}>Verificar fechas</button>
  :
  ""

}
      {/* {diasdisponibles && diasdisponibles.length > 0 ? diasdisponibles.map(e => <div key={e.diadisp}><div>{e.diadisp}</div><div>{e.horariosusados[0]}</div></div>):""} */}
      
      
      {/* {diasdisponibles && diasdisponibles.length > 0
        ? 
        <div>
          <p>Seleccione cantidad de alumnos por horario:</p>
          {diasdisponibles.map((e,i) => (
            <Horarioslibres key={i} diadisp={e}></Horarioslibres>
          ))}
          
        </div>
        
        : ""}



      {mostrarbotonreseravar || true? 
      <button className="btn btn-success" onClick={reservar}>RESERVAR</button>
      :""
    }
       */}
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      {diasdisponibles && diasdisponibles.length > 0
        ? 





        <div>
          <p>Seleccione cantidad de alumnos por horario:</p>
          {diasdisponibles.map((e,i) => (
            // <Horarioslibres key={i} diadisp={e}></Horarioslibres>
            
            e.diadisp ?
            e.horariosusados[0] === "sin horarios" || e.horariosusados.length === 0 ?
            <div>
                <div>{e.diadisp}</div>
                <div>Sin horarios</div>
            </div>
            :
            <div>
              <div>{e.diadisp}</div>
              {e.horariosdisp.map((a,i) => <div key={i*54651}>{a}hs:
              <select onChange={handleInputH}> 
      {
        cantidaddehoras(i, e.horariosdisp ,e.horariosusados, e.diadisp).map((d) => (
        <option key={d} value={[i,e.diadisp,d,e.horariosdisp]}>
          {d}
        </option>
      ))
      }

    </select>

              {/* <Seleccionador key={i*5461} posicion = {i} horarios = {horarios} horariosdisp = {horariosdisp} dia = {dia}></Seleccionador> */}

              </div>
              )}

              </div>

              :
              <p>
                no existe diasdiphorarios
              </p>



          ))}
          
        </div>
        
        : ""}




      {mostrarbotonreseravar? 
      <button className="btn btn-success" onClick={reservar}>RESERVAR</button>
      :""
    }
      
    </div>
    </div>
  );
};

export default Calpreservas;
