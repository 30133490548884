import React from 'react'
import { Footer } from '../footer/Footer'
import "./clasespeques.css"

export const ClasesPeques = () => {
  return (
    <div className="home__clasespeques">
    <header className="hero__clasespeques">
      <section className="hero__main__clasespeques container__clasespeques">
        <h1 className="hero__title__clasespeques">PEQUE SURF</h1>
      </section>
    </header>
    <main>
      <section className="services__clasespeques">
        <div className="service_image--clasespeques"></div>
        <div className="services__texts__clasespeques">
          <h2 className="services__title__clasespeques"> Clases</h2>
          <p className="services__paragraph__clasespeques">
          El programa “Peque Surf” es una propuesta de ambientación al medio acuático diseñada para los más chiquitos. Por medio del juego y el Surf, aprenderán técnicas y ejercicios para poder desenvolverse con mayor autonomía y seguridad dentro del Mar.
            </p>
        </div>
        <div className="service__image--peque"></div>

        <div className="services__texts services__texts--2__clasespeques">
          <li>Edad: 4 a 7 años</li>
          <li>Requisitos: Ninguno</li>
          <li>Duración de la clase: 40´aprox</li>
          <li>Equipo de Surf y materiales didácticos incluidos</li>
          <li>Acompañamiento permanente dentro del agua</li>
          <li>Profesores titulados y Guardavidas a cargo de la actividad</li>
          
        </div>
      </section>
      
    </main>
    
<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
