import React from 'react'
import { Footer } from '../footer/Footer'
import "./alquilerequipos.css"

export const Alquilerequipos = () => {
  return (
    <div className="home__alquilerequipos">
    <header className="hero__alquilerequipos">
      <section className="hero__main__alquilerequipos container__alquilerequipos">
        <h1 className="hero__title__alquilerequipos">ALQUILER EQUIPOS</h1>
      </section>
    </header>
    <main>
      <section className="services__alquilerequipos">
        <div className="service_image--alquilerequipos"></div>
        <div className="services__texts__alquilerequipos">
          <h2 className="services__title__alquilerequipos"> Alquiler de Tabla de Surf</h2>
          <li>Tablas Hard, Soft y Dura Tec </li>
          <li>Todos los alquileres requieren depósito/tarjeta como garantía</li>
          <li>Los daños o roturas corren por cuenta de quien alquila la tabla</li>
          <li>Las tablas se alquilan con quillas y pita.</li>
          <li>Traje de Neoprene NO incluido</li>
          
          </div>
        <div className="service__image--alquilerequipos1"></div>

        <div className="services__texts__alquilerequipos services__texts--2__alquilerequipos">
          <h2 className="services__title__alquilerequipos"> Alquiler de Tabla de Paddle Surf</h2>
          <li>Tabla de SUP inflable 105 lts AQUATONE</li>
          <li>Todos los alquileres requieren depósito/tarjeta como garantía</li>
          <li>Los daños o roturas corren por cuenta de quien alquila la tabla</li>
          <li>La tabla se alquila con quillas, pita y chaleco salvavidas</li>
          <li>Traje de Neoprene NO incluido</li>
          
        </div>
      </section>
    </main>
    
<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
