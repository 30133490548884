import React from 'react'
import "./contactanos.css"
import Iframe from 'react-iframe'
import { Footer } from '../footer/Footer'


export const Contactanos = () => {
  return (

    // <div className='body__contactanos'>
    //     <section className="card__contactanos__container__contactanos">
    //         <article className="card__contactanos__item__contactanos">
    //         <h2 className="card__contactanos__name__contactanos">Supervisor</h2>
    //         <p className="card__contactanos__copy">Monitore activity</p>
    //         <div className="card__contactanos__picture__contactanos">
    //             <div className="card__contactanos__img__contactanos-1"></div>
    //         </div>

    //         </article>
    //         <article className="card__contactanos__item__contactanos">
    //         <h2 className="card__contactanos__name__contactanos">Supervisor1</h2>
    //         <p className="card__contactanos__copy">Monitore activity</p>
    //         <div className="card__contactanos__picture__contactanos">
    //             <div className="card__contactanos__img__contactanos-1"></div>
    //         </div>

    //         </article>
    //         <article className="card__contactanos__item__contactanos">
    //         <h2 className="card__contactanos__name__contactanos">Supervisor2</h2>
    //         <p className="card__contactanos__copy">Monitore activity</p>
    //         <div className="card__contactanos__picture__contactanos">
    //             <div className="card__contactanos__img__contactanos-1"></div>
    //         </div>

    //         </article>
    //         <article className="card__contactanos__item__contactanos">
    //         <h2 className="card__contactanos__name__contactanos">Supervisor3</h2>
    //         <p className="card__contactanos__copy">Monitore activity</p>
    //         <div className="card__contactanos__picture__contactanos">
    //             <div className="card__contactanos__img__contactanos-1"></div>
    //         </div>

    //         </article>
    //     </section>      
           
    // </div>
    <div className="body__contactanos">


<div class="container__contactanos">
  <div class="card__contactanos">
    <div class="icon">
      <div name="globe-outline .ion-icon">
      
        <a href="https://www.google.com/maps/place/Sudestada/@-37.0851694,-56.8300277,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x88da115148c6bb1!8m2!3d-37.0851694!4d-56.8300277" target="_blank" rel="noopener noreferrer" style={{"color":"black" }}><i className="bi bi-geo-alt" style={{"font-size": "4rem" }}></i></a>
        </div>
    </div>
    <div class="content">
      <h2>Encontranos en:</h2>
      <p>Avenida del Olimpo Y Playa, B7167 Pinamar, Provincia de Buenos Aires</p>
    </div>
  </div>
  <div class="card__contactanos">
    <div class="icon">
      <div name="globe-outline .ion-icon">
      
        <a href="https://www.instagram.com/sudestadapinamar/" target="_blank" rel="noopener noreferrer" style={{"color":"black" }}><i className="bi bi-instagram" style={{"font-size": "4rem" }}></i></a>
        </div>
    </div>
    <div class="content">
      <h2>Instagram</h2>
      <img src="https://res.cloudinary.com/dkn2dgfex/image/upload/v1671063168/logo/logofondotransp120_ikuytg.png" alt="" />
      
    </div>
  </div>
  <div class="card__contactanos">
    <div class="icon">
      <div name="globe-outline .ion-icon">
      <a href="https://wa.me/5492254587288" target="_blank" rel="noopener noreferrer" style={{"color":"black" }}><i className="bi bi-whatsapp" style={{"font-size": "4rem" }}></i></a>
      
        </div>
    </div>
    <div class="content">
      <h2>Whatsapp</h2>
      <p>+5492254587288</p>
    </div>
  </div>
  <div class="card__contactanos">
    <div class="icon">
    
      <div name="globe-outline .ion-icon">
        <a href="https://www.facebook.com/surfsudestada" target="_blank" rel="noopener noreferrer" style={{"color":"black" }}><i className="bi bi-facebook" style={{"font-size": "4rem" }}></i></a>
        </div>
    </div>
    <div class="content">
      <h2>FACEBOOK</h2>
      
      
      <Iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fsurfsudestada&width=207&layout=button&action=like&size=large&share=true&height=65&appId" width="207" height="65" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>


    </div>
  </div>
  
</div>

<Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3182.8378190786943!2d-56.830027699999995!3d-37.0851694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x88da115148c6bb1!2sSudestada!5e0!3m2!1ses-419!2sar!4v1673476621840!5m2!1ses-419!2sar" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>


    <Footer></Footer>

    </div>


  )
}
