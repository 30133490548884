import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser, deleteReservaDeuda, deleteUserAdmin, getAllReservasTotales, getAllUsers, modificarDiaClaseAdmin, updateUserAdmin } from "../../../redux/actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./faltadepago.scss"
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";

export const FaltadePago = () => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const allReservas = useSelector((state) => state.AllReservasTotales);
  const [showDel, setShowDel] = useState(false);
  const [clasesF, setClasesF] = useState([])
  const [claseSelec, setClaseSelec] = useState({})



  useEffect(() => {
    dispatch(getAllReservasTotales())
  
  }, [])

  useEffect(() => {
    if(allReservas.length > 0){
        filtrarClases()
    }
  
  }, [allReservas])
  
  const filtrarClases = ()=>{
    const aa = allReservas.sort((a, b) => new Date(a.fecha) - new Date(b.fecha))


var hash = {};
const bb = aa.filter(function(current) {
  var exists = !hash[current.reservaId];
  hash[current.reservaId] = true;
  return exists;
});
const cc = bb.filter(a => a.realizado === false && +a.reserva.pago - +a.reserva.valortotal !== 0)
setClasesF(cc)
setData(cc)
  }



  const handleCloseDel = () => setShowDel(false);
  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
        field: "fecha",
        headerName: "Fecha",
        width: 100,
      },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (params) => {
        return (
          <p className="cellWithImg">
            {params.row.user.name} {params.row.user.lastname}
          </p>
        );
      },
    },
    {
      field: "userEmail",
      headerName: "Email",
      width: 230,
    },
  
    {
        field: "tel",
        headerName: "Telefono",
        width: 170,
        renderCell: (params) => {
          return (
            <p className="cellWithImg">
              {params.row.user.tel}
            </p>
          );
        },
      },
      {
        field: "pago",
        headerName: "Pago",
        width: 80,
        renderCell: (params) => {
          return (
            <p className="cellWithImg">
              ${params.row.reserva.pago}
            </p>
          );
        },
      },
      {
        field: "total",
        headerName: "Total",
        width: 80,
        renderCell: (params) => {
          return (
            <p className="cellWithImg">
              ${params.row.reserva.valortotal}
            </p>
          );
        },
      },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];
  const [data, setData] = useState([]);

//   useEffect(() => {
//     if(allUsers && allUsers.length > 1){
//       const userConId = []
//       for (let i = 0; i < allUsers.length; i++) {
//         const a = allUsers[i];
//         a.id = i
//         userConId.push(a)
        
//       }
//       setData(userConId)
//     }
//   }, [allUsers])

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            
            <div
              className="deleteButton"
              onClick={(x) => eliminarReserva(params.row)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const eliminarReserva = (e) => {
    setShowDel(true)
    setClaseSelec(e)
  }

  const botonEliminar = ()=>{
    dispatch(deleteReservaDeuda(claseSelec))
    reload();

  }
  const reload = ()=>{
    setTimeout(function(){
      reload();
 }, 2000);
}

  return (
    <div className="list">
    <div className="listContainer">
    <div className="datatable">
    <div className="datatableTitle">
      Deudores
      
    </div>
    <DataGrid
      className="datagrid"
      rows={data}
      columns={userColumns.concat(actionColumn)}
      pageSize={10}
      rowsPerPageOptions={[10]}
      // checkboxSelection
    />
  </div>
    </div>


    <div>
        
      
      <Modal show={showDel} onHide={handleCloseDel}>
        <Modal.Header closeButton>
          <Modal.Title>ELIMINAR RESERVA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {claseSelec && claseSelec.id ? 
            
          <div>
            <div>
                <p>Nombre y Apellido: {claseSelec.user.name} {claseSelec.user.lastname}</p>
            </div>
            <div>
                <p>Telefono: {claseSelec.user.tel}</p>
            </div>
            <div>
                <p>Email: {claseSelec.user.email}</p>
            </div>
            <div>
                <p>Pago: ${claseSelec.reserva.pago}</p>
            </div>
            <div>
                <p>Total: ${claseSelec.reserva.valortotal}</p>
            </div>
            <div>
                <p>Cantidad de personas: {claseSelec.reserva.cantpersonasreserva}</p>
            </div>
            <div>
                <p>Dias de reserva: </p>
                {claseSelec.reserva.diasreserva.map(e => <p key={e.id}>{e.dia} ( {e.horario.map(e => e + "hs ")+")"}</p>)}
            </div>
            </div>
        :""}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDel}>
            Close
          </Button>
          {true ? (
            <Button variant="danger" onClick={botonEliminar}>
              ELIMINAR
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      
    </div>
  </div>
  )
}
