import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteReservaCero, getReservaXUsuario } from "../../../redux/actions";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid } from '@mui/x-data-grid';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./reservasusuario.scss"
import { Reservar } from "../../reservar/Reservar";

export const ReservasUsuario = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reservasUsuario = useSelector((state) => state.ReservasPorUsuario);
  const userDetails = useSelector((state) => state.UserLog);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if(userDetails.email){
      dispatch(getReservaXUsuario(userDetails.email));

    }
  }, [userDetails]);










  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "diasreserva",
      headerName: "Dias de reserva",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="celda__reserva">
            {params.row.diasreserva.map(e => <div className="celda__div__reserva"><p className="celda__p__reserva">/ {e.dia} /</p></div>)}
          </div>
        );
      },
    },
    {
      field: "pago",
      headerName: "Pago",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="celda__reserva">
            <p className="celda__p__reserva">${params.row.pago}</p>
          </div>
        );
      },
    },
    {
      field: "valortotal",
      headerName: "Valor Total",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="celda__reserva">
            <p className="celda__p__reserva">${params.row.valortotal}</p>
          </div>
        );
      },
    },
    {
      field: "deportDeport",
      headerName: "Deporte",
      width: 65,
    },
    {
      field: "reservaalumnos",
      headerName: "Alumnos",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="celda__reserva">
            {params.row.reservaalumnos ? params.row.reservaalumnos.map(e => <div className="celda__div__reserva"><p className="celda__p__reserva">/ {e.name} /</p></div>)
            : <p>Debe asignar {params.row.cantpersonasreserva} alumnos</p>}
          </div>
        );
      },
    },
  
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
  ];
  const [data, setData] = useState([]);

  const actionColumn = [
    {
      field: "agregaralumno",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
          {params.row.reservaalumnos? 
              <button  className="btn btn-success">Asignados</button>
              
              
              :
              
            <Link to={`./modificarreserva/${params.id}`}  className="btn btn-info">Asignar alumnos</Link>
         
        
        
      }
      </div>
        );
      },
    },
  ];

  useEffect(() => {
    if(reservasUsuario && reservasUsuario.length > 0){
      verencero()
      setData(reservasUsuario)
    }
    
  }, [reservasUsuario])
  
  
  const verencero = ()=>{
    var reservasencero = []
    for (let i = 0; i < reservasUsuario.length; i++) {
      if(reservasUsuario[i].pago === "0"){
        reservasencero.push(reservasUsuario[i])
  
      }
      
    }
    if(reservasencero.length > 0){
      
    dispatch(deleteReservaCero(reservasencero))
  
      reload()
  }
  }
  const reload = ()=>{
    setTimeout(function(){
     navigate(0)
  }, 2000);}
  
  const handleClose = () => {
    setShow(false)
    reload()
  };

  

  return (
    <div className="list">
      <div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle">
        RESERVAS
        <button onClick={()=>setShow(true)} class="button button--3">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="fish"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <span class="button__text">R E S E R V A</span>
    </button>
      </div>

      {reservasUsuario && reservasUsuario.length > 0
        ?
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
      :
      <h1>SIN RESERVAS</h1>}


    </div>
      </div>

  


















      {/* {reservasUsuario && reservasUsuario.length > 0
        ? reservasUsuario.map((r) => (
            <div key={r.id} name={r.id}>
              <div>
                <h2>fecha</h2>
                {r.diasreserva.map((dias) => (
                  <p key={dias.dia}>{dias.dia}</p>
                ))}
                <h2>Pagado</h2>
                <p>{r.pago}</p>
                <h2>Total</h2>
                <p>{r.valortotal}</p>
                <h2>Alumnos</h2>
                <p>
                  {r.reservaalumnos
                    ? r.reservaalumnos.map((a) => a.name + " ")
                    : "sin alumnos"}
                </p>
                <Link to={`./modificarreserva/${r.id}`}>Modificar Alumnos</Link>
              </div>
            </div>
          ))
        : "sin reservas"} */}



        <div>
        <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header closeButton>
            <Modal.Title>Reservar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Reservar></Reservar>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
    </div>
  );
};
