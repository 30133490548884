export const GET_WINDGURU = "GET_WINDGURU";
export const GET_CLIMA = "GET_CLIMA";
export const GET_MAREA = "GET_MAREA";
export const GET_DEPORTS = "GET_DEPORTS";
export const DIAS_SELECCIONADOS = "DIAS_SELECCIONADOS";
export const DEPORTE_SELECCIONADO = "DEPORTE_SELECCIONADO";
export const GET_ALL_PROF = "GET_ALL_PROF";
/////////////////////////admin////////////////////
export const UPDATE_PROFE_ADMIN = "UPDATE_PROFE_ADMIN";
export const DELETE_PROFE_ADMIN = "DELETE_PROFE_ADMIN";
////////////////////////disponibilidad///////////////////////////
export const GET_DISPONIBILIDADXDIA = "GET_DISPONIBILIDADXDIA";
export const SACAR_DIAS_DISPONIBLES = "SACAR_DIAS_DISPONIBLES";
export const RESET_DISPONIBILIDADXDIA = "RESET_DISPONIBILIDADXDIA";
export const PUT_ALL_DISPONIBILIDAD = "PUT_ALL_DISPONIBILIDAD";
export const AGREGAR_DISPONIBILIDAD = "AGREGAR_DISPONIBILIDAD";
///////////////////////////////clases/////////////////////////////////////
export const MODIFICAR_DIA_CLASE_ADMIN = "MODIFICAR_DIA_CLASE_ADMIN";
export const UPDATE_CLASE_ADMIN = "UPDATE_CLASE_ADMIN";
export const GET_CLASE_PROFE_ALUMNO = "GET_CLASE_PROFE_ALUMNO";
//////////////////////////////login//////////////////////////////
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
////////////////////////////reservas//////////////////////////////
export const CREATE_RESERVA = "CREATE_RESERVA";
export const UPDATE_RESERVA_ALUMNOS = "UPDATE_RESERVA_ALUMNOS";
export const GET_ALL_RESERVAS = "GET_ALL_RESERVAS";
export const GET_ALL_RESERVAS_TOTALES = "GET_ALL_RESERVAS_TOTALES";
export const GET_RESERVA_BY_ID = "GET_RESERVA_BY_ID";
export const GET_RESERVAS_POR_USUARIO = "GET_RESERVAS_POR_USUARIO";
export const DELETE_RESERVA_DEUDA = "DELETE_RESERVA_DEUDA";
export const DELETE_RESERVA_CERO = "DELETE_RESERVA_CERO";
/////////////////////////////users/////////////////////////
export const UPDATE_USER_ADMIN = "UPDATE_USER_ADMIN";
export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL";
export const DELETE_USER_ADMIN = "DELETE_USER_ADMIN";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_FOR_ID = "GET_USER_FOR_ID";
export const GET_PROFE_FOR_ID = "GET_PROFE_FOR_ID";
export const CREATE_USER = "CREATE_USER";
//////////////////////////////////////review////////////
export const CREATE_REVIEW = "CREATE_REVIEW";
export const GET_ALL_REVIEW = "GET_ALL_REVIEW";
//////////////////////////////////////ficha////////////
export const CREATE_FICHA = "CREATE_FICHA";
//////////////////////////////////////PROMO////////////
export const VERIFICAR_PROMO = "VERIFICAR_PROMO";
export const GET_ALL_PROMO = "GET_ALL_PROMO";
export const UPDATE_PROMO = "UPDATE_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";
export const CREATE_PROMO = "CREATE_PROMO";
////////////////////////////////////MP//////////////////////////
export const CREATE_MP_LINK = "CREATE_MP_LINK";
export const VERIFICAR_PAGO = "VERIFICAR_PAGO";


