export function arreglarDias(DiaSeleccionado,a) {

        // DiaSeleccionado.push(a)
      var key = Object.keys(a)
      var h = key[0]
      var hh = a[h]
      var d = a.dia
      var ob = {[h] : 0}
      if(DiaSeleccionado[d] === undefined){
        DiaSeleccionado[d] = {}
      }
      
      DiaSeleccionado[d][h] = hh
      // DiaSeleccionado[d][h] = "a[h]"
            
           
      return DiaSeleccionado
}