import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createReserva,
  createUser,
  getAllUsers,
  getDeports,
  getDisponibilidadXDia,
  putSacarDisponibilidad,
} from "../../../redux/actions";
import MultipleDatePicker from "react-multiple-datepicker"; //calendario
import { format } from "date-fns"; // para formartear el dia
import { Horarioslibres } from "../../calpreservas/horarioslibres";
import { acomodamientoDiasH, sacarCantDiasSelec, siPasaDePersonas } from "../reservaadmin/funciones/funcionesreservaadmin";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { sacarDisp } from "../../botonreservar/funciones";
import { useNavigate } from "react-router-dom";

export const Quitardisponibilidad = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const diasdisponibles = useSelector((state) => state.DisponibilidadXDia);
  const deports = useSelector((state) => state.Deports);
  const allUsers = useSelector((state) => state.AllUsers);
  const [usuariosB, setUsuariosB] = useState(allUsers)
  const [selecDeport, setSelecDeport] = useState("surf");
  const [dias, setDias] = useState(new Date()); //dias elegidos del calendario
  const [diasF, setDiasF] = useState([]); //dias para buscar
  const [cantDePersonas, setCantDePersonas] = useState(1)
  const [UsuarioSelec, setUsuarioSelec] = useState("")
  const usuarioCreado = useSelector((state) => state.UserCreated);
  const [pru, setPru] = useState("")
  const [pago, setPago] = useState(0)
  const [userEmail, setUserEmail] = useState("")
  const [datosreserva, setDatosreserva] = useState({})
  const [descuento, setDescuento] = useState(1)




  ////////////////////////////////crear usuario////////////////////
  const [nombreCrear, setNombreCrear] = useState("")
  const [apellidoCrear, setApellidoCrear] = useState("")
  const [emailCrear, setEmailCrear] = useState("")
  const [telCrear, setTelCrear] = useState("")

  const handleInputCrear = function(e){
    if(e.target.name === "name"){
      setNombreCrear(e.target.value)
    }
    if(e.target.name === "lastname"){
      setApellidoCrear(e.target.value)
    }
    if(e.target.name === "email"){
      setEmailCrear(e.target.value)
    }
    if(e.target.name === "tel"){
      setTelCrear(e.target.value)
    }

  }
///////////////////////////////pago/////////////////////
const handleInputPago = function(e){
  setPago(e.target.value)

}

const handleInputDescuento = function(e){
  setDescuento(e.target.value)
}


/////////////////////////////////////para el modal

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setUserEmail(queusu(UsuarioSelec, usuarioCreado))
    setShow2(true);
  }

  const handleCloseCrear = () => {
    const usuariocrear = {
      email : emailCrear,
      name : nombreCrear,
      lastname : apellidoCrear,
      tel: telCrear
    }
    if(emailCrear.length > 0 && nombreCrear.length > 0 && apellidoCrear.length > 0 && telCrear.length > 0){
      
      dispatch(createUser(usuariocrear))
      dispatch(getAllUsers())
      setPru("aa")
      setShow(false)
    }
    // setShow(false)
  
  };
  
  useEffect(() => {
    dispatch(getAllUsers())
    setUsuariosB(allUsers)
  
  }, [pru])
  

//////////////////////////////////////////////////



//   const [user, setUser] = useState([])

  useEffect(() => {
    dispatch(getDeports());
    dispatch(getAllUsers());
  }, []);

  const diasYhorarios = useSelector((state) => state.DiaSeleccionado); //dias y horarios seleccionados
  const cantDias = sacarCantDiasSelec(diasYhorarios)
  const sipasadepersonas = siPasaDePersonas(diasYhorarios,cantDePersonas)

    // if(diasYhorarios){
    //     setCantDias(sacarCantDiasSelec(diasYhorarios))
    //  }
  



    

 


  useEffect(() => {
    formatearDias(dias);
  }, [dias]);

  const handleInputDep = function (e) {
    setSelecDeport(e.target.value);
  };

  const formatearDias = () => {
    const diai = [];
    for (let i = 0; i < dias.length; i++) {
      const date = new Date(dias[i]);
      diai.push(format(date, "yyyy/MM/dd"));
    }
    setDiasF(diai);
  };
  const buscarDisp = () => {
    const buscar = {
      dias: diasF,
      deport: selecDeport,
    };

    if (diasF.length > 0) {
      dispatch(getDisponibilidadXDia(buscar));
    }
  };




  const handleInputBusquedaUsuario = function (e) {
    const busqueda = (e.target.value);
    setUsuariosB(allUsers.filter(element => element.email.includes(busqueda)))
  };






  const handleInputCantDePersonas = function(e){
    setCantDePersonas(e.target.value)
  }

  const handleInputUsuarioSelec = function(e){
    const ususelec = e.target.value
    
    setUsuarioSelec(allUsers.find(element => element.email.includes(ususelec)))
  }






  const reservaenv = {
    pago : pago,
    cantdiasreserva : cantDias,
    cantpersonasreserva: cantDePersonas,
    userEmail :userEmail,
    valorclase : 0,
    valorreserva: 0,
    valortotal: 0,
    diasreserva: [],
    deportDeport: ""
  }

const queusu = function(){
  
  for (let i = 0; i < deports.length; i++) {
    if(deports[i].deport === selecDeport){
      reservaenv.valorclase = deports[i].valorclase
    }
    
  }
  reservaenv.deportDeport = selecDeport
  
  if(usuarioCreado.email === undefined){
    reservaenv.userEmail = UsuarioSelec.email
  }else{
    reservaenv.userEmail = usuarioCreado.email
  }
  var dd = 1
  if(descuento !== 1){
    var d = descuento*0.01
    dd = dd -d
  }
  reservaenv.valortotal = reservaenv.cantdiasreserva * reservaenv.cantpersonasreserva * reservaenv.valorclase * dd
  const aa = acomodamientoDiasH(diasYhorarios)
  reservaenv.diasreserva = aa

  setDatosreserva(reservaenv)
}


const handleCloseCrearReserva = function(){


  ///////para sacar los dias disponibles.......////////





  const fechasparaenviar = [];
  const arrayDias = Object.keys(diasYhorarios);
  

  for (var i = 0; i < arrayDias.length; i++) {
      const horariosT = diasdisponibles[i].horariosusados;
    const obj = {
      diadisp: "",
      horariosusados: [],
      deport: "",
    };
    const horariosusados = [];
    const horar = diasYhorarios[arrayDias[i]];
    const arrayHorarios = Object.keys(diasYhorarios[arrayDias[i]]);
    for (var x = 0; x < arrayHorarios.length; x++) {
      if (horar[arrayHorarios[x]] != 0) {
        for (var y = 0; y < horar[arrayHorarios[x]]; y++) {
          horariosusados.push(arrayHorarios[x]);
        }
      }
    }
    obj.diadisp = arrayDias[i];
    obj.horariosusados = sacarDisp(horariosT,horariosusados);
    obj.deport = selecDeport;
    
    
    if (obj.horariosusados.length >= 0) {
      if(obj.horariosusados.length === 0){
          obj.horariosusados = []
      }
      fechasparaenviar.push(obj);
    }
  }





  //////////////////////////////////////////////////////////////////////////////////////



  dispatch(putSacarDisponibilidad(fechasparaenviar))
  reload();
  
}


const reload = ()=>{
  setTimeout(function(){
    navigate('/AdminPanel/calendario')
}, 2000);}






  ////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      {/* <div className="form-group">
    <label for="Inputselect">Cantidad de personas:</label>
    <select class="form-control" name="cantPers" onChange={handleInputCantDePersonas}>
    <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
    </select>
  </div>  */}
      <div className="form-group">
    <label for="Inputselect">Seleccionar deporte:</label>
    <select class="form-control" name="deportes" onChange={handleInputDep}>
    {deports && deports.length > 0
            ? deports.map((e) => (
                <option key={e.deport} value={e.deport}>
                  {e.deport}
                </option>
              ))
            : ""}
    </select>
  </div> 
      <div class="form-group">
        <label for="Fecha">Seleccionar fecha:</label>
        <MultipleDatePicker class="form-control" onSubmit={(dates) => setDias(dates)} />{" "}
        <br />
        <button className="btn btn-primary" onClick={buscarDisp}>Buscar</button>
        <br />
        {diasdisponibles && diasdisponibles.length > 0
        ? diasdisponibles.map((e,i) => (
            <Horarioslibres key={i}diadisp={e}></Horarioslibres>
          ))
        : ""}
        {sipasadepersonas === "error"?<div>hay mas personas que dias</div>:""}
        {/* este es el calendario para elegir fechas */}
      
  </div>
  {/* <div class="form-group">
    <label for="usuario">Seleccionar usuario</label>
    <input type="text" class="form-control" id="usuario" placeholder="Usuario" name="busquedausuario" onChange={handleInputBusquedaUsuario}/>
    {usuariosB.length === 0?
        
        <div>
                <Button variant="primary" onClick={handleShow}>
        Crear usuario
      </Button> 

        </div>
        :
        <div>
          
        <select  class="form-control" name="usuario" onChange={handleInputUsuarioSelec}>
        {allUsers && allUsers.length > 0
          ? usuariosB.map((e) => (
                  <option key={e.email} value={e.email}>
                {e.email}
              </option>
              
            ))
          : ""}
      </select>
        {UsuarioSelec !== "" ? 
        <div>
            <p>Nombre y Apellido: {UsuarioSelec.name} {UsuarioSelec.lastname} </p>
        </div>
        :""}
        {usuarioCreado.email !== undefined ? 
        <div>
            <p>Email:{usuarioCreado.email} Nombre: {usuarioCreado.name} Apellido:{usuarioCreado.lastname} </p>
        </div>
        :""}

        </div>

        }
  </div>  
  <div class="form-group">
    
    <label for="descuento">Descuento:</label>
    <input type="number" class="form-control" id="descuento" placeholder="10 = 10%" name="descuento" onChange={handleInputDescuento}/>
  </div>  
  <div class="form-group">
    <label for="descuento">Abonó:</label>
    <input type="number" class="form-control" id="descuento" placeholder="500" name="pago" onChange={handleInputPago}/>
  </div>   */}
  <div class="form-group">
  <Button variant="danger" class="btn btn-info"onClick={handleShow2}>
        Quitar horarios
      </Button> 

  </div>

      {/* <div>
        <p>cant de personas</p>
        <select name="cantPers" onChange={handleInputCantDePersonas}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
        </select>
      </div>
      <div>
        <p>seleccionar deporte</p>
        <select name="deportes" onChange={handleInputDep}>
          {deports && deports.length > 0
            ? deports.map((e) => (
                <option key={e.deport} value={e.deport}>
                  {e.deport}
                </option>
              ))
            : ""}
        </select>
      </div>
      <div>
        <p>selecciona fecha</p>
        <MultipleDatePicker onSubmit={(dates) => setDias(dates)} />{" "}
      </div>
      <div>
      <button onClick={buscarDisp}>buscar</button>
      </div>
      <div>
      {diasdisponibles && diasdisponibles.length > 0
        ? diasdisponibles.map((e,i) => (
            <Horarioslibres key={i}diadisp={e}></Horarioslibres>
          ))
        : ""}
        {sipasadepersonas === "error"?<div>hay mas personas que dias</div>:""}
        
      </div>
      
      <div>
        <p>seleccionar usuario</p>
        <input type="text" name="busquedausuario" onChange={handleInputBusquedaUsuario}/>
        {usuariosB.length === 0?
        
        <div>
                <Button variant="primary" onClick={handleShow}>
        Crear usuario
      </Button> 

        </div>
        :
        <div>
          
        <select name="usuario" onChange={handleInputUsuarioSelec}>
        {allUsers && allUsers.length > 0
          ? usuariosB.map((e) => (
                  <option key={e.email} value={e.email}>
                {e.email}
              </option>
              
            ))
          : ""}
      </select>
        {UsuarioSelec !== "" ? 
        <div>
            <p>{UsuarioSelec.name} {UsuarioSelec.lastname} </p>
        </div>
        :""}
        {usuarioCreado.email !== undefined ? 
        <div>
            <p>Email:{usuarioCreado.email} Nombre: {usuarioCreado.name} Apellido:{usuarioCreado.lastname} </p>
        </div>
        :""}

        </div>

        }
      </div>
      <div>
        <p>Descuento:</p>
        <input type="text" name="pago" onChange={handleInputDescuento}/>
      </div>
      <div>
        <p>Pago:</p>
        <input type="text" name="pago" onChange={handleInputPago}/>
      </div> */}

      {/* <Button variant="primary" onClick={handleShow2}>
        Verificar reserva
      </Button>  */}




      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>SE QUITARAN HORARIOS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
            
          {datosreserva.diasreserva && datosreserva.diasreserva.length > 0 ? datosreserva.diasreserva.map(a => <p>{a.dia} horario: {a.horario.map(a => a + "//")}</p> ):""}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="danger" onClick={handleCloseCrearReserva}>
            ELIMINAR
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};
