import Axios from "axios";
import * as actionTypes from "../action-types";
import Sweal from 'sweetalert2'

Axios.defaults.baseURL = 'https://backsudestada-production.up.railway.app/api/'
// Axios.defaults.baseURL = "http://localhost:3001/api";

// export const getAllOrders = userId => {
//   return async function (dispatch) {
//     try {
//       let orders = await Axios(`admin/ordens?userId=${userId}`)
//       return dispatch({
//         type: actionTypes.GET_ALL_ORDERS,
//         payload: orders.data,
//       })
//     } catch (error) {
//       Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
//     }
//   }
// }

// export const getAllUserReviews = id => {
//   return async function (dispatch) {
//     try {
//       let info = await Axios(`/reviewsUser/${id}`)
//       return dispatch({
//         type: actionTypes.GET_ALL_USER_REVIEWS,
//         payload: info.data,
//       })
//     } catch (error) {
//       Sweal.fire('Ups hubo un error', `${error?.response?.data?.msg}`, 'error')
//     }
//   }
// }
export const getWindguru = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("windguru");
      return dispatch({
        type: actionTypes.GET_WINDGURU,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const getClima = () => {
  return async function (dispatch) {
    try {
      let info = await Axios(
        `https://api.openweathermap.org/data/2.5/weather?q=pinamar&appid=67d69d15edb0e0a137d406767615c22e&units=metric`
      );
      return dispatch({
        type: actionTypes.GET_CLIMA,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const getMarea = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("marea");
      return dispatch({
        type: actionTypes.GET_MAREA,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const getDeports = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("getdeports");
      return dispatch({
        type: actionTypes.GET_DEPORTS,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const getDisponibilidadXDia = (dias) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`disponibilidad`, dias);
      return dispatch({
        type: actionTypes.GET_DISPONIBILIDADXDIA,
        payload: info.data,
      });
    } catch (error) {
      return dispatch({
        type: actionTypes.GET_DISPONIBILIDADXDIA,
        payload: error,
      });
    }
  };
};

export const diasSeleccionados = (seleccionados) => {
  return async function (dispatch) {
    return dispatch({
      type: actionTypes.DIAS_SELECCIONADOS,
      payload: seleccionados,
    });
  };
};
export const resetDisponibilidadXdia = (seleccionados) => {
  return async function (dispatch) {
    return dispatch({
      type: actionTypes.RESET_DISPONIBILIDADXDIA,
      payload: seleccionados,
    });
  };
};

export const deporteSeleccionado = (depselec) => {
  return async function (dispatch) {
    return dispatch({
      type: actionTypes.DEPORTE_SELECCIONADO,
      payload: depselec,
    });
  };
};

export const putSacarDisponibilidad = (dias) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`sacardisponibilidad`, dias);
      if (info.data === "correcto") {
        return dispatch({
          type: actionTypes.SACAR_DIAS_DISPONIBLES,
          payload: "correcto",
        });
      } else {
        return dispatch({
          type: actionTypes.SACAR_DIAS_DISPONIBLES,
          payload: info.data,
        });
      }
    } catch (error) {
      return dispatch({
        type: actionTypes.SACAR_DIAS_DISPONIBLES,
        payload: error,
      });
    }
  };
};

export const getAllReservas = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("getreserva");
      return dispatch({
        type: actionTypes.GET_ALL_RESERVAS,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const getAllReservasTotales = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("getreservastotales");
      return dispatch({
        type: actionTypes.GET_ALL_RESERVAS_TOTALES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const getAllUsers = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("getusers");
      return dispatch({
        type: actionTypes.GET_ALL_USERS,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const getUserDetails = (email) => {
  return async function (dispatch) {
    try {
      let info = await Axios(`userdetails/${email}`);
      return dispatch({
        type: actionTypes.GET_USER_FOR_ID,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const createUser = (user) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createuser`, user);
      Sweal.fire('Éxito', 'Creado Usuario con exito', 'success')
        return dispatch({
          type: actionTypes.CREATE_USER,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      return dispatch({
        type: actionTypes.CREATE_USER,
        payload: error,
      });
    }
  };
};

export const updateUserAdmin = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`updateuseradmin`, obj);
      Sweal.fire('Éxito', 'Cambios Realizados', 'success')
        return dispatch({
          type: actionTypes.UPDATE_USER_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      // return dispatch({
      //   type: actionTypes.UPDATE_USER_ADMIN,
      //   payload: error,
      // });
    }
  };
};
export const updateUserDetail = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`updateuserdetail`, obj);
      Sweal.fire('Éxito', 'Cambios Realizados', 'success')
        return dispatch({
          type: actionTypes.UPDATE_USER_DETAIL,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      // return dispatch({
      //   type: actionTypes.UPDATE_USER_ADMIN,
      //   payload: error,
      // });
    }
  };
};
export const deleteUserAdmin = (email) => { 

  return async function (dispatch) {
    
    try {
      let info = await Axios.put(`deleteuseradmin`,email);
      Sweal.fire('Éxito', 'El usuario fue eliminado', 'success')
        return dispatch({
          type: actionTypes.DELETE_USER_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      // return dispatch({
      //   type: actionTypes.UPDATE_USER_ADMIN,
      //   payload: error,
      // });
    }
  };
};


export const createReserva = (reserva) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createreserva`, reserva);
      if(reserva.userId !== 3){
      Sweal.fire('Éxito', 'Creado con éxito.', 'success')}
        return dispatch({
          type: actionTypes.CREATE_RESERVA,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};




export const getReservaXUsuario = userEmail => {

  return async function (dispatch) {
    try {
      let info = await Axios(`getreservaxusuario/${userEmail}`);
      return dispatch({
        type: actionTypes.GET_RESERVAS_POR_USUARIO,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const getReservaById = id => {

  return async function (dispatch) {
    try {
      let info = await Axios(`getreservabyid/${id}`);
      return dispatch({
        type: actionTypes.GET_RESERVA_BY_ID,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const updateReservaAlumnos = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`updatereservaalumns`, obj);
      Sweal.fire('Éxito', 'Debera esperar que se le asigne un profesor', 'success')
      
        return dispatch({
          type: actionTypes.UPDATE_RESERVA_ALUMNOS,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      return dispatch({
        type: actionTypes.UPDATE_RESERVA_ALUMNOS,
        payload: error,
      });
    }
  };
};
export const deleteReservaDeuda = (clases) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`deletreservadeudoradmin`, clases);
      Sweal.fire('Éxito', 'Se elimino las clases.', 'success')
      return dispatch({
        type: actionTypes.DELETE_RESERVA_DEUDA,
        payload: info.data,
      });
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};



export const getAllProfesores = () => {
  return async function (dispatch) {
    try {
      let info = await Axios("getallprofes");
      return dispatch({
        type: actionTypes.GET_ALL_PROF,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};

export const updateProfeAdmin = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`updateprofeadmin`, obj);
      
      Sweal.fire('Éxito', 'Modificado con exito.', 'success')
      return dispatch({
        type: actionTypes.UPDATE_PROFE_ADMIN,
        payload: info.data,
      });
      
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const deleteProfeAdmin = (email) => { 

  return async function (dispatch) {
    
    try {
      let info = await Axios.put(`deleteprofeadmin`,email);
      Sweal.fire('Éxito', 'El usuario fue eliminado', 'success')
        return dispatch({
          type: actionTypes.DELETE_PROFE_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      // return dispatch({
      //   type: actionTypes.UPDATE_USER_ADMIN,
      //   payload: error,
      // });
    }
  };
};
export const agregarProfeAdmin = (email) => { 

  return async function (dispatch) {
    
    try {
      let info = await Axios.post(`agregarprofeadmin`,email);
      Sweal.fire('Éxito', `${info?.data?.msg}`, 'success')
        return dispatch({
          type: actionTypes.DELETE_PROFE_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      // return dispatch({
      //   type: actionTypes.UPDATE_USER_ADMIN,
      //   payload: error,
      // });
    }
  };
};


export const updateClaseAdmin = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`updateclaseadmin`, obj);
      
        return dispatch({
          type: actionTypes.UPDATE_CLASE_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      return dispatch({
        type: actionTypes.UPDATE_CLASE_ADMIN,
        payload: error,
      });
    }
  };
};
export const modificarDiaClaseAdmin = obj => {

  return async function (dispatch) {
    try {
      let info = await Axios.post(`modificardiaclaseadmin`, obj);
      Sweal.fire('Éxito', `${info?.data?.msg}`, 'success')
        return dispatch({
          type: actionTypes.MODIFICAR_DIA_CLASE_ADMIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const getClasesProfeAlumno = obj => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`/getclasesprofealumno`, obj);
      if(info.data[0]){
        return dispatch({
          type: actionTypes.GET_CLASE_PROFE_ALUMNO,
          payload: info.data,
        });
      }else{
        Sweal.fire('Ups hubo un error', `No tiene clases`, 'error')
      }
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.msg}`, 'error')
    }
  };
};


export const putAllDisponibilidad = (disp) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`putalldisponibilidad`, disp);
      Sweal.fire('Éxito', 'Creado con éxito.', 'success')
        return dispatch({
          type: actionTypes.PUT_ALL_DISPONIBILIDAD,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const agregarDisponibilidad = (disp) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createdisponibilidad`, disp);
      Sweal.fire('Éxito', 'Creado con éxito.', 'success')
        return dispatch({
          type: actionTypes.AGREGAR_DISPONIBILIDAD,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};



export function userLogin(data) {
  return async function (dispatch) {
    try {
      const response = await Axios.post('/login', data)
      
      return dispatch({
        type: actionTypes.USER_LOGIN,
        payload: response.data,
      })
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error?.response?.data?.msg}`, 'error')
    }
  }
}
export function userLogout() {
  
  return function (dispatch) {
    return {
      type: actionTypes.USER_LOGOUT,
    }
  }
}



export const createReview = (review) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createreview`, review);
      Sweal.fire('Éxito', 'Se actualizo la review', 'success')
        return dispatch({
          type: actionTypes.CREATE_REVIEW,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const getAllReview = (review) => {
  return async function (dispatch) {
    try {
      let info = await Axios("getallreviews");
      return dispatch({
        type: actionTypes.GET_ALL_REVIEW,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const createFicha = (ficha) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createficha`, ficha);
      Sweal.fire('Éxito', 'Se actualizo correctamente', 'success')
        return dispatch({
          type: actionTypes.CREATE_FICHA,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};





export const getPromporId = codigo => {

  return async function (dispatch) {
    try {
      let info = await Axios(`promsdetails/${codigo}`);
      if(info.data === "Se termino la promo"){
        Sweal.fire('Ups hubo un error', `Se termino la promo`, 'error')
      return dispatch({
        type: actionTypes.VERIFICAR_PROMO,
        payload: false,
      });}
      if(info.data === null){
        Sweal.fire('Ups hubo un error', `No existe el codigo de descuento`, 'error')
      return dispatch({
        type: actionTypes.VERIFICAR_PROMO,
        payload: false,
      });}
      if(info.data.id){
        
      return dispatch({
        type: actionTypes.VERIFICAR_PROMO,
        payload: info.data,
      });}




    } catch (error) {
      return "error";
    }
  };
};


export const getAllPromos = () => {
  return async function (dispatch) {
    try {
      let info = await Axios(`getallproms`);
      return dispatch({
        type: actionTypes.GET_ALL_PROMO,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const updatePromos = (promo) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`updatepromadmin`, promo);
      Sweal.fire('Éxito', 'Modificado con exito.', 'success')
      return dispatch({
        type: actionTypes.UPDATE_PROMO,
        payload: info.data,
      });
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const createPromos = (promo) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`createproms`, promo);
      Sweal.fire('Éxito', 'Creado con exito.', 'success')
      return dispatch({
        type: actionTypes.CREATE_PROMO,
        payload: info.data,
      });
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};
export const deletePromos = (promo) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`deletepromadmin`, promo);
      Sweal.fire('Éxito', 'Se elimino la promo.', 'success')
      return dispatch({
        type: actionTypes.DELETE_PROMO,
        payload: info.data,
      });
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};


export const crearPagoMP = (reserva) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`payment`, reserva);
        return dispatch({
          type: actionTypes.CREATE_MP_LINK,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};


export const verificarPagoMP = (datos) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`verificarpayment`, datos);
        return dispatch({
          type: actionTypes.VERIFICAR_PAGO,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};


export const deleteReservaCero = (reserva) => {
  return async function (dispatch) {
    try {
      let info = await Axios.put(`deletereservacero`, reserva);
      return dispatch({
        type: actionTypes.DELETE_RESERVA_CERO,
        payload: info.data,
      });
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
    }
  };
};


