import React from 'react'
import { BotonPagar } from '../botonpagar/BotonPagar'
import { BotonReservar } from '../botonreservar/BotonReservar'
import Calpreservas from '../calpreservas/calpreservas'

export const Reservar = () => {
  return (
    <div>
        <div>
        <Calpreservas></Calpreservas>
      </div>
      {/* <div>
        <BotonReservar></BotonReservar>
      </div> */}
    </div>
  )
}
