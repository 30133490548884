import React from 'react'
import { Footer } from '../footer/Footer'
import "./quienessomos.css"

export const Quienessomos = () => {
  return (
    <div className='body__quienessomos'>
        <main className="main__quienessomos">
            <section className="main__hero__quienessomos">
                <nav className="main__nav__quienessomos container__quienessomos">
                    <h1 className='main__logo__quienessomos'></h1>
                </nav>
            </section>
            <section className="main__buy__quienessomos container__quienessomos">
                <div className="main__content__quienessomos">
                    <h2 className="main__title__quienessomos">QUIENES SOMOS</h2>
                    <p className="main__paragraph__quienessomos">Todo arrancó en el verano del 2013 como una linda propuesta de temporada, un nuevo proyecto familiar. Desde siempre el MAR fue algo que nos unió como familia, pensar en la playa es hablar de los momentos más lindos que pasamos juntos.
Así fue que surgió SUDESTADA, un espacio donde trasmitir nuestro amor por el SURF, el MAR y las OLAS; Un lugar para seguir creando “buenos momentos”, nuestra llamada “casita de playa”.
                    </p>
                </div>
            </section>
            <div className="main__bg__quienessomos"></div>
            <section className="main__about__quienessomos container__quienessomos">
            <div className="main__content__quienessomos">
                    <h3 className="main__title__quienessomos"></h3>
                    <p className="main__paragraph__quienessomos main__paragraph__quienessomos--about">
                    Con el tiempo se fueron sumando más integrantes al equipo, otros locos y locas como nosotrxs, amantes de las OLAS y de este estilo de vida tan lindo. Se fueron incorporando nuevas disciplinas, se agregaron propuestas, cambiamos de espacio, mejoramos “nuestra casita”, la llenamos de arte.. Y así es que llegamos a ser lo que somos: una de las Escuelas más reconocidas de la zona, y sin duda; EL MEJOR EQUIPO!
                        </p>
                </div>
            </section>
            <div className="main__bg__quienessomos main__bg__quienessomos--second"></div>
        </main>
        <Footer></Footer>
    </div>
  )
}
