import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getClima } from '../../redux/actions';

const Clima = () => {

  const clima = useSelector(state => state.Clima)
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getClima())
  }, [])
  // const [clima, setclima] = useState("")
  // function onSearch() {
  //   fetch(`https://api.openweathermap.org/data/2.5/weather?q=pinamar&appid=67d69d15edb0e0a137d406767615c22e&units=metric`)
  //     .then(r => r.json())
  //     .then((recurso) => {
  //       if(recurso.main !== undefined){
  //         const ciudad = {
  //           min: Math.round(recurso.main.temp_min),
  //           max: Math.round(recurso.main.temp_max),
  //           img: recurso.weather[0].icon,
  //           id: recurso.id,
  //           wind: recurso.wind.speed,
  //           temp: recurso.main.temp,
  //           name: recurso.name,
  //           weather: recurso.weather[0].main,
  //           clouds: recurso.clouds.all,
  //           latitud: recurso.coord.lat,
  //           longitud: recurso.coord.lon
  //         };
  //         setclima(ciudad);
  //       } else {
  //         alert("Ciudad no encontrada");
  //       }
  //     });

  //   }

  // useEffect(() => {
  //   onSearch()
  
  // }, [])
  
  return (
    <Link to={"/infoclima"}>
      {clima && clima.weather ? <img className="iconoClima" src={`/images/clima/${clima.weather[0].icon}.png`} width="80" height="80" alt="" />:""}
    </Link>
  )
}

export default Clima