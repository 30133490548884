import React, { Component,  useState } from 'react';


export default function CardMarea (s) {
  
    
    const fecha = s[0].slice(0,5) + " " + s[0].slice(11,16)

    return (
        <tr>
            <td data-label="Fecha">{fecha}</td>
            <td data-label="Marea 1">{s[1]}.</td>
            <td data-label="Marea 2">{s[2]}.</td>
            <td data-label="Marea 3">{s[3]}.</td>
            <td data-label="Marea 4">{s[4]}.</td>
            
            
        </tr>
    );
};