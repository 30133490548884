import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Axios from "axios";
import {
  createUser,
  updateUserAdmin,
  updateUserDetail,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Loading } from "../loading/Loading";
import "./userdetail.css";
import { Deslinde } from "../deslinde/Deslinde";

export const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.UserLog);

  const [details, setDetails] = useState({
    avatar_url: "",
  });
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    avatar_url: userDetails.avatar_url || "",
    cantclases: userDetails.cantclases || "",
    deslingue: userDetails.deslingue || false,
    dni: userDetails.dni || "",
    email: userDetails.email || "",
    fechanac: userDetails.fechanac || new Date(),
    instagram: userDetails.instagram || "",
    lastname: userDetails.lastname || "",
    name: userDetails.name || "",
    // roleId: userDetails.roleId||"",
    tel: userDetails.tel || "",
    // emailAnt: userDetails.email||""
  });
  useEffect(() => {
    setInput({
      avatar_url: userDetails.avatar_url || "",
      cantclases: userDetails.cantclases || "",
      deslingue: userDetails.deslingue || false,
      dni: userDetails.dni || "",
      email: userDetails.email || "",
      fechanac: userDetails.fechanac || new Date(),
      instagram: userDetails.instagram || "",
      lastname: userDetails.lastname || "",
      name: userDetails.name || "",
      // roleId: userDetails.roleId||"",
      tel: userDetails.tel || "",
      // emailAnt: userDetails.email||""
    });
  }, [userDetails]);

  const handleUploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();

    data.append("file", files[0]);
    data.append("upload_preset", "useravatar"); //nombre de la carpeta de cloudinary
    setLoading(true);

    try {
      // CLOUDINARY_URL=cloudinary://449916713616631:Kn2TprrvUJEyFMJMsjj44Ls-FKI@dv2uyjmw1
      let response = await Axios.post(
        "https://api.cloudinary.com/v1_1/dkn2dgfex/image/upload",
        data
        // {
        //   method: "POST",
        //   body: data,
        // }
      );
      Swal.fire("Éxito", "Foto cargada con exito", "success");
      response = response.data;

      setImage(response.secure_url);
      setInput({
        ...input,
        avatar_url: response.secure_url,
      });

      setLoading(false);
    } catch (error) {
      Swal.fire("Error", "Hubo un error al cargar la imagen", "error");
    }
  };
  const handleInputChange = function (e) {
    if (e.target.name === "deslingue") {
      setInput({
        ...input,
        [e.target.name]: e.target.checked,
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
        // [e.target.name]: e.target.checked,
      });
    }
  };
  const reload = ()=>{
    setTimeout(function(){
     navigate(0)
 }, 2000);
}

  const handleSave = () => {
    dispatch(updateUserDetail(input));
    setInput({});
    reload()
  };


  return (
    <div className="body__perfil">
      <div className="container rounded bg-white mt-5 mb-5">
        <div className="row">
          {" "}
          <div className="col-md-6 border-right">
            <div className="d-flex flex-column align-items-center text-center p-3 py-5">
            {image !== ""?
              <img
                className="rounded-circle mt-5"
                src={image}
                width="200px"
                height="200px"
                alt=""
              />
              :
              <img
                className="rounded-circle mt-5"
                src={userDetails.avatar_url}
                width="200px"
                height="200px"
                alt=""
              />
            }
            {loading?
              <Loading></Loading>
              :
              <input
                      accept="image/*"
                      type="file"
                      name='file'
                      onChange={handleUploadImage}
                    />
      }
              <span className="font-weight-bold">{userDetails.email}</span>
              <span className="text-black-50">Clases tomadas:{userDetails.cantclases}</span>
              <span> </span>
            </div>
          </div>
          <div className="col-md-6 border-right">
            <div className="p-3 py-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4 className="text-right">Perfil</h4>
              </div>{" "}
              <div className="row mt-2">
                <div className="col-md-6">
                  <label className="labels">Nombre:</label>
                  <input
                type="text"
                name="name"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={userDetails.name}
              />
                </div>
                <div className="col-md-6">
                  <label className="labels">Apellido:</label>
                  <input
                type="text"
                name="lastname"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={userDetails.lastname}
              />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <label className="labels">DNI:</label>
                  <input
                type="number"
                name="dni"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={userDetails.dni}
              />
                </div>{" "}
                <div className="col-md-12">
                  <label className="labels">Telefono:</label>
                  <input
                type="number"
                name="tel"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={userDetails.tel}
              />
                </div>{" "}
                <div className="col-md-12">
                  <label className="labels">Fecha de nacimiento:</label>
                  <input
                type="date"
                name="fechanac"
                className="form-control"
                onChange={handleInputChange}
                placeholder="1988/07/29"
                defaultValue={userDetails.fechanac}
              />
                </div>{" "}
                <div className="col-md-12">
                  <label className="labels">Instagram:</label>
                  <input
                type="text"
                name="instagram"
                className="form-control"
                onChange={handleInputChange}
                defaultValue={userDetails.instagram}
              />
                </div>{" "}
              </div>{" "}
              <div className="row mt-3">
                {" "}
                <div className="col-md-6">
                  <label className="labels">Deslinde: </label>
                  {" "}
                  <input
                type="checkbox"
                name="deslingue"
                onChange={handleInputChange}
                defaultChecked={userDetails.deslingue}
              />
                </div>{" "}
                <div className="col-md-6">
                  <label className="labels"><Deslinde></Deslinde></label>
                </div>{" "}
              </div>{" "}
              <div className="mt-5 text-center">
                <button className="btn btn-primary profile-button" type="button" onClick={handleSave}>
                  Guardar
                </button>
              </div>{" "}
            </div>{" "}
          </div>{" "}
          {/* <div className="col-md-4">
            {" "}
            <div className="p-3 py-5">
              {" "}
              <div className="d-flex justify-content-between align-items-center experience">
                <span>Edit Experience</span>
                <span className="border px-3 p-1 add-experience">
                  <i className="fa fa-plus"></i>&nbsp;Experience
                </span>
              </div>
              <br />{" "}
              <div className="col-md-12">
                <label className="labels">Experience in Designing</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="experience"
                  value=""
                />
              </div>{" "}
              <br />{" "}
              <div className="col-md-12">
                <label className="labels">Additional Details</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="additional details"
                  value=""
                />
              </div>{" "}
            </div>{" "}
          </div>{" "} */}
        </div>
      </div>
    </div>

    /* <div>
          {image !== ""?<img src={image} alt="" width="200px" height="200px"/>:<img src={userDetails.avatar_url} width="200px" height="200px"alt="" />}
        {loading?
              <Loading></Loading>
              :
              <input
                      accept="image/*"
                      type="file"
                      name='file'
                      onChange={handleUploadImage}
                    />
      }
        </div>
        <div>
              <label>Nombre:</label>
              <input
                type="text"
                name="name"
                onChange={handleInputChange}
                defaultValue={userDetails.name}
              />
            </div>
            <div>
              <label>Apellido:</label>
              <input
                type="text"
                name="lastname"
                onChange={handleInputChange}
                defaultValue={userDetails.lastname}
              />
            </div>
            <div>
              <label>DNI:</label>
              <input
                type="number"
                name="dni"
                onChange={handleInputChange}
                defaultValue={userDetails.dni}
              />
            </div>
            <div>
              <label>Tel:</label>
              <input
                type="number"
                name="tel"
                onChange={handleInputChange}
                defaultValue={userDetails.tel}
              />
            </div>
            <div>
              <label>Fecha de nacimiento:</label>
              <input
                type="date"
                name="fechanac"
                onChange={handleInputChange}
                placeholder="1988/07/29"
                defaultValue={userDetails.fechanac}
              />
            </div>
            <div>
              <label>Instagram:</label>
              <input
                type="text"
                name="instagram"
                onChange={handleInputChange}
                defaultValue={userDetails.instagram}
              />
            </div>
            <div>
              <label>Deslingue:</label>
              <input
                type="checkbox"
                name="deslingue"
                onChange={handleInputChange}
                defaultChecked={userDetails.deslingue}
              />
            </div>
            <button variant="primary" onClick={handleSave}>
                Save
              </button> */
  );
};
