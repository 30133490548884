import React, { useCallback, useRef, useState } from "react";

import DatePicker from "react-multi-date-picker"

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allreservasarray } from "../../paneladmin/funciones/allreservasarray";
import {
  getAllProfesores,
  getAllReservasTotales,
  getAllUsers,
  getDisponibilidadXDia,
  modificarDiaClaseAdmin,
  putSacarDisponibilidad,
  updateClaseAdmin,
} from "../../../redux/actions";
import { findUserForClass } from "../../calendario/funciones.jsx/filtrarusuarioclase";
import { sacarDisp } from "../../botonreservar/funciones";
import { useNavigate } from 'react-router-dom'

export const ModificarReservaAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(getAllReservasTotales());
  }, []);
  var reservasA = [];


  const [value, setValue] = useState(new Date())

  const allReservasTotales = useSelector((state) => state.AllReservasTotales);
  const allProfes = useSelector((state) => state.AllProfes);
  const allUsers = useSelector((state) => state.AllUsers);
  if (allReservasTotales.length > 0) {
    reservasA = allreservasarray(allReservasTotales);
  }

  const [show, setShow] = useState(false);
  const [popUp, setPopUp] = useState({});
  const [title, setTitle] = useState("");
  const [arrayAlumnos, setArrayAlumnos] = useState([]);
  const [alumno, setAlumno] = useState({});
  const [alumno2, setAlumno2] = useState({});
  const [pagado, setPagado] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);
  const [CantClases, setCantClases] = useState(0);
  const [CantPersonas, setCantPersonas] = useState(0);
  const [profesor, setProfesor] = useState("");
  const [profesor2, setProfesor2] = useState("");
  const [id, setId] = useState(0);
  const [realizado, setRealizado] = useState(false);
  const [input, setInput] = useState({
    alumno: "",
    realizado: false,
    profesoreEmail: "",
    pago: 0,
    id: 0,
  });

  const [allUsuarios, setAllUsuarios] = useState([]);

  useEffect(() => {
    dispatch(getAllProfesores());
    dispatch(getAllUsers());
    dispatch(getAllReservasTotales());
  }, []);

  useEffect(() => {
    if (alumno) {
      setAlumno2(findUserForClass(allUsers, alumno));
    }
    if (!alumno) {
      setAlumno2(null);
    }
    if (profesor) {
      setProfesor2(findUserForClass(allUsers, profesor));
    }
    if (!profesor) {
      setProfesor2(null);
    }
  }, [alumno, profesor]);

  const handleInputChange = function (e) {
    if (e.target.name === "realizado") {
      setInput({
        ...input,
        [e.target.name]: e.target.checked,
      });
    } else {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
        // [e.target.name]: e.target.checked,
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  var events = reservasA;

  const onSelectEvent = useCallback((calEvent) => {
    
    setRealizado(calEvent.desc.realizado);
    setInput({
      ...input,
      alumno: calEvent.desc.alumno,
      cantdiasreserva: calEvent.desc.cantdiasreserva,
      cantpersonasreserva: calEvent.desc.cantpersonasreserva,
      deportDeport: calEvent.desc.deportDeport,
      id: calEvent.desc.id,
      idreservatotales: calEvent.desc.idreservatotales,
      pago: calEvent.desc.pago,
      profesoreEmail: calEvent.desc.profesoreEmail,
      profesores: calEvent.desc.profesores,
      realizado: calEvent.desc.realizado,
      reservaId: calEvent.desc.reservaId,
      reservaalumnos: calEvent.desc.reservaalumnos,
      review: calEvent.desc.review,
      userEmail: calEvent.desc.userEmail,
      valortotal: calEvent.desc.valortotal
    });
    setId(calEvent.desc.id);
    setPagado(calEvent.desc.pago);
    setValorTotal(calEvent.desc.valortotal);
    setArrayAlumnos(calEvent.desc.reservaalumnos);
    setCantClases(calEvent.desc.cantdiasreserva);
    setCantPersonas(calEvent.desc.cantpersonasreserva);
    setTitle(calEvent.title);

    setAlumno(calEvent.desc.alumno);
    setProfesor(calEvent.desc.profesoreEmail);
    // setAlumno(findUserForClass(allUsers, calEvent.desc.alumno))
    // setProfesor(findUserForClass(allUsers, calEvent.desc.profesoreEmail))
    handleShow();
  }, []);

  const savechange = () => {
    
    const horariosT = diasdisponibles[0].horariosusados;
    const obj = {
        diadisp: "",
        horariosusados: [],
        deport: "",
      };
      obj.diadisp = new Date(input.date);
      obj.horariosusados = sacarDisp(horariosT,[input.horaSelec]);
      obj.deport = input.deportDeport;

      dispatch(putSacarDisponibilidad([obj]))
      dispatch(modificarDiaClaseAdmin(input))
    handleClose();
    reload();
  };
  const reload = ()=>{
    setTimeout(function(){
     navigate(0)
 }, 2000);}

  

  const diasdisponibles = useSelector((state) => state.DisponibilidadXDia);

useEffect(() => {
  if(input.date){
    const buscar = {
        dias: [new Date(input.date)],
        deport: input.deportDeport,
      };
      if (input.date.length > 0) {
        dispatch(getDisponibilidadXDia(buscar));
      }
  }
}, [input])


function handleChange(value){
  //your modification on passed value ....
 
  const diai = [];
  const date = new Date(value);
      diai.push(format(date, "yyyy/MM/dd"));

  // const formatearDias = () => {
  //   const diai = [];
  //   for (let i = 0; i < dias.length; i++) {
  //     const date = new Date(dias[i]);
  //     diai.push(format(date, "yyyy/MM/dd"));
  //   }
  //   setDiasF(diai);
  // };
  setValue(diai)
  setInput({
    ...input,
    date: diai
  });
}


  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: "50px" }}
        onSelectEvent={onSelectEvent}
        popup
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>MOVER CLASE DE {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cant clases reserva: {CantClases}</p>
          <p>Cant personas reserva: {CantPersonas}</p>
          <p>Profesor: {profesor2 && profesor2.name ? profesor2.name : ""}</p>

          <p>Alumno: {alumno2 && alumno2.name ? alumno2.name : ""}</p>
          
          <DatePicker 
  value={value[0]}
  onChange={handleChange}
  
/>
          {/* <input type="date" name="date" id="date" onChange={handleInputChange}/> */}
          <select name="horaSelec" id="horaSelec"  onChange={handleInputChange} onClick={handleInputChange}>
          {diasdisponibles && diasdisponibles.length > 0
        ? diasdisponibles[0].horariosusados.map((e,i) => (
            <option  value={e}>{e}</option>
          ))
        : ""}
          </select>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {input.date && input.horaSelec ?

            <Button variant="primary" onClick={savechange}>
              Save Changes
            </Button>
          :""}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
