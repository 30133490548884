import React from 'react'
import { Footer } from '../footer/Footer'
import "./nuestraspromos.css"

export const Nuestraspromos = () => {
  return (
    <div className="home__nuestraspromos">
    <header className="hero__nuestraspromos">
      <section className="hero__main__nuestraspromos container__nuestraspromos">
        <h1 className="hero__title__nuestraspromos">PROMOS</h1>
      </section>
    </header>
    <main>
      <section className="services__nuestraspromos">
        <div className="service_image--nuestraspromos"></div>
        <div className="services__texts__nuestraspromos">
          <h2 className="services__title__nuestraspromos"> PROMO HERMANOS:</h2>
          <p className="services__paragraph__clasessup">
          Porque no hay nada más lindo que compartir con tu hermana/o los buenos momentos de la vida, es que diseñamos esta PROMO!!!</p>
          <li>Clases grupales personalizadas: un profe- un alumno</li>
          <li>Acompañamiento permanente dentro y fuera del Mar</li>
          <li>Duración de la clase: 50´aprox</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          <li>Promo válida únicamente ENERO y FEBRERO 2023</li>
          
          </div>
        <div className="service__image--nuestraspromos1"></div>

        <div className="services__texts__nuestraspromos services__texts--2__nuestraspromos">
          <h2 className="services__title__nuestraspromos"> CURSO INTENSIVO FINDE SEMANA</h2>
          <p className="services__paragraph__clasessup">
          Si sos de esos que te gusta darlo todo pero tenés poco tiempo para demostrarlo, este CURSO INTENSIVO de FIN DE SEMANA es especial para vos!</p>
          <li>Clases personalizadas: un profe- un alumno</li>
          <li>Acompañamiento permanente dentro y fuera del agua</li>
          <li>Todos los niveles, todas las edades</li>
          <li>Objetivos específicos según el nivel del surfista</li>
          <li>Duración de la clase variable según el contenido a abordar</li>
          <li>Clases teórico prácticas: Ingresamos al MAR desde la primera clase, en TODAS las clases!</li>
          <li>Traje de Neoprene corto y tabla incluidos</li>
          <li>Promo válida hasta el 23 de Diciembre (consultar valores para temporada alta)</li>
          
        </div>
      </section>
    </main>
    


<Footer></Footer>
<div>
    
</div>

  </div>
  )
}
