import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { diasSeleccionados } from "../../redux/actions";

export const Seleccionador = ({ posicion, horarios, horariosdisp, dia }) => {
    const dispatch = useDispatch();
    const [horario, setHorario] = useState(0)
  var cant = 0;
  const horariosSeleccionados = { 
    dia: 0, 
    // horario: 0,
    // cant: 0
}
  for (var i = 0; i < horariosdisp.length; i++) {
    if (horariosdisp[i] === horarios[posicion]) {
      cant++;
    }
  }

  var num = [];
  for (var x = 0; x < cant + 1; x++) {
    num.push(x);
  }

  const handleInputH = function (e) {
    setHorario(e.target.value);
};

horariosSeleccionados.dia = dia
horariosSeleccionados[horarios[posicion]] = horario
// horariosSeleccionados.cant = horario
useEffect(() => {
  dispatch(diasSeleccionados(horariosSeleccionados))

}, [horariosSeleccionados])



  return (
    <select onChange={handleInputH} > 
      {num.map((e) => (
        <option key={e} value={e}>
          {e}
        </option>
      ))}

      {/* <option>{cant}</option> */}
    </select>
  );
};
